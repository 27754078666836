import React from "react";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";
import { Alert, Grid } from "@mui/material";
import {getRoles} from "../utils/jwtUtils";

export const AdminProtectedRoute = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  const auth = useAuth();

  const [isAdmin, setIsAdmin] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    for (const role of getRoles(auth)) {
      if (role.toLowerCase().includes("admin")) {
        setIsAdmin(true);
        return;
      }
    }

    setIsAdmin(false);
  }, []);

  const { children } = props;

  if (isAdmin === undefined) {
    return <React.Fragment />;
  }

  return (
    <ProtectedRoute>
      {isAdmin ? children : <Navigate to={"/simulation"} />}
    </ProtectedRoute>
  );
};

export const ProtectedRoute = (props: { children: React.ReactNode }) => {
  const auth = useAuth();

  const [hasAccess, setHasAccess] = React.useState<boolean | undefined>(
    undefined
  );

  const { children } = props;

  React.useEffect(() => {
    setHasAccess(getRoles(auth)?.length > 0);
  }, []);

  if (hasAccess === undefined) {
    return <React.Fragment />;
  }

  if (!hasAccess) {
    return (
      <Grid container sx={{ pl: "45px" }}>
        {" "}
        <Alert
          children="Access denied. Please make the ServiceNow request to receive access."
          sx={{ width: "100%", ml: "-25px" }}
          severity="info"
        />{" "}
      </Grid>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
