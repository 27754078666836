import React from "react";
import Header from "../../../../components/Header";
import { Grid } from "@mui/material";
import { formatNumber } from "../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const OngoingServiceRelatedCosts = (): React.ReactElement => {
  const { output } = React.useContext(AppContext) as AppContextTypes;

  if (output === undefined) return <React.Fragment />;

  if (
    parseFloat(output.ongoingServiceRelatedCostsTotalValue) === 0.0 ||
    isNaN(parseFloat(output.ongoingServiceRelatedCostsTotalValue))
  )
    return <React.Fragment />;

  return (
    <React.Fragment>
      <Grid container marginTop={3}>
        <Header title={output.ongoingServiceRelatedCosts} />
      </Grid>
      <Grid item xs={12}>
        {output.bsfLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.bsfLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(parseFloat(output.bsfValue), { decimals: 3 })}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.bsfSpecialConditionsLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.bsfSpecialConditionsLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(parseFloat(output.bsfSpecialConditionsValue), {
                decimals: 3,
              })}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.bsfTotalLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.bsfTotalLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(parseFloat(output.bsfTotalValue), {
                  decimals: 3,
                })}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.asfLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.asfLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(parseFloat(output.asfValue), { decimals: 3 })}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.asfSpecialConditionsLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.asfSpecialConditionsLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(parseFloat(output.asfSpecialConditionsValue), {
                decimals: 3,
              })}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.asfTotalLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.asfTotalLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(parseFloat(output.asfTotalValue), {
                  decimals: 3,
                })}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.csfLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.csfLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(parseFloat(output.csfValue), {
                decimals: 3,
              })}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.csfSpecialConditionsLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.csfSpecialConditionsLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(parseFloat(output.csfSpecialConditionsValue), {
                decimals: 3,
              })}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.csfTotalLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.csfTotalLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(parseFloat(output.csfTotalValue), {
                  decimals: 3,
                })}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.ongoingServiceRelatedCostsTotalLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.ongoingServiceRelatedCostsTotalLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(
                  parseFloat(output.ongoingServiceRelatedCostsTotalValue),
                  {
                    decimals: 3,
                  }
                )}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.srlPercentageOnInvestmentAmountLabel ? (
          <div style={{ width: "100%" }}>
            <span>{output.srlPercentageOnInvestmentAmountLabel}</span>
            <span style={{ float: "right" }}>
              {output.srlPercentageOnInvestmentAmountValue}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}
      </Grid>
    </React.Fragment>
  );
};

export default OngoingServiceRelatedCosts;
