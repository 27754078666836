import BookingCenter from "../../../../../../models/BookingCenter";
import { DropdownItem } from "../../../../../../components/Dropdown";
import RMLocation from "../../../../../../models/RMLocation";
import ServiceModel from "../../../../../../models/ServiceModel";
import ServicePackage from "../../../../../../models/ServicePackage";

export const bookingCentersToDropdownItems = (bookingCenters: BookingCenter[]): DropdownItem[] => {
    return bookingCenters.map<DropdownItem>((bookingCenter: BookingCenter): DropdownItem => {
        return  {
            value: bookingCenter.id,
            label: bookingCenter.code
        };
    });
}

export const rmLocationsToDropdownItems = (rmLocations: RMLocation[]): DropdownItem[] => {
    return rmLocations.map<DropdownItem>((rmLocation: RMLocation): DropdownItem => {
        return {
            value: rmLocation.id,
            label: rmLocation.code
        };
    });
}

export const serviceModelsToDropdownItems = (serviceModels: ServiceModel[]): DropdownItem[] => {
    return serviceModels.map<DropdownItem>((serviceModel: ServiceModel): DropdownItem => {
        return {
            value: serviceModel.id,
            label: serviceModel.text
        };
    });
}

export const servicePackagesToDropdownItems = (servicePackages: ServicePackage[]): DropdownItem[] => {
    return servicePackages.map<DropdownItem>((servicePackage: ServicePackage): DropdownItem => {
        return {
            value: servicePackage.id,
            label: servicePackage.text
        };
    });
}