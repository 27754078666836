// action types
import TransactionCostConstant from "../models/TransactionCostConstant";

export const TRANSACTION_COST_CONSTANT_GET_API_REQUEST =
  "TRANSACTION_COST_CONSTANT_GET_API_REQUEST";
export const TRANSACTION_COST_CONSTANT_GET_API_SUCCESS =
  "TRANSACTION_COST_CONSTANT_GET_API_SUCCESS";
export const TRANSACTION_COST_CONSTANT_GET_API_FAILURE =
  "TRANSACTION_COST_CONSTANT_GET_API_FAILURE";
export const TRANSACTION_COST_CONSTANT_SELECTED =
  "TRANSACTION_COST_CONSTANT_SELECTED";

export interface TransactionCostConstantState {
  transactionCostConstants: TransactionCostConstant[];
  selected?: number;
}

// reducer with initial state
const initialState: TransactionCostConstantState = {
  transactionCostConstants: [],
  selected: undefined,
};

interface Action {
  type: string;
  transactionCostConstants: TransactionCostConstant[];
  selected?: number;
}

export default function transactionCostConstantReducer(
  state: TransactionCostConstantState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case TRANSACTION_COST_CONSTANT_GET_API_SUCCESS:
      newState.transactionCostConstants = action.transactionCostConstants;
      break;
    case TRANSACTION_COST_CONSTANT_GET_API_FAILURE:
      break;
    case TRANSACTION_COST_CONSTANT_SELECTED:
      newState.selected = action.selected;
      break;
  }

  return newState;
}
