import React from "react";
import Language from "../models/Language";
import ServicePackage from "../models/ServicePackage";
import BookingCenter from "../models/BookingCenter";
import BrokerageModel from "../models/BrokerageModel";
import InvestmentStrategy from "../models/InvestmentStrategy";
import ValueBand from "../models/ValueBand";
import ServiceModel from "../models/ServiceModel";
import RMLocation from "../models/RMLocation";
import FeeClassMatrix from "../models/FeeClassMatrix";
import FeeTypeMatrix from "../models/FeeTypeMatrix";
import TransactionCostConstant from "../models/TransactionCostConstant";
import { specialConditionTypes } from "./costants";
import Output from "../models/Output";

export interface AppContextTypes {
  clientName?: string;
  setClientName?: (value: string) => void;
  percentageInsteadOfBps?: boolean;
  setPercentageInsteadOfBps?: (value: boolean) => void;
  outputLanguage?: Language;
  setOutputLanguage?: (value: Language) => void;
  bcCurrency?: string;
  setBcCurrency?: (value: string) => void;
  outputCurrency?: string;
  setOutputCurrency?: (value: string) => void;
  servicePackage?: ServicePackage;
  setServicePackage?: (value: ServicePackage) => void;
  bookingCenter?: BookingCenter;
  setBookingCenter?: (value: BookingCenter) => void;
  brokerageModel?: BrokerageModel;
  setBrokerageModel?: (value: BrokerageModel) => void;
  investmentStrategyRequired?: boolean;
  setInvestmentStrategyRequired?: (value: boolean) => void;
  investmentStrategy?: InvestmentStrategy;
  setInvestmentStrategy?: (value: InvestmentStrategy) => void;
  exchangeRate?: number;
  setExchangeRate?: (value: number | undefined) => void;
  taValueBands?: ValueBand[];
  iaValueBands?: ValueBand[];
  setValueBands?: (value: ValueBand[]) => void;
  serviceModel?: ServiceModel;
  setServiceModel?: (value: ServiceModel) => void;
  rmLocation?: RMLocation;
  setRMLocation?: (value: RMLocation) => void;
  investmentAmount?: number | string;
  setInvestmentAmount?: (value: number | string) => void;
  feeClassMatrices?: FeeClassMatrix[];
  setFeeClassMatrices?: (value: FeeClassMatrix[]) => void;
  feeTypeMatrices?: FeeTypeMatrix[];
  setFeeTypeMatrices?: (value: FeeTypeMatrix[]) => void;
  bsFeeFlatAmount?: number;
  setBsFeeFlatAmount?: (value: number | undefined) => void;
  bsfDiscount?: number;
  setBsfDiscount?: (value: number) => void;
  bsfAmount?: number;
  setBsfAmount?: (value: number) => void;
  bespoke?: boolean;
  setBespoke?: (value: boolean) => void;
  transactionCostConstants?: TransactionCostConstant[];
  setTransactionCostConstants?: (value: TransactionCostConstant[]) => void;
  asfSpecialCondition?: string;
  asfMinFeeWaived?: boolean;
  setASFMinFeeWaived?: (value: boolean) => void;
  setASFSpecialCondition?: (value: string) => void;
  asfSpecialConditionAmount?: number;
  setASFSpecialConditionAmount?: (value: number | string) => void;
  asfTotal?: number;
  setAsfTotal?: (value: number) => void;
  asfSoko?: number;
  setAsfSoko?: (value: number) => void;
  csfMinFeeWaived?: boolean;
  setCSFMinFeeWaived?: (value: boolean) => void;
  csfSpecialCondition?: string;
  setCSFSpecialCondition?: (value: string) => void;
  csfSpecialConditionAmount?: number | undefined;
  setCSFSpecialConditionAmount?: (value: number | undefined) => void;
  csfTotal?: number;
  setCsfTotal?: (value: number) => void;
  csfSoko?: number;
  setCsfSoko?: (value: number) => void;
  bankServiceFee?: number;
  setBankServiceFee?: (value: number) => void;
  advisoryServiceFee?: number;
  setAdvisoryServiceFee?: (value: number) => void;
  custodyServiceFee?: number;
  setCustodyServiceFee?: (value: number) => void;
  trcSpecialCondition?: string;
  setTrcSpecialCondition?: (value: string) => void;
  trcSpecialConditionAmount?: number;
  setTrcSpecialConditionAmount?: (value: number) => void;
  transactionRelatedCosts?: number;
  setTransactionRelatedCosts?: (value: number) => void;
  output?: Output;
  setOutput?: (value: Output) => void;
  valueBand1?: number;
  setValueBand1?: (value: number) => void;
  valueBand2?: number;
  setValueBand2?: (value: number) => void;
  valueBand3?: number;
  setValueBand3?: (value: number) => void;
  valueBand4?: number;
  setValueBand4?: (value: number) => void;
  bespokeFee?: number;
  setBespokeFee?: (value: number) => void;
  asfBand1?: number;
  setASFBand1?: (value: number) => void;
  asfBand2?: number;
  setASFBand2?: (value: number) => void;
  asfBand3?: number;
  setASFBand3?: (value: number) => void;
  asfBand4?: number;
  setASFBand4?: (value: number) => void;
  asfMinFee?: number;
  setASFMinFee?: (value: number) => void;
  csfBand1?: number;
  setCSFBand1?: (value: number) => void;
  csfBand2?: number;
  setCSFBand2?: (value: number) => void;
  csfBand3?: number;
  setCSFBand3?: (value: number) => void;
  csfBand4?: number;
  setCSFBand4?: (value: number) => void;
  csfMinFee?: number;
  setCSFMinFee?: (value: number) => void;
  trcAvgTxSize?: number;
  setTrcAvgTxSize?: (value: number) => void;
  masFee?: number;
  setMASFee?: (value: number) => void;
  trcMinFee?: number;
  setTrcMinFee?: (value: number) => void;
  noTxLow?: number;
  setNoTxLow?: (value: number) => void;
  noTxMedium?: number;
  setNoTxMedium?: (value: number) => void;
  noTxHigh?: number;
  setNoTxHigh?: (value: number) => void;
  trcValueBand1?: number;
  setTrcValueBand1?: (value: number) => void;
  trcValueBand2?: number;
  setTrcValueBand2?: (value: number) => void;
  trcValueBand3?: number;
  setTrcValueBand3?: (value: number) => void;
  trcValueBand4?: number;
  setTrcValueBand4?: (value: number) => void;
  trcValueBand5?: number;
  setTrcValueBand5?: (value: number) => void;
  trcRateBand1Low?: number;
  setTrcRateBand1Low?: (value: number) => void;
  trcRateBand2Low?: number;
  setTrcRateBand2Low?: (value: number) => void;
  trcRateBand3Low?: number;
  setTrcRateBand3Low?: (value: number) => void;
  trcRateBand4Low?: number;
  setTrcRateBand4Low?: (value: number) => void;
  trcRateBand5Low?: number;
  setTrcRateBand5Low?: (value: number) => void;
  trcMasLow?: number;
  setTrcMasLow?: (value: number) => void;
  trcMasMedium?: number;
  setTrcMasMedium?: (value: number) => void;
  trcMasHigh?: number;
  setTrcMasHigh?: (value: number) => void;
  trcBpsIsDm?: number;
  setTrcBpsIsDM?: (value: number) => void;
  trcAmountLow?: number;
  setTrcAmountLow?: (value: number) => void;
  trcAmountMedium?: number;
  setTrcAmountMedium?: (value: number) => void;
  trcAmountHigh?: number;
  setTrcAmountHigh?: (value: number) => void;
  productRelatedCosts?: number;
  setProductRelatedCosts?: (value: number) => void;
  overridePercentageOfPRC?: number;
  setOverridePercentageOfPRC?: (value: number | undefined) => void;
  remarks?: string;
  setRemarks?: (value: string) => void;
  prcModelPortfolioStrategy?: number | undefined;
  setPrcModelPortfolioStrategy?: (value: number | undefined) => void;
  refModelPortfolioStrategyForPrc?: string | undefined;
  setRefModelPortfolioStrategyForPrc?: (value: string | undefined) => void;
  calculateCanBeEnabled?: { name: string }[];
  setCalculateCanBeEnabled?: (value: { name: string }[]) => void;
}

export const AppContext = React.createContext<AppContextTypes | null>(null);

export interface AppProviderProperties {
  children: React.ReactNode;
}

export const AppProvider = (
  props: AppProviderProperties
): React.ReactElement => {
  const [clientName, setClientName] = React.useState<string>(""),
    [percentageInsteadOfBps, setPercentageInsteadOfBps] =
      React.useState<boolean>(true),
    [outputLanguage, setOutputLanguage] = React.useState<Language>(),
    [bcCurrency, setBcCurrency] = React.useState<string>(""),
    [outputCurrency, setOutputCurrency] = React.useState<string>(""),
    [servicePackage, setServicePackage] = React.useState<ServicePackage>(),
    [bookingCenter, setBookingCenter] = React.useState<BookingCenter>(),
    [brokerageModel, setBrokerageModel] = React.useState<BrokerageModel>(),
    [investmentStrategyRequired, setInvestmentStrategyRequired] =
      React.useState<boolean>(true),
    [investmentStrategy, setInvestmentStrategy] =
      React.useState<InvestmentStrategy>(),
    [exchangeRate, setExchangeRate] = React.useState<number | undefined>(1.0),
    [valueBands, setValueBands] = React.useState<ValueBand[]>([]),
    [serviceModel, setServiceModel] = React.useState<ServiceModel>(),
    [rmLocation, setRMLocation] = React.useState<RMLocation>(),
    [investmentAmount, setInvestmentAmount] = React.useState<number | string>(
      0
    ),
    [feeClassMatrices, setFeeClassMatrices] = React.useState<FeeClassMatrix[]>(
      []
    ),
    [feeTypeMatrices, setFeeTypeMatrices] = React.useState<FeeTypeMatrix[]>([]),
    [bsFeeFlatAmount, setBsFeeFlatAmount] = React.useState<number>(),
    [bsfDiscount, setBsfDiscount] = React.useState<number>(),
    [bsfAmount, setBsfAmount] = React.useState<number>(),
    [bespoke, setBespoke] = React.useState<boolean>(false),
    [transactionCostConstants, setTransactionCostConstants] = React.useState<
      TransactionCostConstant[]
    >([]),
    [asfMinFeeWaived, setASFMinFeeWaived] = React.useState<boolean>(false),
    [asfSpecialCondition, setASFSpecialCondition] = React.useState<string>(
      specialConditionTypes[0].value as string
    ),
    [asfSpecialConditionAmount, setASFSpecialConditionAmount] =
      React.useState<any>(),
    [csfMinFeeWaived, setCSFMinFeeWaived] = React.useState<boolean>(false),
    [csfSpecialCondition, setCSFSpecialCondition] = React.useState<string>(
      specialConditionTypes[0].value as string
    ),
    [csfSpecialConditionAmount, setCSFSpecialConditionAmount] =
      React.useState<number>(),
    [bankServiceFee, setBankServiceFee] = React.useState<number>(0),
    [advisoryServiceFee, setAdvisoryServiceFee] = React.useState<number>(0),
    [custodyServiceFee, setCustodyServiceFee] = React.useState<number>(0),
    [trcSpecialCondition, setTrcSpecialCondition] = React.useState<string>(
      specialConditionTypes[0].value as string
    ),
    [trcSpecialConditionAmount, setTrcSpecialConditionAmount] =
      React.useState<number>(0),
    [transactionRelatedCosts, setTransactionRelatedCosts] =
      React.useState<number>(0),
    [output, setOutput] = React.useState<Output>(),
    [valueBand1, setValueBand1] = React.useState<number>(0),
    [valueBand2, setValueBand2] = React.useState<number>(0),
    [valueBand3, setValueBand3] = React.useState<number>(0),
    [valueBand4, setValueBand4] = React.useState<number>(0),
    [bespokeFee, setBespokeFee] = React.useState<number>(0),
    [asfBand1, setASFBand1] = React.useState<number>(0),
    [asfBand2, setASFBand2] = React.useState<number>(0),
    [asfBand3, setASFBand3] = React.useState<number>(0),
    [asfBand4, setASFBand4] = React.useState<number>(0),
    [asfMinFee, setASFMinFee] = React.useState<number>(0),
    [csfBand1, setCSFBand1] = React.useState<number>(0),
    [csfBand2, setCSFBand2] = React.useState<number>(0),
    [csfBand3, setCSFBand3] = React.useState<number>(0),
    [csfBand4, setCSFBand4] = React.useState<number>(0),
    [csfMinFee, setCSFMinFee] = React.useState<number>(0),
    [trcAvgTxSize, setTrcAvgTxSize] = React.useState<number>(0),
    [masFee, setMASFee] = React.useState<number>(0),
    [trcMinFee, setTrcMinFee] = React.useState<number>(0),
    [noTxLow, setNoTxLow] = React.useState<number>(0),
    [noTxMedium, setNoTxMedium] = React.useState<number>(0),
    [noTxHigh, setNoTxHigh] = React.useState<number>(0),
    [trcValueBand1, setTrcValueBand1] = React.useState<number>(0),
    [trcValueBand2, setTrcValueBand2] = React.useState<number>(0),
    [trcValueBand3, setTrcValueBand3] = React.useState<number>(0),
    [trcValueBand4, setTrcValueBand4] = React.useState<number>(0),
    [trcValueBand5, setTrcValueBand5] = React.useState<number>(0),
    [trcRateBand1Low, setTrcRateBand1Low] = React.useState<number>(0),
    [trcRateBand2Low, setTrcRateBand2Low] = React.useState<number>(0),
    [trcRateBand3Low, setTrcRateBand3Low] = React.useState<number>(0),
    [trcRateBand4Low, setTrcRateBand4Low] = React.useState<number>(0),
    [trcRateBand5Low, setTrcRateBand5Low] = React.useState<number>(0),
    [trcMasLow, setTrcMasLow] = React.useState<number>(0),
    [trcMasMedium, setTrcMasMedium] = React.useState<number>(0),
    [trcMasHigh, setTrcMasHigh] = React.useState<number>(0),
    [trcAmountLow, setTrcAmountLow] = React.useState<number>(0),
    [trcAmountMedium, setTrcAmountMedium] = React.useState<number>(0),
    [trcAmountHigh, setTrcAmountHigh] = React.useState<number>(0),
    [trcBpsIsDm, setTrcBpsIsDM] = React.useState<number>(0),
    [csfTotal, setCsfTotal] = React.useState<number>(),
    [asfTotal, setAsfTotal] = React.useState<number>(),
    [csfSoko, setCsfSoko] = React.useState<number>(),
    [productRelatedCosts, setProductRelatedCosts] = React.useState<number>(0),
    [asfDiscount, setAsfDiscount] = React.useState<number>(),
    [asfAmount, setAsfAmount] = React.useState<number>(),
    [remarks, setRemarks] = React.useState<string>(""),
    [prcModelPortfolioStrategy, setPrcModelPortfolioStrategy] = React.useState<
      number | undefined
    >(undefined),
    [asfSoko, setAsfSoko] = React.useState<number>(0),
    [overridePercentageOfPRC, setOverridePercentageOfPRC] = React.useState<
      number | undefined
    >(undefined),
    [refModelPortfolioStrategyForPrc, setRefModelPortfolioStrategyForPrc] =
      React.useState<string | undefined>(""),
    [calculateCanBeEnabled, setCalculateCanBeEnabled] = React.useState<
      { name: string }[]
    >([]);

  const taValueBands = valueBands.filter((vb) => vb.code.startsWith("TA_")),
    iaValueBands = valueBands.filter((vb) => vb.code.startsWith("IA_"));

  const value = {
    clientName,
    setClientName,
    percentageInsteadOfBps,
    setPercentageInsteadOfBps,
    outputLanguage,
    setOutputLanguage,
    bcCurrency,
    setBcCurrency,
    outputCurrency,
    setOutputCurrency,
    servicePackage,
    setServicePackage,
    bookingCenter,
    setBookingCenter,
    brokerageModel,
    setBrokerageModel,
    investmentStrategyRequired,
    setInvestmentStrategyRequired,
    investmentStrategy,
    setInvestmentStrategy,
    exchangeRate,
    setExchangeRate,
    taValueBands,
    iaValueBands,
    setValueBands,
    serviceModel,
    setServiceModel,
    rmLocation,
    setRMLocation,
    investmentAmount,
    setInvestmentAmount,
    feeClassMatrices,
    setFeeClassMatrices,
    feeTypeMatrices,
    setFeeTypeMatrices,
    bsFeeFlatAmount,
    setBsFeeFlatAmount,
    bespoke,
    setBespoke,
    transactionCostConstants,
    setTransactionCostConstants,
    asfMinFeeWaived,
    setASFMinFeeWaived,
    asfSpecialCondition,
    setASFSpecialCondition,
    asfSpecialConditionAmount,
    setASFSpecialConditionAmount,
    csfMinFeeWaived,
    setCSFMinFeeWaived,
    csfSpecialCondition,
    setCSFSpecialCondition,
    csfSpecialConditionAmount,
    setCSFSpecialConditionAmount,
    bankServiceFee,
    setBankServiceFee,
    advisoryServiceFee,
    setAdvisoryServiceFee,
    custodyServiceFee,
    setCustodyServiceFee,
    trcSpecialCondition,
    setTrcSpecialCondition,
    trcSpecialConditionAmount,
    setTrcSpecialConditionAmount,
    transactionRelatedCosts,
    setTransactionRelatedCosts,
    output,
    setOutput,
    valueBand1,
    setValueBand1,
    valueBand2,
    setValueBand2,
    valueBand3,
    setValueBand3,
    valueBand4,
    setValueBand4,
    asfBand1,
    setASFBand1,
    asfBand2,
    setASFBand2,
    asfBand3,
    setASFBand3,
    asfBand4,
    setASFBand4,
    asfMinFee,
    setASFMinFee,
    csfBand1,
    setCSFBand1,
    csfBand2,
    setCSFBand2,
    csfBand3,
    setCSFBand3,
    csfBand4,
    setCSFBand4,
    csfMinFee,
    setCSFMinFee,
    bespokeFee,
    setBespokeFee,
    trcAvgTxSize,
    setTrcAvgTxSize,
    masFee,
    setMASFee,
    trcMinFee,
    setTrcMinFee,
    noTxLow,
    setNoTxLow,
    noTxMedium,
    setNoTxMedium,
    noTxHigh,
    setNoTxHigh,
    trcValueBand1,
    setTrcValueBand1,
    trcValueBand2,
    setTrcValueBand2,
    trcValueBand3,
    setTrcValueBand3,
    trcValueBand4,
    setTrcValueBand4,
    trcValueBand5,
    setTrcValueBand5,
    trcRateBand1Low,
    setTrcRateBand1Low,
    trcRateBand2Low,
    setTrcRateBand2Low,
    trcRateBand3Low,
    setTrcRateBand3Low,
    trcRateBand4Low,
    setTrcRateBand4Low,
    trcRateBand5Low,
    setTrcRateBand5Low,
    trcMasLow,
    setTrcMasLow,
    trcMasMedium,
    setTrcMasMedium,
    trcMasHigh,
    setTrcMasHigh,
    trcBpsIsDm,
    setTrcBpsIsDM,
    trcAmountLow,
    setTrcAmountLow,
    trcAmountMedium,
    setTrcAmountMedium,
    trcAmountHigh,
    setTrcAmountHigh,
    csfTotal,
    setCsfTotal,
    csfSoko,
    setCsfSoko,
    productRelatedCosts,
    setProductRelatedCosts,
    remarks,
    setRemarks,
    bsfDiscount,
    setBsfDiscount,
    bsfAmount,
    setBsfAmount,
    asfDiscount,
    setAsfDiscount,
    asfAmount,
    setAsfAmount,
    asfTotal,
    setAsfTotal,
    prcModelPortfolioStrategy,
    setPrcModelPortfolioStrategy,
    asfSoko,
    setAsfSoko,
    overridePercentageOfPRC,
    setOverridePercentageOfPRC,
    refModelPortfolioStrategyForPrc,
    setRefModelPortfolioStrategyForPrc,
    calculateCanBeEnabled,
    setCalculateCanBeEnabled,
  };

  const { children } = props;

  return (
    <React.Fragment>
      <AppContext.Provider value={value}>{children}</AppContext.Provider>
    </React.Fragment>
  );
};

export default AppContext;
