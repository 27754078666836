/* istanbul ignore file */
import React from "react";
import { UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import { AuthProvider } from "react-oidc-context";
import env from "./env";

interface IAppConfigProps {
  children: React.ReactNode;
}

export const AuthConfig: React.FC<IAppConfigProps> = ({ children }) => {
  // http://docs.identityserver.io/en/latest/quickstarts/4_javascript_client.html
  const authProviderProps: UserManagerSettings = {
    authority: env.REACT_APP_ADFS_AUTHORITY as string,
    client_id: env.REACT_APP_ADFS_CLIENT_ID as string,
    resource: env.REACT_APP_ADFS_RESOURCE as string, // resource is needed for adfs
    scope: "User.Read email openid offline profile",
    redirect_uri: env.REACT_APP_ADFS_REDIRECT_URL as string,
    post_logout_redirect_uri: env.REACT_APP_ADFS_REDIRECT_URL as string,
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: localStorage }),
  };

  return <AuthProvider {...authProviderProps}>{children}</AuthProvider>;
};
