import React, { useEffect } from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import { InputAdornment } from "@mui/material";
import { formatNumber } from "../../../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Bands = (): React.ReactElement => {
  const {
    investmentAmount,
    feeClassMatrices,
    setASFBand1,
    setASFBand2,
    setASFBand3,
    setASFBand4,
    serviceModel,
  } = React.useContext(AppContext) as AppContextTypes;

  const band1 = feeClassMatrices?.find(
    (fcm) =>
      fcm.feeType.text === "Advisory Service Fee" &&
      fcm.feeClass.text === "Band_1"
  )?.amount as number;

  const band2 = feeClassMatrices?.find(
    (fcm) =>
      fcm.feeType.text === "Advisory Service Fee" &&
      fcm.feeClass.text === "Band_2"
  )?.amount as number;

  const band3 = feeClassMatrices?.find(
    (fcm) =>
      fcm.feeType.text === "Advisory Service Fee" &&
      fcm.feeClass.text === "Band_3"
  )?.amount as number;

  const band4 = feeClassMatrices?.find(
    (fcm) =>
      fcm.feeType.text === "Advisory Service Fee" &&
      fcm.feeClass.text === "Band_4"
  )?.amount as number;

  useEffect(() => {
    if (setASFBand1) {
      setASFBand1(serviceModel ? band1 : 0);
    }
    if (setASFBand2) {
      setASFBand2(serviceModel ? band2 : 0);
    }
    if (setASFBand3) {
      setASFBand3(serviceModel ? band3 : 0);
    }
    if (setASFBand4) {
      setASFBand4(serviceModel ? band4 : 0);
    }
  }, [band1, band2, band3, band4]);

  const hiddenStyle =
    !investmentAmount || !serviceModel ? { display: "none" } : {};

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="asf-band1"
          name="asf-band1"
          label="ASF Band 1"
          variant="filled"
          value={band1 ? formatNumber(Number(band1), { decimals: 2 }) : 0.0}
          style={hiddenStyle}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="asf-band2"
          name="asf-band2"
          label="ASF Band 2"
          variant="filled"
          value={band2 ? formatNumber(Number(band2), { decimals: 2 }) : 0.0}
          style={hiddenStyle}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="asf-band3"
          name="asf-band3"
          label="ASF Band 3"
          variant="filled"
          value={band3 ? formatNumber(Number(band3), { decimals: 2 }) : 0.0}
          style={hiddenStyle}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="asf-band4"
          name="asf-band4"
          label="ASF Band 4"
          variant="filled"
          value={band4 ? formatNumber(Number(band4), { decimals: 2 }) : 0.0}
          style={hiddenStyle}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default Bands;
