import React from "react";
import BasicOutput from "./components/BasicOutput";
import ValueBandsOutput from "./components/ValueBandsOutput";
import BankServiceFeeOutput from "./components/BankServiceFeeOutput";
import AdvisoryServiceFeeOutput from "./components/AdvisoryServiceFeeOutput";
import CustodyServiceFeeOutput from "./components/CustodyServiceFeeOutput";
import TransactionRelatedCostsOutput from "./components/TransactionRelatedCostsOutput";
import { StyledSection } from "./index.style";

const Sidebar = (): React.ReactElement => {
  return (
    <React.Fragment>
      <StyledSection>
        <BasicOutput />
        <ValueBandsOutput />
        <BankServiceFeeOutput />
        <AdvisoryServiceFeeOutput />
        <CustodyServiceFeeOutput />
        <TransactionRelatedCostsOutput />
      </StyledSection>
    </React.Fragment>
  );
};

export default Sidebar;
