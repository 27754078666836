import { takeLatest, call, put } from "redux-saga/effects";
import {
    LANGUAGE_GET_API_REQUEST,
    LANGUAGE_GET_API_SUCCESS,
    LANGUAGE_GET_API_FAILURE
} from "../reducers/languages-reducer";
import api, { GetResponse } from "../api";
import Language from "../models/Language";

function getLanguages(): Promise<GetResponse<Language>> {
    return api.get<Language>({
        endpoint: '/languages'
    });
}

function* retrieveLanguages() {
    try {
        const response: GetResponse<Language> = yield call(getLanguages);
        yield put({ type: LANGUAGE_GET_API_SUCCESS, languages: response.entities });
    } catch (error) {
        yield put({ type: LANGUAGE_GET_API_FAILURE, error });
    }
}

export default function* languagesSaga() {
    yield takeLatest(LANGUAGE_GET_API_REQUEST, retrieveLanguages);
}
