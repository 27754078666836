import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EditPageLayout } from "../../layouts/EditPageLayout";
import { EditFeeClassMatrixForm } from "./components/EditFeeClassMatrixForm";

export const EditFeeClassMatrix = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, [id, navigate]);

  return (
    <EditPageLayout>
      <EditFeeClassMatrixForm id={id} />
    </EditPageLayout>
  );
};
