/* istanbul ignore file */
import React from "react";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  ThemeProvider,
} from "@mui/material";
import { AppProvider } from "./index.ctx";
import { theme } from "./index.theme";
import store from "../store";
import { Provider } from "react-redux";
import { useAuth } from "react-oidc-context";
import { AppRoutes } from "./app-routes";
import { BrowserRouter } from "react-router-dom";

const App = (): React.ReactElement => {
  const auth = useAuth();

  React.useEffect(() => {
    auth.startSilentRenew();
  }, [auth.startSilentRenew]);

  React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
  }, [auth.signinSilent, auth.events.addAccessTokenExpiring]);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Container maxWidth="xl" sx={{ mt: 16, mb: 4 }}>
              <Grid container spacing={3}>
                <AppProvider>
                  <Provider store={store}>
                    <BrowserRouter>
                      <AppRoutes />
                    </BrowserRouter>
                  </Provider>
                </AppProvider>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
