import React from "react";
import Sidebar from "./components/Sidebar";
import Form from "./components/Form";
import Header from "../../components/Header";
import Card from "../../components/Card";
import {
  ButtonWrapper,
  StyledButton,
  StyledDiv,
  StyledSection,
} from "./index.style";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { outputCalculation } from "../../utils/audit";
import { useAuth } from "react-oidc-context";
import { connect } from "react-redux";
import InputDataProps, { mapDispatchToProps } from "./index.props";
import { useValidateForm } from "../../hooks/useValidateForm";
import { getAccessToken, getRoles, parseJwt } from "utils/jwtUtils";

const WrapperInputData = (): React.ReactElement => {
  const [isAdmin, setIsAdmin] = React.useState<boolean | undefined>(undefined);

  const auth = useAuth();
  const { isValid } = useValidateForm();
  const navigate = useNavigate();

  React.useEffect(() => {
    for (const role of getRoles(auth)) {
      if (role.toLowerCase().includes("admin")) {
        setIsAdmin(true);
        return;
      }
    }

    setIsAdmin(false);
  }, [auth]);

  const onCalculate = (): void => {
    const token = parseJwt(getAccessToken() ?? "");
    outputCalculation(token.sub)
      .then((_result) => {
        navigate("/output", { replace: true });
      })
      .catch((_error) => {
        navigate("/output", { replace: true });
      });
  };

  const reset = () => {
    window.location.reload();
  };

  return (
    <React.Fragment>
      {isAdmin !== undefined && (
        <Card
          title={
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              PCS Simulation
            </div>
          }
          Component={StyledSection}
          sticky
          actions={
            <React.Fragment>
              <ButtonWrapper>
                <StyledButton
                  variant={"outlined"}
                  color={"secondary"}
                  onClick={reset}
                >
                  Reset
                </StyledButton>
                {isValid ? (
                  <StyledButton
                    id="calculate-button"
                    variant="contained"
                    color="primary"
                    onClick={onCalculate}
                  >
                    Calculate PCS
                  </StyledButton>
                ) : (
                  <StyledButton
                    id="calculate-button"
                    variant="contained"
                    color="primary"
                    disabled
                  >
                    Calculate PCS
                  </StyledButton>
                )}
              </ButtonWrapper>
            </React.Fragment>
          }
        >
          <Grid item xs={isAdmin ? 8 : 12} sx={{ ml: "-20px" }}>
            <StyledDiv>
              <Header
                title={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Input-parameters
                  </div>
                }
              />
              <Form />
            </StyledDiv>
          </Grid>

          <Grid item xs={4} sx={{ display: isAdmin ? "block" : "none" }}>
            <StyledDiv>
              <Header
                title={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Calculation-parameters
                  </div>
                }
              />
              <Sidebar />
            </StyledDiv>
          </Grid>
        </Card>
      )}
    </React.Fragment>
  );
};

export const InputData = ({
  onRequestGetInvestmentStrategiesFullList,
}: InputDataProps): React.ReactElement => {
  React.useEffect(() => {
    onRequestGetInvestmentStrategiesFullList();
  }, [onRequestGetInvestmentStrategiesFullList]);

  return (
    <React.Fragment>
      <WrapperInputData />
    </React.Fragment>
  );
};

export default connect(() => ({}), mapDispatchToProps)(InputData);
