import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import {
  defaultNumberTo,
  formatNumber,
} from "../../../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

export function defaultZero(value: number | undefined): number {
  return isNaN(value as number) ? 0 : (value as number);
}

const Bands = (): React.ReactElement => {
  const {
    investmentAmount,
    taValueBands,
    transactionCostConstants,
    setTrcAvgTxSize,
    setTrcValueBand1,
    setTrcValueBand2,
    setTrcValueBand3,
    setTrcValueBand4,
    setTrcValueBand5,
    exchangeRate,
  } = React.useContext(AppContext) as AppContextTypes;

  const opk12Val = transactionCostConstants?.find(
    (tcc) => tcc.code.toLowerCase() === "OPk_12".toLowerCase()
  )?.val as number;
  const opk13Val = transactionCostConstants?.find(
    (tcc) => tcc.code.toLowerCase() === "OPk_13".toLowerCase()
  )?.val as number;
  const opk14Val = transactionCostConstants?.find(
    (tcc) => tcc.code.toLowerCase() === "OPk_14".toLowerCase()
  )?.val as number;

  const trcAvgTxSize = Math.max(
    opk13Val,
    Math.min(
      (investmentAmount as number) *
        defaultNumberTo(exchangeRate, 1) *
        opk12Val,
      opk14Val
    )
  );

  const band1ValMax = taValueBands?.find(
    (vb) => vb.code.toLowerCase() === "TA_BAND_1".toLowerCase()
  )?.valMax as number;
  const band1 = Math.min(trcAvgTxSize, band1ValMax);

  const band2ValMax = taValueBands?.find(
    (vb) => vb.code.toLowerCase() === "TA_BAND_2".toLowerCase()
  )?.valMax as number;
  const band2 =
    trcAvgTxSize < band2ValMax
      ? trcAvgTxSize - band1
      : band2ValMax - band1ValMax;

  const band3ValMax = taValueBands?.find(
    (vb) => vb.code.toLowerCase() === "TA_BAND_3".toLowerCase()
  )?.valMax as number;
  const band3 =
    trcAvgTxSize < band3ValMax
      ? trcAvgTxSize - band2 - band1
      : band3ValMax - band2ValMax;

  const band4ValMax = taValueBands?.find(
    (vb) => vb.code.toLowerCase() === "TA_BAND_4".toLowerCase()
  )?.valMax as number;
  const band4 =
    trcAvgTxSize < band4ValMax
      ? trcAvgTxSize - band3 - band2 - band1
      : band4ValMax - band3ValMax - band2ValMax - band1ValMax;

  const band5 = Math.max(trcAvgTxSize - band1 - band2 - band3 - band4, 0);

  React.useEffect(() => {
    if (setTrcAvgTxSize) {
      setTrcAvgTxSize(trcAvgTxSize);
    }
    if (setTrcValueBand1) {
      setTrcValueBand1(band1);
    }
    if (setTrcValueBand2) {
      setTrcValueBand2(band2);
    }
    if (setTrcValueBand3) {
      setTrcValueBand3(band3);
    }
    if (setTrcValueBand4) {
      setTrcValueBand4(band4);
    }
    if (setTrcValueBand5) {
      setTrcValueBand5(band5);
    }
  }, [trcAvgTxSize, band1, band2, band3, band4, band5]);

  if (!investmentAmount) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="trc-avg-tx-size"
          name="trc-avg-tx-size"
          label="TRC_Avg. Tx Size"
          variant="filled"
          value={
            isNaN(trcAvgTxSize)
              ? 0.0
              : formatNumber(trcAvgTxSize, { decimals: 2 })
          }
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="trc-band1"
          name="trc-band1"
          label="TRC Value Band 1"
          variant="filled"
          value={isNaN(band1) ? 0.0 : formatNumber(band1, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="trc-band2"
          name="trc-band2"
          label="TRC Value Band 2"
          variant="filled"
          value={isNaN(band2) ? 0.0 : formatNumber(band2, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="trc-band3"
          name="trc-band3"
          label="TRC Value Band 3"
          variant="filled"
          value={isNaN(band3) ? 0.0 : formatNumber(band3, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="trc-band4"
          name="trc-band4"
          label="TRC Value Band 4"
          variant="filled"
          value={isNaN(band4) ? 0.0 : formatNumber(band4, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="trc-band5"
          name="trc-band5"
          label="TRC Value Band 5"
          variant="filled"
          value={isNaN(band5) ? 0.0 : formatNumber(band5, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default Bands;
