import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../index.style";
import { InputAdornment } from "@mui/material";
import { defaultNumberTo, formatNumber } from "../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const BankServiceFeeOutput = (): React.ReactElement => {
  const {
    investmentAmount,
    outputCurrency,
    bcCurrency,
    feeClassMatrices,
    feeTypeMatrices,
    exchangeRate,
    bsFeeFlatAmount,
    setBankServiceFee,
    setBsfDiscount,
    setBsfAmount,
  } = React.useContext(AppContext) as AppContextTypes;

  const bankServiceFee = Math.ceil(
    feeTypeMatrices?.filter(
      (ftm) =>
        ftm.feeType.text.toLowerCase() === "Banking Service Fee".toLowerCase()
    ).length as number
  );

  let bankServiceFeePrice =
    (feeClassMatrices?.find(
      (fcm) =>
        fcm.feeType.text.toLowerCase() ===
          "Banking Service Fee".toLowerCase() &&
        fcm.feeClass.text.toLowerCase() === "Rate".toLowerCase()
    )?.amount as number) * 4;

  if (bankServiceFee === 0) {
    bankServiceFeePrice = 0;
  }

  const bankServiceFeePriceInOutputCurrency = Math.ceil(
    defaultNumberTo(bankServiceFeePrice, 0) / defaultNumberTo(exchangeRate, 1)
  );

  const bankServiceDiscount = Math.ceil(
    defaultNumberTo(
      (bsFeeFlatAmount ?? bankServiceFeePriceInOutputCurrency) -
        bankServiceFeePriceInOutputCurrency,
      0
    )
  );

  const bankServiceFeeHiddenStyle = !investmentAmount
    ? { display: "none" }
    : {};

  React.useEffect(() => {
    if (setBankServiceFee) {
      setBankServiceFee(bankServiceFee);
    }
    if (setBsfAmount) {
      setBsfAmount(bankServiceFeePrice);
    }
    if (setBsfDiscount) {
      setBsfDiscount(bankServiceDiscount);
    }
  }, [bankServiceFee, bankServiceFeePrice, bankServiceDiscount]);

  if (!investmentAmount) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="bank-service-fee"
          name="bank-service-fee"
          label={"Bank service fee"}
          variant="filled"
          value={formatNumber(bankServiceFee, { decimals: 2 })}
          style={bankServiceFeeHiddenStyle}
        />

        <StyledTextInput
          id="bank-service-fee-bc"
          name="bank-service-fee-bc"
          label={"Bank service fee in " + bcCurrency}
          variant="filled"
          value={formatNumber(Number(bankServiceFeePrice), { decimals: 2 })}
          style={bankServiceFeeHiddenStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
        />

        {outputCurrency !== bcCurrency && outputCurrency !== "" ? (
          <StyledTextInput
            id="bank-service-fee-out"
            name="bank-service-fee-out"
            label={"Bank service fee in " + outputCurrency}
            variant="filled"
            value={formatNumber(Number(bankServiceFeePriceInOutputCurrency), {
              decimals: 2,
            })}
            style={bankServiceFeeHiddenStyle}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {outputCurrency}
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <React.Fragment />
        )}

        <StyledTextInput
          id="bank-service-fee-discount"
          name="bank-service-fee-discount"
          label={"Bank service fee discount"}
          variant="filled"
          value={formatNumber(Number(bankServiceDiscount), { decimals: 2 })}
          style={bankServiceFeeHiddenStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{outputCurrency}</InputAdornment>
            ),
          }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default BankServiceFeeOutput;
