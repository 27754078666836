// action types
import BookingCenter from "../models/BookingCenter";

export const BOOKING_CENTER_GET_API_REQUEST = "BOOKING_CENTER_GET_API_REQUEST";
export const BOOKING_CENTER_GET_API_SUCCESS = "BOOKING_CENTER_GET_API_SUCCESS";
export const BOOKING_CENTER_GET_API_FAILURE = "BOOKING_CENTER_GET_API_FAILURE";
export const BOOKING_CENTER_SELECTED = "BOOKING_CENTER_SELECTED";

export interface BookingCenterState {
  bookingCenters: BookingCenter[];
  selected?: number;
}

// reducer with initial state
const initialState: BookingCenterState = {
  bookingCenters: [],
  selected: undefined,
};

interface Action {
  type: string;
  bookingCenters: BookingCenter[];
  selected?: number;
}

export default function bookingCentersReducer(
  state: BookingCenterState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case BOOKING_CENTER_GET_API_SUCCESS:
      newState.bookingCenters = action.bookingCenters;
      break;
    case BOOKING_CENTER_GET_API_FAILURE:
      break;
    case BOOKING_CENTER_SELECTED:
      newState.selected = action.selected;
      break;
  }

  return newState;
}
