// action types
import ValueBand from "../models/ValueBand";

export const VALUE_BAND_GET_API_REQUEST = "VALUE_BAND_GET_API_REQUEST";
export const VALUE_BAND_GET_API_SUCCESS = "VALUE_BAND_GET_API_SUCCESS";
export const VALUE_BAND_GET_API_FAILURE = "VALUE_BAND_GET_API_FAILURE";
export const VALUE_BAND_SELECTED = "VALUE_BAND_SELECTED";

export interface ValueBandState {
  valueBands: ValueBand[];
  selected?: number;
}

// reducer with initial state
const initialState: ValueBandState = {
  valueBands: [],
  selected: undefined,
};

interface Action {
  type: string;
  valueBands: ValueBand[];
  selected?: number;
}

export default function valueBandReducer(
  state: ValueBandState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case VALUE_BAND_GET_API_SUCCESS:
      newState.valueBands = action.valueBands;
      break;
    case VALUE_BAND_GET_API_FAILURE:
      break;
    case VALUE_BAND_SELECTED:
      newState.selected = action.selected;
      break;
  }

  return newState;
}
