import { Grid } from "@mui/material";
import { FormFieldLabel } from "../../../../hook-form/FormFieldLabel";
import { FormInput } from "../../../../hook-form/FormInput";
import { FormCurrencyInput } from "hook-form/FormCurrencyInput";

export interface SecondRowFieldsProps {
  control: any;
  getValues: any;
}

export const SecondRowFields = (props: SecondRowFieldsProps) => {
  const { control, getValues } = props;

  return (
    <>
      <Grid item xs={6}>
        <FormCurrencyInput
          name="valMin"
          label={<FormFieldLabel isRequired>Minimum Value</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
          rules={{
            required: "Minimum value is required.",
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <FormCurrencyInput
          name="valMax"
          label={<FormFieldLabel isRequired>Maximum Value</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
          rules={{
            required: "Maximum value is required",
            validate: (value: any) =>
              value >= getValues().valMin ||
              "Maximum Value must be equal or greater than Minimum Value.",
          }}
        />
      </Grid>
    </>
  );
};
