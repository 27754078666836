// action types
import BrokerageModel from "../models/BrokerageModel";

export const BROKERAGE_MODEL_GET_API_REQUEST =
  "BROKERAGE_MODEL_GET_API_REQUEST";
export const BROKERAGE_MODEL_GET_API_SUCCESS =
  "BROKERAGE_MODEL_GET_API_SUCCESS";
export const BROKERAGE_MODEL_GET_API_FAILURE =
  "BROKERAGE_MODEL_GET_API_FAILURE";
export const BROKERAGE_MODEL_SELECTED = "BROKERAGE_MODEL_SELECTED";
export const BROKERAGE_MODEL_CLEAR = "BROKERAGE_MODEL_CLEAR";

export interface BrokerageModelState {
  brokerageModels: BrokerageModel[];
  selected?: number;
}

// reducer with initial state
const initialState: BrokerageModelState = {
  brokerageModels: [],
  selected: undefined,
};

interface Action {
  type: string;
  brokerageModels: BrokerageModel[];
  selected?: number;
}

export default function brokerageModelsReducer(
  state: BrokerageModelState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case BROKERAGE_MODEL_GET_API_SUCCESS:
      newState.brokerageModels = action.brokerageModels;
      break;
    case BROKERAGE_MODEL_GET_API_FAILURE:
      break;
    case BROKERAGE_MODEL_SELECTED:
      newState.selected = action.selected;
      break;
    case BROKERAGE_MODEL_CLEAR:
      newState = initialState;
      break;
  }

  return newState;
}
