import Output from "../models/Output";

export const OUTPUT_POST_API_REQUEST = "OUTPUT_POST_API_REQUEST";
export const OUTPUT_POST_API_SUCCESS = "OUTPUT_POST_API_SUCCESS";
export const OUTPUT_POST_API_FAILURE = "OUTPUT_POST_API_FAILURE";
export const OUTPUT_CLEAR = "OUTPUT_CLEAR";

export interface OutputState {
  output?: Output;
}

const initialState: OutputState = {
  output: undefined,
};

interface Action {
  type: string;
  output: Output;
}

export default function valueBandReducer(
  state: OutputState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case OUTPUT_POST_API_SUCCESS:
      newState.output = action.output;
      break;
    case OUTPUT_CLEAR:
      newState.output = undefined;
      break;
    default:
      break;
  }

  return newState;
}
