import React from "react";
import Header from "../../../../components/Header";
import { Grid } from "@mui/material";
import { formatNumber } from "../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const TotalCosts = (): React.ReactElement => {
  const { output } = React.useContext(AppContext) as AppContextTypes;

  if (output === undefined) return <React.Fragment />;
  if (output.totalCosts === undefined) return <React.Fragment />;
  if (output.totalCosts === null) return <React.Fragment />;

  return (
    <React.Fragment>
      <Grid container marginTop={3}>
        <Header title={output.totalCosts} />
      </Grid>
      <Grid item xs={12}>
        {output.totalCostsTrcLowLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.totalCostsTrcLowLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(parseFloat(output.totalCostsTrcLowValue), {
                  decimals: 3,
                })}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.tcTrcLowPercentageOnInvestmentAmountLabel ? (
          <div style={{ width: "100%" }}>
            <span>{output.tcTrcLowPercentageOnInvestmentAmountLabel}</span>
            <span style={{ float: "right" }}>
              {`${output.tcTrcLowPercentageOnInvestmentAmountValue}%`}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.totalCostsTrcMediumLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.totalCostsTrcMediumLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(parseFloat(output.totalCostsTrcMediumValue), {
                  decimals: 3,
                })}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.tcTrcMediumPercentageOnInvestmentAmountLabel ? (
          <div style={{ width: "100%" }}>
            <span>{output.tcTrcMediumPercentageOnInvestmentAmountLabel}</span>
            <span style={{ float: "right" }}>
              {`${output.tcTrcMediumPercentageOnInvestmentAmountValue}%`}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.totalCostsTrcHighLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.totalCostsTrcHighLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(parseFloat(output.totalCostsTrcHighValue), {
                  decimals: 3,
                })}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.tcTrcHighPercentageOnInvestmentAmountLabel ? (
          <div style={{ width: "100%" }}>
            <span>{output.tcTrcHighPercentageOnInvestmentAmountLabel}</span>
            <span style={{ float: "right" }}>
              {`${output.tcTrcHighPercentageOnInvestmentAmountValue}%`}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.totalCostsTotalLabel !== null &&
        output.totalCostsTotalValue !== null ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.totalCostsTotalLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(parseFloat(output.totalCostsTotalValue), {
                  decimals: 3,
                })}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.tcTotalPercentageOnInvestmentAmountLabel !== null &&
        output.tcTotalPercentageOnInvestmentAmountValue !== null ? (
          <div style={{ width: "100%" }}>
            <span>{output.tcTotalPercentageOnInvestmentAmountLabel}</span>
            <span style={{ float: "right" }}>
              {`${output.tcTotalPercentageOnInvestmentAmountValue}`}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}
      </Grid>
    </React.Fragment>
  );
};

export default TotalCosts;
