import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import { InputAdornment } from "@mui/material";
import { specialConditionTypes } from "../../../../../../../../app/costants";
import {
  defaultNumberTo,
  formatNumber,
} from "../../../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Totals = (): React.ReactElement => {
  const {
    investmentAmount,
    asfMinFeeWaived,
    asfSpecialCondition,
    asfSpecialConditionAmount,
    exchangeRate,
    bcCurrency,
    valueBand1,
    valueBand2,
    valueBand3,
    valueBand4,
    bespokeFee,
    asfBand1,
    asfBand2,
    asfBand3,
    asfBand4,
    asfMinFee,
    setAsfTotal,
    setAsfSoko,
    advisoryServiceFee,
  } = React.useContext(AppContext) as AppContextTypes;

  const hiddenStyle = !investmentAmount ? { display: "none" } : {};

  let total =
    ((valueBand1 as number) * ((bespokeFee as number) + (asfBand1 as number))) /
    100 +
    ((valueBand2 as number) * ((bespokeFee as number) + (asfBand2 as number))) /
    100 +
    ((valueBand3 as number) * ((bespokeFee as number) + (asfBand3 as number))) /
    100 +
    ((valueBand4 as number) * ((bespokeFee as number) + (asfBand4 as number))) /
    100;

  if (advisoryServiceFee === 0) {
    total = 0;
  }

  let soko = (() => {
    if (asfSpecialConditionAmount !== undefined && !isNaN(asfSpecialConditionAmount)) {
      return asfSpecialCondition === specialConditionTypes[1].value
        ? -((total * asfSpecialConditionAmount) / 100)
        : -(
          total -
          ((investmentAmount as number) *
            defaultNumberTo(exchangeRate, 1) *
            asfSpecialConditionAmount) /
          10000
        );
    }

    return 0;
  })();

  total = (
    !asfMinFeeWaived && (asfMinFee as number) > total + soko ? asfMinFee : total
  ) as number;

  if (total + soko >= total) {
    soko = 0;
  }

  soko = (() => {
    if (asfMinFeeWaived) {
      return soko;
    }

    return total > (asfMinFee as number) ? soko : 0;
  })();

  React.useEffect(() => {
    if (setAsfTotal) {
      setAsfTotal(total);
    }
  }, [total]);

  React.useEffect(() => {
    if (setAsfSoko) {
      setAsfSoko(soko);
    }
  }, [soko, setAsfSoko]);

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="asf-sum"
          name="asf-sum"
          label="ASF Amount Sum"
          variant="filled"
          value={total ? formatNumber(total, { decimals: 2 }) : 0.0}
          style={hiddenStyle}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
        />

        <StyledTextInput
          id="asf-soko"
          name="asf-soko"
          label="ASF Amount SOKO"
          variant="filled"
          value={soko ? formatNumber(soko, { decimals: 2 }) : 0.0}
          style={hiddenStyle}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default Totals;
