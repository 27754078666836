import { useCallback, useEffect, useState } from "react";
import { Alert, Grid, Box } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import TransactionCostConstant from "../../../../models/TransactionCostConstant";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import api from "../../../../api";
import { LoadingButton } from "@mui/lab";
import { Spinner } from "../../../../components/Spinner";
import { FirstRowFields } from "./FirstRowFields";
import { SecondRowFields } from "./SecondRowFields";
import Card from "../../../../components/Card";
import { getAccessToken, parseJwt } from "../../../../utils/jwtUtils";
import { entityEdit } from "../../../../utils/audit";
import { AlertMessage } from "../../../../components/AlertMessage/AlertMessage";
export interface TransactionCostConstantFormInputs {
  code: string;
  text: string;
  val: number;
  itRemark: string;
  bsRemark: string;
}

export interface TransactionCostConstantFormProps {
  id?: string;
}

export const EditTCCForm = (props: TransactionCostConstantFormProps) => {
  const [transactionCostConstant, setTransactionCostConstant] =
    useState<TransactionCostConstant>();
  const [transactionCostConstantError, setTransactionCostConstantError] =
    useState("");

  const [
    transactionCostConstantIsFetching,
    setTransactionCostConstantIsFetching,
  ] = useState(false);

  const { id } = props;

  const form = useForm<TransactionCostConstantFormInputs>({
    defaultValues: {
      code: "",
      text: "",
      val: 0,
      itRemark: "",
      bsRemark: "",
    },
  });

  const { handleSubmit, control, reset, formState } = form;

  useEffect(() => {
    setTransactionCostConstantIsFetching(true);

    api
      .get<TransactionCostConstant>({
        endpoint: `/transaction-cost-constants/${id}`,
      })
      .then((data) => {
        setTransactionCostConstantIsFetching(false);
        setTransactionCostConstant(data.entities[0]);
        reset({
          code: data.entities[0].text ?? "",
          text: data.entities[0].text ?? "",
          val: data.entities[0].val ?? 0,
          itRemark: data.entities[0].itRemark ?? "",
          bsRemark: data.entities[0].bsRemark ?? "",
        });
      })
      .catch((error) => {
        setTransactionCostConstantIsFetching(false);
        setTransactionCostConstantError(error.message);
      });
  }, [id, reset, setTransactionCostConstantIsFetching]);

  const onSubmit: SubmitHandler<TransactionCostConstantFormInputs> =
    useCallback(
      (data) => {
        const { val, itRemark, bsRemark } = data;

        const bodyReq = {
          val,
          bsRemark,
          itRemark,
        };

        api
          .patch({
            endpoint: `/transaction-cost-constants/${id}`,
            data: bodyReq,
          })
          .then(() => {
            const token = parseJwt(getAccessToken() ?? "");
            entityEdit(token.sub, "Transaction Cost Constant", id as string);
          })
          .catch((error) => {
            setTransactionCostConstantError(error.message);
          });
      },
      [id]
    );

  return (
    <Card
      title="Edit Transaction Cost Constant"
      style={{ width: "1512px" }}
      sticky
      actions={
        transactionCostConstant &&
        !transactionCostConstantError && (
          <LoadingButton
            variant="contained"
            endIcon={<SaveAltOutlinedIcon />}
            form="edit-transaction-cost-constant-form"
            type="submit"
            sx={{ textTransform: "none" }}
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Save
          </LoadingButton>
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {transactionCostConstantError && (
            <Alert severity="error">{transactionCostConstantError}</Alert>
          )}
          {transactionCostConstantIsFetching &&
            !transactionCostConstantError && <Spinner height={155} />}
          {transactionCostConstant && !transactionCostConstantError && (
            <form
              id="edit-transaction-cost-constant-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <FirstRowFields
                  control={control}
                  transactionCostConstant={transactionCostConstant}
                />
                <SecondRowFields
                  control={control}
                  transactionCostConstant={transactionCostConstant}
                />
              </Grid>
            </form>
          )}

          <AlertMessage
            show={
              formState.isSubmitted &&
              formState.isSubmitSuccessful &&
              !transactionCostConstantError
            }
            message="Data has been updated correctly"
          />
        </Box>
      )}
    />
  );
};
