import { useCallback, useEffect, useState } from "react";
import { Alert, Grid, Box } from "@mui/material";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { SubmitHandler, useForm } from "react-hook-form";
import FeeClassMatrix from "../../../../models/FeeClassMatrix";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { LoadingButton } from "@mui/lab";
import { FirstRowFields } from "./FirstRowFields";
import api from "../../../../api";
import { SecondRowFields } from "./SecondRowFields";
import { ThirdRowFields } from "./ThirdRowFields";
import { FourthRowFields } from "./FourthRowFields";
import Card from "../../../../components/Card";
import { entityEdit } from "../../../../utils/audit";
import { getAccessToken, parseJwt } from "../../../../utils/jwtUtils";
import { AlertMessage } from "../../../../components/AlertMessage/AlertMessage";

export interface EditFeeClassMatrixFormInputs {
  bookingCenter: string;
  serviceModel: string;
  feeType: string;
  servicePackage: string;
  brokerageModel: string;
  investmentStrategies: string;
  feeClass: string;
  amount: number;
  docSection: string;
  bsRemark: string;
  itRemark: string;
}

export interface EditFeeClassMatrixFormProps {
  id?: string;
}

export const EditFeeClassMatrixForm = (props: EditFeeClassMatrixFormProps) => {
  const [feeClassMatrix, setFeeClassMatrix] = useState<FeeClassMatrix>();
  const [feeClassMatrixError, setFeeClassMatrixError] = useState("");
  const [feeClassMatrixIsFetching, setfeeClassMatrixIsFetching] =
    useState(false);
  const { id } = props;

  const form = useForm<EditFeeClassMatrixFormInputs>({
    defaultValues: {
      bookingCenter: "",
      serviceModel: "",
      feeType: "",
      servicePackage: "",
      brokerageModel: "",
      investmentStrategies: "",
      feeClass: "",
      amount: 0,
      docSection: "",
      bsRemark: "",
      itRemark: "",
    },
  });

  const { reset, formState, handleSubmit, control } = form;

  useEffect(() => {
    setfeeClassMatrixIsFetching(true);

    api
      .get<FeeClassMatrix>({ endpoint: `/fee-class-matrices/${id}` })
      .then((data) => {
        setfeeClassMatrixIsFetching(false);
        setFeeClassMatrix(data.entities[0]);
        reset({
          bookingCenter: data.entities[0].bookingCenter?.text ?? "",
          serviceModel: data.entities[0].serviceModel?.text ?? "",
          feeType: data.entities[0].feeType?.text ?? "",
          servicePackage: data.entities[0].servicePackage?.text ?? "",
          brokerageModel: data.entities[0].brokerageModel?.text ?? "",
          investmentStrategies:
            data.entities[0].investmentStrategy?.text ??
            "investement strategies",
          feeClass: data.entities[0].feeClass?.text ?? "",
          amount: data.entities[0].amount ?? 0,
          docSection: data.entities[0].sectionDoc ?? "",
          bsRemark: data.entities[0].bsRemark ?? "",
          itRemark: data.entities[0].itRemark ?? "",
        });
      })
      .catch((error) => {
        setfeeClassMatrixIsFetching(false);
        setFeeClassMatrixError(error.message);
      });
  }, [id, setfeeClassMatrixIsFetching, reset]);

  const onSubmit: SubmitHandler<EditFeeClassMatrixFormInputs> = useCallback(
    (data) => {
      const { amount, bsRemark, itRemark, docSection } = data;

      const bodyReq = {
        amount,
        bsRemark,
        itRemark,
        sectionDoc: docSection,
      };

      api
        .patch({
          endpoint: `/fee-class-matrices/${id}`,
          data: bodyReq,
        })
        .then(() => {
          const token = parseJwt(getAccessToken() ?? "");
          entityEdit(token.sub, "Fee Class Matrix", id as string);
        })
        .catch((error) => {
          setFeeClassMatrixError(error.message);
        });
    },
    [id]
  );

  return (
    <Card
      title="Edit Fee Class Matrix"
      style={{ width: "1512px" }}
      sticky
      actions={
        feeClassMatrix &&
        !feeClassMatrixError && (
          <LoadingButton
            variant="contained"
            endIcon={<SaveAltOutlinedIcon />}
            form="edit-free-class-matrix-form"
            type="submit"
            sx={{ textTransform: "none" }}
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Save
          </LoadingButton>
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {feeClassMatrixError && (
            <Alert severity="error">{feeClassMatrixError}</Alert>
          )}
          {feeClassMatrixIsFetching && !feeClassMatrixError && (
            <Spinner height={353} />
          )}
          {feeClassMatrix && !feeClassMatrixError && (
            <form
              id="edit-free-class-matrix-form"
              data-testid="edit-free-class-matrix-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <FirstRowFields
                  control={control}
                  feeClassMatrix={feeClassMatrix}
                />
                <SecondRowFields
                  control={control}
                  feeClassMatrix={feeClassMatrix}
                />
                <ThirdRowFields
                  control={control}
                  feeClassMatrix={feeClassMatrix}
                />
                <FourthRowFields
                  control={control}
                  feeClassMatrix={feeClassMatrix}
                />
              </Grid>
            </form>
          )}
          {
            <AlertMessage
              show={
                formState.isSubmitted &&
                formState.isSubmitSuccessful &&
                !feeClassMatrixError
              }
              message="Data has been updated correctly"
            />
          }
        </Box>
      )}
    />
  );
};
