import { takeLatest, call, put } from "redux-saga/effects";
import {
    BOOKING_CENTER_GET_API_REQUEST,
    BOOKING_CENTER_GET_API_SUCCESS,
    BOOKING_CENTER_GET_API_FAILURE,
} from "../reducers/booking-centers-reducer";
import api, { GetResponse } from "../api";
import BookingCenter from "../models/BookingCenter";

function getBookingCenters(): Promise<GetResponse<BookingCenter>> {
    return api.get<BookingCenter>({
        endpoint: '/booking-centers'
    });
}

function* retrieveBookingCenters() {
    try {
        const response: GetResponse<BookingCenter> = yield call(getBookingCenters);
        yield put({ type: BOOKING_CENTER_GET_API_SUCCESS, bookingCenters: response.entities });
    } catch (error) {
        yield put({ type: BOOKING_CENTER_GET_API_FAILURE, error });
    }
}

export default function* bookingCentersSaga() {
    yield takeLatest(BOOKING_CENTER_GET_API_REQUEST, retrieveBookingCenters);
}
