import { Grid } from "@mui/material";
import { FormFieldLabel } from "../../../../hook-form/FormFieldLabel";
import { FormInput } from "../../../../hook-form/FormInput";
import FeeClassMatrix from "../../../../models/FeeClassMatrix";

export interface FourthRowFieldsProps {
  control: any;
  feeClassMatrix: FeeClassMatrix;
}

export const FourthRowFields = (props: FourthRowFieldsProps) => {
  const { control } = props;

  return (
    <>
      <Grid item xs={6}>
        <FormInput
          control={control}
          name="bsRemark"
          label={<FormFieldLabel>BS Remark</FormFieldLabel>}
          fullWidth
          autoComplete="off"
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          control={control}
          name="itRemark"
          label={<FormFieldLabel>IT Remark</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
    </>
  );
};
