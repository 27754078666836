import { takeLatest, call, put } from "redux-saga/effects";
import {
  FEE_TYPE_MATRIX_GET_API_REQUEST,
  FEE_TYPE_MATRIX_GET_API_SUCCESS,
  FEE_TYPE_MATRIX_GET_API_FAILURE,
} from "../reducers/fee-type-matrices-reducer";
import api, { GetResponse } from "../api";
import FeeTypeMatrix from "../models/FeeTypeMatrix";

export interface RetrieveFeeTypeMatricesParams {
  type: string;
  bookingCenterId: number;
  serviceModelId: number;
}

function getFeeTypeMatrices({
  bookingCenterId,
  serviceModelId,
}: RetrieveFeeTypeMatricesParams): Promise<GetResponse<FeeTypeMatrix>> {
  return api.get<FeeTypeMatrix>({
    endpoint:
      "/booking-centers/" +
      bookingCenterId +
      "/service-models/" +
      serviceModelId +
      "/fee-type-matrices",
    data: {
      hasFt: true,
    },
  });
}

function* retrieveFeeTypeMatrices(action: RetrieveFeeTypeMatricesParams) {
  try {
    const response: GetResponse<FeeTypeMatrix> = yield call(
      getFeeTypeMatrices,
      action
    );
    yield put({
      type: FEE_TYPE_MATRIX_GET_API_SUCCESS,
      feeTypeMatrices: response.entities,
    });
  } catch (error) {
    yield put({ type: FEE_TYPE_MATRIX_GET_API_FAILURE, error });
  }
}

export default function* feeTypeMatricesSaga() {
  yield takeLatest(FEE_TYPE_MATRIX_GET_API_REQUEST, retrieveFeeTypeMatrices);
}
