import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";
import type { UseControllerProps } from "react-hook-form";
import type { SelectProps } from "@mui/material";
import type { FieldValues } from "react-hook-form/dist/types/fields";

export type FormSelectOption = {
  label: string;
  value: string;
};

export type FormSelectProps<T extends FieldValues = FieldValues> = Omit<
  UseControllerProps<T>,
  "defaultValue"
> &
  Omit<
    SelectProps,
    | "children"
    | "defaultValue"
    | "id"
    | "labelId"
    | "onChange"
    | "value"
    | "onBlur"
  > & {
    options: FormSelectOption[];
  };

export const FormSelect = <T extends FieldValues = FieldValues>(
  props: FormSelectProps<T>
) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    options,
    label,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
              name={name}
              id={`${name}-select`}
              labelId={`${name}-select-label`}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={!!error}
              label={label}
              {...restProps}
            >
              {options.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{error?.message ?? null}</FormHelperText>
          </FormControl>
        </Box>
      )}
    />
  );
};
