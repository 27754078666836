import React, { useEffect } from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import { InputAdornment } from "@mui/material";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Bands = (): React.ReactElement => {
  const {
    investmentAmount,
    feeClassMatrices,
    setCSFBand1,
    setCSFBand2,
    setCSFBand3,
    setCSFBand4,
    custodyServiceFee,
  } = React.useContext(AppContext) as AppContextTypes;

  const band1 = feeClassMatrices?.find(
    (fcm) =>
      fcm.feeType.text.toLowerCase() === "Custody Service Fee".toLowerCase() &&
      fcm.feeClass.text.toLowerCase() === "Band_1".toLowerCase()
  )?.amount as number;

  const band2 = feeClassMatrices?.find(
    (fcm) =>
      fcm.feeType.text.toLowerCase() === "Custody Service Fee".toLowerCase() &&
      fcm.feeClass.text.toLowerCase() === "Band_2".toLowerCase()
  )?.amount as number;

  const band3 = feeClassMatrices?.find(
    (fcm) =>
      fcm.feeType.text.toLowerCase() === "Custody Service Fee".toLowerCase() &&
      fcm.feeClass.text.toLowerCase() === "Band_3".toLowerCase()
  )?.amount as number;

  const band4 = feeClassMatrices?.find(
    (fcm) =>
      fcm.feeType.text.toLowerCase() === "Custody Service Fee".toLowerCase() &&
      fcm.feeClass.text.toLowerCase() === "Band_4".toLowerCase()
  )?.amount as number;

  useEffect(() => {
    if (custodyServiceFee === 0) return;
    if (setCSFBand1) {
      setCSFBand1(band1);
    }
    if (setCSFBand2) {
      setCSFBand2(band2);
    }
    if (setCSFBand3) {
      setCSFBand3(band3);
    }
    if (setCSFBand4) {
      setCSFBand4(band4);
    }
  }, [band1, band2, band3, band4, custodyServiceFee]);

  if (!investmentAmount) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="csf-band1"
          name="csf-band1"
          label="CSF Band 1"
          variant="filled"
          value={Number(band1).toFixed(2) ?? 0.0}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="csf-band2"
          name="csf-band2"
          label="CSF Band 2"
          variant="filled"
          value={Number(band2).toFixed(2) ?? 0.0}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="csf-band3"
          name="csf-band3"
          label="CSF Band 3"
          variant="filled"
          value={Number(band3).toFixed(2) ?? 0.0}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="csf-band4"
          name="csf-band4"
          label="CSF Band 4"
          variant="filled"
          value={Number(band4).toFixed(2) ?? 0.0}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default Bands;
