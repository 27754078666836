import { RequireAtLeastOne } from "../types/utility";

export enum Separator {
  DOT = ".",
  COMMA = ",",
  APOSTROPHE = "'",
}

interface Options {
  decimals?: number;
  thousandsSeparator?: Separator;
}

export const formatNumber = (
  n: number,
  options?: RequireAtLeastOne<Options>
) => {
  const { decimals, thousandsSeparator = Separator.APOSTROPHE } = options ?? {};

  if (isNaN(n)) return "NaN";

  const thousandsRegex = /\B(?=(\d{3})+(?!\d))/g;
  const parts = n.toString().split(".");

  const truncatedDecimalPart =
    parts[1] && decimals && decimals > 0
      ? `.${parts[1].substring(0, decimals)}`
      : null;

  const decimalPart = parts[1] && `.${parts[1]}`

  const formattedIntegerPart = parts[0].replace(
    thousandsRegex,
    thousandsSeparator
  );

  return decimals === 0
    ? formattedIntegerPart
    : `${formattedIntegerPart}${truncatedDecimalPart ?? decimalPart ?? ''}`;
};

export const defaultNumberTo = (n: number | undefined, to: number): number => {
  return isNaN(n ?? to) ? to : n ?? to;
}