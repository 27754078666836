import { useCallback, useEffect, useState } from "react";
import { Alert, Grid, Box } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { LoadingButton } from "@mui/lab";
import ValueBand from "../../../../models/ValueBand";
import { Spinner } from "../../../../components/Spinner";
import { FirstRowFields } from "./FirstRowFields";
import { SecondRowFields } from "./SecondRowFields";
import { ThirdRowFields } from "./ThirdRowFields";
import Card from "../../../../components/Card";
import api from "../../../../api";
import { entityEdit } from "../../../../utils/audit";
import { getAccessToken, parseJwt } from "../../../../utils/jwtUtils";
import { AlertMessage } from "../../../../components/AlertMessage/AlertMessage";

export interface EditValueBandFormInputs {
  code: string;
  text: string;
  valMin: number;
  valMax: number;
  bsRemark: string;
  itRemark: string;
}

export interface EditValueBandFormProps {
  id?: string;
}

export const EditValueBandForm = (props: EditValueBandFormProps) => {
  const [valueBand, setValueBand] = useState<ValueBand>();
  const [valueBandError, setValueBandError] = useState("");
  const [valueBandIsFetching, setValueBandIsFetching] = useState(false);

  const { id } = props;

  const form = useForm<EditValueBandFormInputs>({
    defaultValues: {
      code: "",
      text: "",
      valMin: 0,
      valMax: 0,
      bsRemark: "",
      itRemark: "",
    },
  });

  const { handleSubmit, control, getValues, reset, formState } = form;

  useEffect(() => {
    setValueBandIsFetching(true);

    api
      .get<ValueBand>({ endpoint: `/value-bands/${id}` })
      .then((data) => {
        setValueBandIsFetching(true);
        setValueBand(data.entities[0]);
        reset({
          code: data.entities[0].code ?? "",
          text: data.entities[0].text ?? "",
          valMin: data.entities[0].valMin ?? 0,
          valMax: data.entities[0].valMax ?? 0,
          bsRemark: data.entities[0].bsRemark ?? "",
          itRemark: data.entities[0].itRemark ?? "",
        });
        setValueBandIsFetching(false);
      })
      .catch((error) => {
        setValueBandIsFetching(false);
        setValueBandError(error.message);
      });
  }, [id, reset]);

  const onSubmit: SubmitHandler<EditValueBandFormInputs> = useCallback(
    (data) => {
      const { valMin, valMax, bsRemark, itRemark } = data;

      const bodyReq = {
        valMin,
        valMax,
        bsRemark,
        itRemark,
      };

      api
        .patch({
          endpoint: `/value-bands/${id}`,
          data: bodyReq,
        })
        .then(() => {
          const token = parseJwt(getAccessToken() ?? "");
          entityEdit(token.sub, "Value Band", id as string);
        })
        .catch((error) => {
          setValueBandError(error.message);
        });
    },
    [id]
  );

  return (
    <Card
      title="Edit Value Band"
      style={{ width: "1512px" }}
      sticky
      actions={
        valueBand &&
        !valueBandError && (
          <LoadingButton
            variant="contained"
            endIcon={<SaveAltOutlinedIcon />}
            form="edit-value-band-form"
            type="submit"
            sx={{ textTransform: "none" }}
          >
            Save
          </LoadingButton>
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {valueBandError && <Alert severity="error">{valueBandError}</Alert>}
          {valueBandIsFetching && !valueBandError && <Spinner height={251} />}
          {valueBand && !valueBandError && (
            <form id="edit-value-band-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container columnSpacing={2} rowSpacing={5}>
                <FirstRowFields control={control} valueBand={valueBand} />
                <SecondRowFields control={control} getValues={getValues} />
                <ThirdRowFields control={control} valueBand={valueBand} />
              </Grid>
            </form>
          )}
          <AlertMessage
            show={
              formState.isSubmitted &&
              formState.isSubmitSuccessful &&
              !valueBandError
            }
            message="Data has been updated correctly"
          />
        </Box>
      )}
    />
  );
};
