import { FormState } from "../../../../store";
import InvestmentStrategy from "../../../../models/InvestmentStrategy";

export default interface FormDataProps {
  investmentStrategiesFullList: InvestmentStrategy[];
}

export const mapStateToProps = (state: FormState): FormDataProps => {
  return {
    investmentStrategiesFullList: state.investmentStrategiesReducer.fullList
  };
};
