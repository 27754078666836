import React from "react";
import Fees from "./components/Fees";
import Bands from "./components/Bands";
import NumberOfTx from "./components/NumberOfTx";
import MAS from "./components/MAS";
import RateBands from "./components/RateBands";
import Conclusions from "./components/Conclusions";

const TransactionRelatedCostsOutput = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Fees />
            <Bands />
            <NumberOfTx />
            <MAS />
            <RateBands />
            <Conclusions />
        </React.Fragment>
    )
}

export default TransactionRelatedCostsOutput;