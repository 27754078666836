import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../index.style";
import Content from "../../../../../../components/Content";
import Header from "../../../../../../components/Header";
import AppContext, { AppContextTypes } from "app/index.ctx";

const BasicFields = (): React.ReactElement => {
  const { clientName, setClientName } = React.useContext(
    AppContext
  ) as AppContextTypes;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setClientName) {
      setClientName(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <Content>
        <Header title="Basic fields" />
      </Content>
      <StyledInputWrapper>
        <StyledTextInput
          id="client-name"
          name="client-name"
          value={clientName}
          label="Client Name"
          variant="outlined"
          onChange={onChange}
          required
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default BasicFields;
