import React from "react";
import { GridSpacing } from "@mui/material/Grid";
import { Box, Grid } from "@mui/material";

export interface SectionProps {
  children?: React.ReactNode;
  spacing?: GridSpacing;
  className?: string;
}

const Section = (props: SectionProps): React.ReactElement => {
  const { children, spacing, className } = props;

  return (
    <React.Fragment>
      <Box sx={{ margin: "50px 0 0 40px", width: "100%" }}>
        <Grid
          container
          spacing={spacing ?? 5}
          role="grid"
          className={className}
          sx={{ width: "100%" }}
        >
          {children}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Section;
