import React from "react";
import Fees from "./components/Fees";
import Bands from "./components/Bands";
import Totals from "./components/Totals";

const AdvisoryServiceFeeOutput = (): React.ReactElement => {
  return (
    <React.Fragment>
      <Fees />
      <Bands />
      <Totals />
    </React.Fragment>
  );
};

export default AdvisoryServiceFeeOutput;
