import api from "../api";

export const outputCalculation = (user_id: string): Promise<boolean> => {
    return new Promise<boolean>(((resolve, reject) => {
        api.post({
            endpoint: '/user-action',
            data: {
                "user": user_id,
                "cdOperation": "output_calculation",
                "operation": "pcs_oc",
                "description": "The user performed a calculation"
            }
        }).then(() => {
            resolve(true);
        }).catch((error) => {
            reject(error);
        })
    }));
}

export const entityEdit = (user_id: string, entity: string, id: string): Promise<boolean> => {
    return new Promise<boolean>(((resolve, reject) => {
        api.post({
            endpoint: '/user-action',
            data: {
                "user": user_id,
                "cdOperation": "entity_" + entity.toLowerCase().replace(/\s/g, "_")  + "_edit",
                "operation": "pcs_ee",
                "description": "The user edited the " + entity + " entity with id " + id
            }
        }).then(() => {
            resolve(true);
        }).catch((error) => {
            reject(error);
        })
    }));
}