import React from "react";
import { StyledButton, StyledSection } from "./index.style";
import Card from "../../components/Card";
import { Box, Grid } from "@mui/material";

const Index = (): React.ReactElement => {
  const openSimulation = () => {
    window.location.href = "/simulation";
  };

  const openFeeClassMatrices = () => {
    window.location.href = "/admin/fee-class-matrices";
  };

  const openValueBands = () => {
    window.location.href = "/admin/value-bands";
  };

  const openTransactionCostConstants = () => {
    window.location.href = "/admin/transaction-cost-constants";
  };

  return (
    <React.Fragment>
      <Card
        title={"Admin Panel"}
        Component={StyledSection}
        style={{ width: "100%", minHeight: "646px" }}
        sticky
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            container
            sx={{ pl: "45px", display: "flex", flexDirection: "column" }}
          >
            <p>Please choose what to do:</p>

            <StyledButton variant={"contained"} onClick={openSimulation}>
              Start Simulation
            </StyledButton>

            <Box sx={{ height: "25px" }} />

            <StyledButton
              variant={"outlined"}
              color="secondary"
              onClick={openFeeClassMatrices}
            >
              Edit Fee Class Matrices
            </StyledButton>

            <Box sx={{ height: "25px" }} />

            <StyledButton
              variant={"outlined"}
              color="secondary"
              onClick={openValueBands}
            >
              Edit Value Bands
            </StyledButton>
            <Box sx={{ height: "25px" }} />
            <StyledButton
              variant={"outlined"}
              color="secondary"
              onClick={openTransactionCostConstants}
            >
              Edit Transaction Cost Constants
            </StyledButton>
          </Grid>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default Index;
