import React from "react";
import { Grid } from "@mui/material";
import { formatNumber } from "../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const IndicativeOverview = (): React.ReactElement => {
  const { output } = React.useContext(AppContext) as AppContextTypes;

  if (output === undefined) return <React.Fragment />;

  return (
    <React.Fragment>
      <Grid item marginTop={5} xs={6}>
        <div>{output.clientName}</div>
        <div>{output.currency}</div>
        <div>{output.mandate}</div>
        <div>{output.bankingServicePackage}</div>
      </Grid>
      <Grid item marginTop={5} xs={6}>
        <div>
          <br />
        </div>
        <div>{output.period}</div>
        <div>{output.investmentStrategy}</div>
        <div>{output.brokerageModel}</div>
      </Grid>
      <Grid item xs={12}>
        <div style={{ width: "100%" }}>
          <b>
            <span>{output.intendedInvestmentAmountLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(parseFloat(output.intendedInvestmentAmountValue), {
                decimals: 3,
              })}
            </span>
          </b>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default IndicativeOverview;
