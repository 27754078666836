// action types
import Language from "../models/Language";

export const LANGUAGE_GET_API_REQUEST = "LANGUAGE_GET_API_REQUEST";
export const LANGUAGE_GET_API_SUCCESS = "LANGUAGE_GET_API_SUCCESS";
export const LANGUAGE_GET_API_FAILURE = "LANGUAGE_GET_API_FAILURE";

export interface LanguageState {
  languages: Language[];
}

// reducer with initial state
const initialState: LanguageState = {
  languages: [],
};

interface Action {
  type: string;
  languages: Language[];
}

export default function languagesReducer(
  state: LanguageState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case LANGUAGE_GET_API_SUCCESS:
      newState.languages = action.languages;
      break;
    case LANGUAGE_GET_API_FAILURE:
      break;
  }

  return newState;
}
