// action types
import InvestmentStrategy from "../models/InvestmentStrategy";

export const INVESTMENT_STRATEGY_GET_API_REQUEST =
  "INVESTMENT_STRATEGY_GET_API_REQUEST";
export const INVESTMENT_STRATEGY_GET_API_SUCCESS =
  "INVESTMENT_STRATEGY_GET_API_SUCCESS";
export const INVESTMENT_STRATEGY_GET_API_FAILURE =
  "INVESTMENT_STRATEGY_GET_API_FAILURE";
export const INVESTMENT_STRATEGY_SELECTED = "INVESTMENT_STRATEGY_SELECTED";
export const INVESTMENT_STRATEGY_FULL_LIST_GET_API_REQUEST =
  "INVESTMENT_STRATEGY_FULL_LIST_GET_API_REQUEST";
export const INVESTMENT_STRATEGY_FULL_LIST_GET_API_SUCCESS =
  "INVESTMENT_STRATEGY_FULL_LIST_GET_API_SUCCESS";
export const INVESTMENT_STRATEGY_FULL_LIST_GET_API_FAILURE =
  "INVESTMENT_STRATEGY_FULL_LIST_GET_API_FAILURE";
export const INVESTMENT_STRATEGY_CLEAR = "INVESTMENT_STRATEGY_CLEAR";

export interface InvestmentStrategyState {
  fullList: InvestmentStrategy[];
  investmentStrategies: InvestmentStrategy[];
  selected?: number;
}

// reducer with initial state
const initialState: InvestmentStrategyState = {
  fullList: [],
  investmentStrategies: [],
  selected: undefined,
};

interface Action {
  type: string;
  fullList: InvestmentStrategy[];
  investmentStrategies: InvestmentStrategy[];
  selected?: number;
}

export default function investmentStrategiesReducer(
  state: InvestmentStrategyState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case INVESTMENT_STRATEGY_GET_API_SUCCESS:
      newState.investmentStrategies = action.investmentStrategies;
      break;
    case INVESTMENT_STRATEGY_GET_API_FAILURE:
      break;
    case INVESTMENT_STRATEGY_SELECTED:
      newState.selected = action.selected;
      break;
    case INVESTMENT_STRATEGY_FULL_LIST_GET_API_SUCCESS:
      newState.fullList = action.fullList;
      break;
    case INVESTMENT_STRATEGY_FULL_LIST_GET_API_FAILURE:
      break;
    case INVESTMENT_STRATEGY_CLEAR:
      newState = initialState;
      break;
  }

  return newState;
}
