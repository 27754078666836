import { all } from "redux-saga/effects";
import languagesSaga from "./languages-saga";
import bookingCentersSaga from "./booking-centers-saga";
import rmLocationsSaga from "./rm-locations-saga";
import serviceModelsSaga from "./service-models-saga";
import servicePackagesSaga from "./service-packages-saga";
import brokerageModelsSaga from "./brokerage-models-saga";
import investmentStrategiesSaga from "./investment-strategies-saga";
import valueBandsSaga from "./value-bands-saga";
import feeClassMatricesSaga from "./fee-class-matrices-saga";
import feeTypeMatricesSaga from "./fee-type-matrices-saga";
import transactionCostConstantsSaga from "./transaction-cost-constants-saga";
import outputSaga from "./output-saga";
import currenciesSaga from "./currencies-saga";

export default function* rootSaga() {
  yield all([
    languagesSaga(),
    currenciesSaga(),
    bookingCentersSaga(),
    rmLocationsSaga(),
    serviceModelsSaga(),
    servicePackagesSaga(),
    brokerageModelsSaga(),
    brokerageModelsSaga(),
    investmentStrategiesSaga(),
    valueBandsSaga(),
    feeClassMatricesSaga(),
    feeTypeMatricesSaga(),
    transactionCostConstantsSaga(),
    outputSaga(),
  ]);
}
