import React from "react";
import BasicFields from "./components/BasicFields";
import OutputFields from "./components/OutputFields";
import ServiceFields from "./components/ServiceFields";
import InvestmentFields from "./components/InvestmentFields";
import BankServiceFeeFields from "./components/BankServiceFeeFields";
import AdvisoryServiceFeeFields from "./components/AdvisoryServiceFeeFields";
import CustodyServiceFeeFields from "./components/CustodyServiceFeeFields";
import TransactionRelatedCostsFields from "./components/TransactionRelatedCostsFields";
import store from "../../../../store";
import { VALUE_BAND_GET_API_REQUEST } from "../../../../reducers/value-bands-reducer";
import { FEE_CLASS_MATRIX_GET_API_REQUEST } from "../../../../reducers/fee-class-matrices-reducer";
import {
  FEE_TYPE_MATRIX_GET_API_REQUEST,
  FEE_TYPE_MATRIX_RESET,
} from "../../../../reducers/fee-type-matrices-reducer";
import { TRANSACTION_COST_CONSTANT_GET_API_REQUEST } from "../../../../reducers/transaction-cost-constants-reducer";
import { StyledSection } from "./index.style";
import Remarks from "./Remarks";
import ProductRelatedCostFields from "./components/ProductRelatedCostFields/ProductRelatedCostFields";
import FormDataProps, { mapStateToProps } from "./index.props";
import { connect } from "react-redux";
import AppContext, { AppContextTypes } from "app/index.ctx";
import { Box } from "@mui/material";

const Form = ({
  investmentStrategiesFullList,
}: FormDataProps): React.ReactElement => {
  const [valueBandsRequested, setValueBandsRequested] = React.useState(false);
  const [
    transactionCostConstantsRequested,
    setTransactionCostConstantsRequested,
  ] = React.useState(false);
  const {
    setValueBands,
    servicePackage,
    feeClassMatrices,
    setFeeClassMatrices,
    feeTypeMatrices,
    setFeeTypeMatrices,
    setTransactionCostConstants,
    bookingCenter,
    serviceModel,
    brokerageModel,
    investmentStrategy,
    rmLocation,
    investmentStrategyRequired,
  } = React.useContext(AppContext) as AppContextTypes;

  function onValueBandsRequested(state: any) {
    if (state.valueBandsReducer.valueBands) {
      if (setValueBands) {
        setValueBands(state.valueBandsReducer.valueBands);
      }
      setValueBandsRequested(true);
    }
  }

  function onFeeClassRequested(state: any) {
    if (feeClassMatrices !== state.feeClassMatricesReducer.feeClassMatrices) {
      if (setFeeClassMatrices) {
        setFeeClassMatrices(state.feeClassMatricesReducer.feeClassMatrices);
      }
    }
  }

  function onFeeTypeMatrixRequested(state: any) {
    if (feeTypeMatrices !== state.feeTypeMatricesReducer.feeTypeMatrices) {
      if (setFeeTypeMatrices) {
        setFeeTypeMatrices(state.feeTypeMatricesReducer.feeTypeMatrices);
      }
    }
  }

  function onTransactionCostConstantsRequested(state: any) {
    if (state.transactionCostConstantsReducer.transactionCostConstants) {
      if (setTransactionCostConstants) {
        setTransactionCostConstants(
          state.transactionCostConstantsReducer.transactionCostConstants
        );
      }
      setTransactionCostConstantsRequested(true);
    }
  }

  const callFeeClassMatrix = React.useCallback(() => {
    if (investmentStrategy || !investmentStrategyRequired) {
      let _investmentStrategy: number | undefined = investmentStrategy?.id;

      if (rmLocation?.code === "UK" && serviceModel && serviceModel.isDM) {
        const growth = investmentStrategiesFullList.find(
          (i) => i.text.toLowerCase() === "growth"
        );
        const balanced = investmentStrategiesFullList.find(
          (i) => i.text.toLowerCase() === "balanced"
        );

        _investmentStrategy =
          serviceModel.text === "MACM Dynamic Asset Allocation"
            ? growth?.id // Growth
            : balanced?.id; // Balanced
      }

      store.dispatch({
        type: FEE_CLASS_MATRIX_GET_API_REQUEST,
        servicePackageId: servicePackage?.id,
        brokerageModelId: brokerageModel?.id,
        investmentStrategyId: _investmentStrategy,
        bookingCenterId: bookingCenter?.id,
        serviceModelId: serviceModel?.id,
      });
    }
  }, [
    brokerageModel,
    investmentStrategy,
    investmentStrategyRequired,
    serviceModel,
    rmLocation,
  ]);

  React.useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();

      onValueBandsRequested(state);
      onFeeClassRequested(state);
      onFeeTypeMatrixRequested(state);
      onTransactionCostConstantsRequested(state);
    });

    if (!valueBandsRequested) {
      store.dispatch({ type: VALUE_BAND_GET_API_REQUEST });
    }

    if (bookingCenter && serviceModel) {
      store.dispatch({
        type: FEE_TYPE_MATRIX_GET_API_REQUEST,
        bookingCenterId: bookingCenter?.id,
        serviceModelId: serviceModel?.id,
      });

      callFeeClassMatrix();
    } else {
      store.dispatch({
        type: FEE_TYPE_MATRIX_RESET,
      });
    }

    if (!transactionCostConstantsRequested) {
      store.dispatch({ type: TRANSACTION_COST_CONSTANT_GET_API_REQUEST });
    }

    return () => {
      unsubscribe();
    };
  }, [
    servicePackage,
    brokerageModel,
    investmentStrategy,
    bookingCenter,
    serviceModel,
  ]);

  return (
    <Box sx={{ ml: "-40px" }}>
      <StyledSection>
        <BasicFields />
        <ServiceFields />
        <OutputFields />
        <InvestmentFields />
        <BankServiceFeeFields />
        <AdvisoryServiceFeeFields />
        <CustodyServiceFeeFields />
        <TransactionRelatedCostsFields />
        <ProductRelatedCostFields />
        <Remarks />
      </StyledSection>
    </Box>
  );
};

export default connect(mapStateToProps)(Form);
