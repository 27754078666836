// action types
import ServicePackage from "../models/ServicePackage";

export const SERVICE_PACKAGE_GET_API_REQUEST =
  "SERVICE_PACKAGE_GET_API_REQUEST";
export const SERVICE_PACKAGE_GET_API_SUCCESS =
  "SERVICE_PACKAGE_GET_API_SUCCESS";
export const SERVICE_PACKAGE_GET_API_FAILURE =
  "SERVICE_PACKAGE_GET_API_FAILURE";
export const SERVICE_PACKAGE_SELECTED = "SERVICE_PACKAGE_SELECTED";
export const SERVICE_PACKAGE_CLEAR = "SERVICE_PACKAGE_CLEAR";

export interface ServicePackageState {
  servicePackages: ServicePackage[];
  selected?: number;
}

// reducer with initial state
const initialState: ServicePackageState = {
  servicePackages: [],
  selected: undefined,
};

interface Action {
  type: string;
  servicePackages: ServicePackage[];
  selected?: number;
}

export default function servicePackagesReducer(
  state: ServicePackageState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case SERVICE_PACKAGE_GET_API_SUCCESS:
      newState.servicePackages = action.servicePackages;
      break;
    case SERVICE_PACKAGE_GET_API_FAILURE:
      break;
    case SERVICE_PACKAGE_SELECTED:
      newState.selected = action.selected;
      break;
    case SERVICE_PACKAGE_CLEAR:
      newState = initialState;
      break;
  }

  return newState;
}
