import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/root-reducer";
import rootSaga from "./sagas/root-saga";
import { LanguageState } from "./reducers/languages-reducer";
import { BookingCenterState } from "./reducers/booking-centers-reducer";
import { RMLocationState } from "./reducers/rm-locations-reducer";
import { ServiceModelState } from "./reducers/service-models-reducer";
import { ServicePackageState } from "./reducers/service-packages-reducer";
import { BrokerageModelState } from "./reducers/brokerage-models-reducer";
import { InvestmentStrategyState } from "./reducers/investment-strategies-reducer";
import { ValueBandState } from "./reducers/value-bands-reducer";
import { FeeClassMatrixState } from "./reducers/fee-class-matrices-reducer";
import { FeeTypeMatrixState } from "./reducers/fee-type-matrices-reducer";
import { TransactionCostConstantState } from "./reducers/transaction-cost-constants-reducer";
import { OutputState } from "./reducers/output-reducer";
import { CurrencyState } from "./reducers/currencies-reducer";

const sagaMiddleware = createSagaMiddleware();

export interface FormState {
  languagesReducer: LanguageState;
  currenciesReducer: CurrencyState;
  bookingCentersReducer: BookingCenterState;
  rmLocationsReducer: RMLocationState;
  serviceModelsReducer: ServiceModelState;
  servicePackagesReducer: ServicePackageState;
  brokerageModelsReducer: BrokerageModelState;
  investmentStrategiesReducer: InvestmentStrategyState;
  valueBandsReducer: ValueBandState;
  feeClassMatrices: FeeClassMatrixState;
  feeTypeMatrices: FeeTypeMatrixState;
  transactionCostConstants: TransactionCostConstantState;
  outputReducer: OutputState;
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
