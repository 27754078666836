import { Grid } from "@mui/material";
import { FormFieldLabel } from "../../../../hook-form/FormFieldLabel";
import { FormSelect } from "../../../../hook-form/FormSelect";
import TransactionCostConstant from "../../../../models/TransactionCostConstant";
import { FormCurrencyInput } from "hook-form/FormCurrencyInput";

export interface FirstRowFieldsProps {
  control: any;
  transactionCostConstant: TransactionCostConstant;
}

export const FirstRowFields = (props: FirstRowFieldsProps) => {
  const { transactionCostConstant, control } = props;

  return (
    <>
      <Grid item xs={4}>
        <FormSelect
          name="code"
          label={<FormFieldLabel isRequired>Code</FormFieldLabel>}
          control={control}
          fullWidth
          options={[
            {
              label: transactionCostConstant?.code ?? "",
              value: transactionCostConstant?.text ?? "",
            },
          ]}
          disabled
          rules={{ required: "Code is required." }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormSelect
          name="text"
          label={<FormFieldLabel isRequired>Text</FormFieldLabel>}
          control={control}
          fullWidth
          options={[
            {
              label: transactionCostConstant?.text ?? "",
              value: transactionCostConstant?.text ?? "",
            },
          ]}
          disabled
          rules={{ required: "Text is required." }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormCurrencyInput
          name="val"
          label={<FormFieldLabel isRequired>Value</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          rules={{
            required: "Value is required.",
          }}
        />
      </Grid>
    </>
  );
};
