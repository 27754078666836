import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import { InputAdornment } from "@mui/material";
import { formatNumber } from "../../../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Fees = (): React.ReactElement => {
  const {
    investmentAmount,
    bcCurrency,
    feeTypeMatrices,
    feeClassMatrices,
    setCustodyServiceFee,
    setCSFMinFee,
  } = React.useContext(AppContext) as AppContextTypes;

  const custodyServiceFee = Math.ceil(
    feeTypeMatrices?.filter(
      (ftm) =>
        ftm.feeType.text.toLowerCase() === "Custody Service Fee".toLowerCase()
    ).length as number
  );

  const custodyServiceFeePriceMin =
    (feeClassMatrices?.find(
      (fcm) =>
        fcm.feeType.text.toLowerCase() ===
          "Custody Service Fee".toLowerCase() &&
        fcm.feeClass.text.toLowerCase() === "min. Fee".toLowerCase()
    )?.amount as number) * 4;

  React.useEffect(() => {
    if (setCSFMinFee) {
      setCSFMinFee(custodyServiceFeePriceMin);
    }
  }, [custodyServiceFeePriceMin]);

  React.useEffect(() => {
    if (setCustodyServiceFee) {
      setCustodyServiceFee(custodyServiceFee);
    }
  }, [custodyServiceFee]);

  if (!investmentAmount) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="custody-service-fee"
          name="custody-service-fee"
          label="Custody service fee"
          variant="filled"
          value={formatNumber(custodyServiceFee, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="custody-service-min-fee-min"
          name="custody-service-fee-min"
          label={"CSF Rate min fee"}
          variant="filled"
          value={formatNumber(custodyServiceFeePriceMin, { decimals: 2 })}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default Fees;
