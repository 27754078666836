import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { EditPageLayout } from "../../layouts/EditPageLayout";
import { EditTCCForm } from "./components/EditTCCForm";

export const EditTCC = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, [id, navigate]);

  return (
    <EditPageLayout>
      <EditTCCForm id={id} />
    </EditPageLayout>
  );
};
