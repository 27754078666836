import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import { InputAdornment } from "@mui/material";
import { formatNumber } from "../../../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Fees = (): React.ReactElement => {
  const {
    investmentAmount,
    bcCurrency,
    feeTypeMatrices,
    feeClassMatrices,
    bespoke,
    transactionCostConstants,
    setAdvisoryServiceFee,
    setBespokeFee,
    setASFMinFee,
  } = React.useContext(AppContext) as AppContextTypes;

  const advisoryServiceFee = Math.ceil(
    feeTypeMatrices?.filter(
      (ftm) =>
        ftm.feeType.text.toLowerCase() === "Advisory Service Fee".toLowerCase()
    ).length as number
  );

  const advisoryServiceFeePriceMin =
    (feeClassMatrices?.find(
      (fcm) =>
        fcm.feeType.text.toLowerCase() ===
          "Advisory Service Fee".toLowerCase() &&
        fcm.feeClass.text.toLowerCase() ===
          ("min. Fee" + (bespoke ? " bespoken" : "")).toLowerCase()
    )?.amount as number) * 4;

  const rateAddByBespoke = (
    bespoke
      ? transactionCostConstants?.find((tcc) => tcc.code === "OPk_11")?.val
      : 0
  ) as number;
  const advisoryServiceFeeHiddenStyle = !investmentAmount
    ? { display: "none" }
    : {};

  React.useEffect(() => {
    if (setBespokeFee) {
      setBespokeFee(rateAddByBespoke);
    }
    if (setASFMinFee) {
      setASFMinFee(advisoryServiceFeePriceMin);
    }
  }, [rateAddByBespoke, advisoryServiceFeePriceMin]);

  React.useEffect(() => {
    if (setAdvisoryServiceFee) {
      setAdvisoryServiceFee(advisoryServiceFee);
    }
  }, [advisoryServiceFee]);

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="advisory-service-fee"
          name="advisory-service-fee"
          label="Advisory service fee"
          variant="filled"
          value={formatNumber(advisoryServiceFee, {
            decimals: 2,
          })}
          style={advisoryServiceFeeHiddenStyle}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="advisory-service-min-fee-min"
          name="advisory-service-fee-min"
          label={"ASF Rate min fee"}
          variant="filled"
          value={
            isNaN(advisoryServiceFeePriceMin)
              ? 0.0
              : formatNumber(advisoryServiceFeePriceMin, {
                  decimals: 2,
                })
          }
          style={advisoryServiceFeeHiddenStyle}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
        />

        <StyledTextInput
          id="advisory-rate-add-by-bespoke"
          name="advisory-rate-add-by-bespoke"
          label={"Advisory rate add by bespoke"}
          variant="filled"
          value={formatNumber(parseFloat(Number(rateAddByBespoke).toFixed(2)), {
            decimals: 2,
          })}
          style={advisoryServiceFeeHiddenStyle}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default Fees;
