/* istanbul ignore file */
import { INVESTMENT_STRATEGY_FULL_LIST_GET_API_REQUEST } from "../../reducers/investment-strategies-reducer";

export default interface InputDataProps {
  onRequestGetInvestmentStrategiesFullList?: any;
}

export const mapDispatchToProps = (
  dispatch: (arg0: { type: string }) => any
) => ({
  onRequestGetInvestmentStrategiesFullList: () =>
    dispatch({ type: INVESTMENT_STRATEGY_FULL_LIST_GET_API_REQUEST }),
});
