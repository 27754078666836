import React from "react";
import {
  StyledFormControlLabel,
  StyledInputWrapper,
  StyledTextInput,
} from "../../../../index.style";
import { Checkbox, InputAdornment } from "@mui/material";
import ServiceModelToInvestmentStrategy from "../../../../../../../../models/ServiceModelToInvestmentStrategy";
import api from "../../../../../../../../api";
import FeeClassMatrix from "../../../../../../../../models/FeeClassMatrix";
import { formatNumber } from "../../../../../../../../utils/numbers";
import axios, { CancelTokenSource } from "axios";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Conclusions = (): React.ReactElement => {
  const [prcFeeClassMatrices, setPrcFeeClassMatrices] = React.useState<
    FeeClassMatrix[]
  >([]);
  const {
    bookingCenter,
    servicePackage,
    brokerageModel,
    transactionCostConstants,
    bcCurrency,
    investmentAmount,
    feeClassMatrices,
    feeTypeMatrices,
    rmLocation,
    investmentStrategy,
    noTxLow,
    noTxMedium,
    noTxHigh,
    trcRateBand1Low,
    trcRateBand2Low,
    trcRateBand3Low,
    trcRateBand4Low,
    trcRateBand5Low,
    trcValueBand1,
    trcValueBand2,
    trcValueBand3,
    trcValueBand4,
    trcValueBand5,
    trcMinFee,
    setTrcBpsIsDM,
    setTrcAmountLow,
    setTrcAmountMedium,
    setTrcAmountHigh,
    transactionRelatedCosts,
    setProductRelatedCosts,
    serviceModel,
    setPrcModelPortfolioStrategy,
    refModelPortfolioStrategyForPrc,
    setRefModelPortfolioStrategyForPrc,
  } = React.useContext(AppContext) as AppContextTypes;

  const cancelTokens: CancelTokenSource[] = [];

  const isTicketFee =
    brokerageModel?.text.toLowerCase() === "Ticket Fee".toLowerCase();

  const opk10 = transactionCostConstants?.find(
    (tcc) => tcc.code.toLowerCase() === "OPk_10".toLowerCase()
  )?.val as number;

  const bandsSum =
    ((trcRateBand1Low as number) * (trcValueBand1 as number)) / 100 +
    ((trcRateBand2Low as number) * (trcValueBand2 as number)) / 100 +
    ((trcRateBand3Low as number) * (trcValueBand3 as number)) / 100 +
    ((trcRateBand4Low as number) * (trcValueBand4 as number)) / 100 +
    ((trcRateBand5Low as number) * (trcValueBand5 as number)) / 100;

  let trcAmountLow = isTicketFee
    ? opk10 * (noTxLow as number)
    : Math.max(bandsSum, trcMinFee as number) * (noTxLow as number);

  trcAmountLow = isNaN(trcAmountLow) ? 0 : trcAmountLow;

  let trcAmountMedium =
    (isTicketFee
      ? opk10 * (noTxMedium as number)
      : Math.max(bandsSum, trcMinFee as number) * (noTxMedium as number)) ?? 0;

  trcAmountMedium = isNaN(trcAmountMedium) ? 0 : trcAmountMedium;

  let trcAmountHigh =
    (isTicketFee
      ? opk10 * (noTxHigh as number)
      : Math.max(bandsSum, trcMinFee as number) * (noTxHigh as number)) ?? 0;

  trcAmountHigh = isNaN(trcAmountHigh) ? 0 : trcAmountHigh;

  let trcBpsIsDM =
    (feeClassMatrices?.find(
      (fcm) =>
        fcm.feeType.text.toLowerCase() === "MAS Fee".toLowerCase() &&
        fcm.feeClass.code.toLowerCase() === "Bps".toLowerCase()
    )?.amount as number) ?? 0;

  trcBpsIsDM = isNaN(trcBpsIsDM) ? 0 : trcBpsIsDM;

  let productRelatedCosts = feeTypeMatrices?.filter(
    (ftm) =>
      ftm.feeType.text.toLowerCase() === "Product Related Costs".toLowerCase()
  )?.length as number;

  productRelatedCosts = isNaN(productRelatedCosts) ? 0 : productRelatedCosts;

  const smToIs: ServiceModelToInvestmentStrategy | undefined =
    investmentStrategy?.serviceModelToInvestmentStrategies !== undefined
      ? investmentStrategy?.serviceModelToInvestmentStrategies[0]
      : undefined;

  const prcServiceModel = smToIs?.prcServiceModel ?? serviceModel;
  const prcInvestmentStrategy =
    smToIs?.prcInvestmentStrategy ?? investmentStrategy;

  const prcModelPortfolioStrategy = (
    rmLocation?.code === "UK"
      ? prcFeeClassMatrices?.find(
          (fcm) =>
            fcm.feeType.text.toLowerCase() ===
              "Product Related Costs.UK".toLowerCase() &&
            fcm.feeClass.text.toLowerCase() === "Rate".toLowerCase()
        )
      : prcFeeClassMatrices?.find(
          (fcm) =>
            fcm.feeType.text.toLowerCase() ===
              "Product Related Costs".toLowerCase() &&
            fcm.feeClass.text.toLowerCase() === "Rate".toLowerCase()
        )
  )?.amount as number;

  React.useEffect(() => {
    if (setRefModelPortfolioStrategyForPrc) {
      setRefModelPortfolioStrategyForPrc(
        (prcServiceModel?.text ?? "") +
          ", strategy " +
          (prcInvestmentStrategy?.text ?? "")
      );
    }
  }, [prcServiceModel, prcInvestmentStrategy]);

  React.useEffect(() => {
    if (!bookingCenter || !prcInvestmentStrategy || !prcServiceModel) {
      return;
    }

    cancelTokens.forEach((source) => source.cancel());
    const tokenSource = axios.CancelToken.source();
    cancelTokens.push(tokenSource);

    api
      .get({
        endpoint: "/fee-class-matrices",
        data: {
          bookingCenterId: bookingCenter?.id,
          servicePackageId: servicePackage?.id,
          serviceModelId: prcServiceModel?.id,
          brokerageModelId: brokerageModel?.id,
          investmentStrategyId: prcInvestmentStrategy?.id,
        },
        cancelToken: tokenSource,
      })
      .then((data) => {
        setPrcFeeClassMatrices(data.entities as FeeClassMatrix[]);
      })
      .catch((ex) => {
        console.log(ex);
      });
  }, [
    bookingCenter,
    prcServiceModel,
    prcInvestmentStrategy,
    brokerageModel,
    servicePackage,
  ]);

  React.useEffect(() => {
    if (setTrcBpsIsDM) {
      setTrcBpsIsDM(trcBpsIsDM);
    }
    if (setTrcAmountLow) {
      setTrcAmountLow(trcAmountLow);
    }
    if (setTrcAmountMedium) {
      setTrcAmountMedium(trcAmountMedium);
    }
    if (setTrcAmountHigh) {
      setTrcAmountHigh(trcAmountHigh);
    }
    if (setProductRelatedCosts) {
      setProductRelatedCosts(productRelatedCosts);
    }
  }, [
    trcBpsIsDM,
    trcAmountLow,
    trcAmountMedium,
    trcAmountHigh,
    productRelatedCosts,
    setTrcBpsIsDM,
    setTrcAmountLow,
    setTrcAmountMedium,
    setTrcAmountHigh,
    setProductRelatedCosts,
  ]);

  React.useEffect(() => {
    if (setPrcModelPortfolioStrategy) {
      setPrcModelPortfolioStrategy(prcModelPortfolioStrategy);
    }
  }, [setPrcModelPortfolioStrategy, prcModelPortfolioStrategy]);

  if (!investmentAmount) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="trc-amount-sum-low"
          name="trc-amount-sum-low"
          label="TRC_Amount__sum.Low"
          variant="filled"
          value={formatNumber(trcAmountLow, { decimals: 2 })}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
        />
        <StyledTextInput
          id="trc-amount-sum-medium"
          name="trc-amount-sum-medium"
          label="TRC_Amount__sum.Medium"
          variant="filled"
          value={formatNumber(trcAmountMedium, { decimals: 2 })}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
        />
        <StyledTextInput
          id="trc-amount-sum-high"
          name="trc-amount-sum-high"
          label="TRC_Amount__sum.High"
          variant="filled"
          value={formatNumber(trcAmountHigh, { decimals: 2 })}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
        />
        <StyledTextInput
          id="trc-bps-is-dm"
          name="trc-bps-is-dm"
          label="TRC_Bps.is_DM"
          variant="filled"
          value={formatNumber(trcBpsIsDM, { decimals: 2 })}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
        />
        <StyledFormControlLabel
          disabled
          control={<Checkbox checked={transactionRelatedCosts !== 0} />}
          label="Transaction Related Costs-Type"
        />
        <StyledTextInput
          id="product-related-costs"
          name="product-related-costs"
          label="Product Related Costs"
          variant="filled"
          value={formatNumber(productRelatedCosts, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />
        <StyledTextInput
          id="ref-model-portfolio-strategy-prc"
          name="ref-model-portfolio-strategy-prc"
          label="Ref. Model Portfolio / Strategy for PRC"
          variant="filled"
          value={
            refModelPortfolioStrategyForPrc === ", strategy "
              ? "NONE"
              : refModelPortfolioStrategyForPrc
          }
          InputProps={{ readOnly: true }}
        />
        <StyledTextInput
          id="prc-model-portfolio-strategy"
          name="prc-model-portfolio-strategy"
          label="PRC of Model Portfolio / Strategy"
          variant="filled"
          value={
            prcModelPortfolioStrategy
              ? prcModelPortfolioStrategy.toFixed(3)
              : ""
          }
          InputProps={{ readOnly: true }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default Conclusions;
