import { DropdownItem } from "../../../../../../components/Dropdown";
import { FormState } from "../../../../../../store";
import { currenciesToDropdownItems, languagesToDropdownItems } from "./utils";
import { LANGUAGE_GET_API_REQUEST } from "../../../../../../reducers/languages-reducer";
import { CURRENCY_GET_API_REQUEST } from "../../../../../../reducers/currencies-reducer";

export default interface OutputFieldsProperties {
  languages: DropdownItem[];
  currencies: DropdownItem[];
  onRequestGetLanguages?: any;
  onRequestGetCurrencies?: any;
}

export const mapStateToProps = (state: FormState): OutputFieldsProperties => {
  return {
    languages: languagesToDropdownItems(state.languagesReducer.languages),
    currencies: currenciesToDropdownItems(state.currenciesReducer.currencies),
  };
};

export const mapDispatchToProps = (
  dispatch: (arg0: { type: string }) => any
) => {
  return {
    onRequestGetLanguages: () => dispatch({ type: LANGUAGE_GET_API_REQUEST }),
    onRequestGetCurrencies: () => dispatch({ type: CURRENCY_GET_API_REQUEST }),
  };
};
