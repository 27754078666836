import React from "react";
import { StyledDropdownInput, StyledInputWrapper } from "../../index.style";
import Content from "../../../../../../components/Content";
import Header from "../../../../../../components/Header";
import { connect } from "react-redux";
import OutputFieldsProperties, {
  mapDispatchToProps,
  mapStateToProps,
} from "./index.props";
import { SelectChangeEvent } from "@mui/material";
import Language from "../../../../../../models/Language";
import store from "../../../../../../store";
import { outputUnitItems } from "../../../../../../app/costants";
import NumberField from "../../../../../../components/NumberField/NumberField";
import AppContext, { AppContextTypes } from "app/index.ctx";

export const OutputFields = (
  props: OutputFieldsProperties
): React.ReactElement => {
  const {
    setOutputCurrency,
    outputCurrency,
    setPercentageInsteadOfBps,
    percentageInsteadOfBps,
    bcCurrency,
    exchangeRate,
    setExchangeRate,
    setOutputLanguage,
    outputLanguage,
  } = React.useContext(AppContext) as AppContextTypes;

  const {
    languages,
    currencies,
    onRequestGetLanguages,
    onRequestGetCurrencies,
  } = props;
  const disabled =
    !bcCurrency || !outputCurrency || outputCurrency === bcCurrency;
  const exchangeRateAdornment = (() => {
    if (!bcCurrency) return "";

    return !outputCurrency
      ? bcCurrency + "/" + bcCurrency
      : outputCurrency + "/" + bcCurrency;
  })();

  let exchangeRateInputRef: any;
  const outputLanguageRef = React.useRef<any>();

  React.useEffect(() => {
    onRequestGetLanguages();
    onRequestGetCurrencies();
  }, []);

  React.useEffect(() => {
    if (!languages || languages.length === 0) {
      return;
    }

    if (setOutputLanguage) {
      const ol: Language | undefined = store
        .getState()
        .languagesReducer.languages.find((l) => l.code.toLowerCase() === "en");

      setOutputLanguage(ol as Language);
      outputLanguageRef.current.setValue(ol?.id);
    }
  }, [languages, setOutputLanguage]);

  React.useEffect(() => {
    if (bcCurrency === outputCurrency) {
      return;
    }

    exchangeRateInputRef?.focus();
  }, [outputCurrency, bcCurrency]);

  const onChangeOutputCurrency = (
    event: SelectChangeEvent<string | number>
  ) => {
    const value = event.target.value as string;

    if (setOutputCurrency) {
      setOutputCurrency(value.toUpperCase());
    }

    if (setExchangeRate) {
      setExchangeRate(value === bcCurrency ? 1.0 : undefined);
    }
  };

  const onChangeExchangeRate = (value: string) => {
    const _value = parseFloat(value);

    if (setExchangeRate) {
      setExchangeRate(_value);
    }
  };

  const onChangeOutputLanguage = (
    event: SelectChangeEvent<string | number>
  ) => {
    const languageId = event.target.value as number;
    const ol: Language | undefined = store
      .getState()
      .languagesReducer.languages.find((l) => l.id === languageId);
    if (setOutputLanguage) {
      setOutputLanguage(ol as Language);
    }
  };

  /*
  const onChangeOutputUnit = (event: SelectChangeEvent<string | number>) => {
    const piob = event.target.value === outputUnitItems[1].value;
    if (setPercentageInsteadOfBps) {
      setPercentageInsteadOfBps(piob);
    }
  };
  */

  return (
    <React.Fragment>
      <Content>
        <Header title="Output fields" />
      </Content>
      <StyledInputWrapper>
        <StyledDropdownInput
          name="output-language"
          label="Output language"
          variant="outlined"
          items={languages ?? []}
          onChange={onChangeOutputLanguage}
          defaultValue={outputLanguage?.id}
          required
          ref={outputLanguageRef}
        />
        <StyledDropdownInput
          name="output-unit"
          label="Output unit"
          variant="outlined"
          items={outputUnitItems}
          defaultValue={
            (percentageInsteadOfBps as boolean)
              ? outputUnitItems[1].value
              : outputUnitItems[0].value
          }
          onChange={() =>
            setPercentageInsteadOfBps &&
            setPercentageInsteadOfBps(!percentageInsteadOfBps)
          }
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <StyledDropdownInput
          name="output-currency"
          label="Output currency"
          variant="outlined"
          items={currencies}
          onChange={onChangeOutputCurrency}
          defaultValue={outputCurrency ?? ""}
        />
        <NumberField
          name="exchange-rate"
          label="Exchange rate"
          variant="outlined"
          startAdornment={exchangeRateAdornment}
          disabled={disabled}
          onChange={onChangeExchangeRate}
          value={exchangeRate}
          required={!disabled}
          inputRef={(inputRef) => (exchangeRateInputRef = inputRef)}
          min={0}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OutputFields);
