/* istanbul ignore file */
import { DropdownItem } from "../../../../../../components/Dropdown";
import { FormState } from "../../../../../../store";
import {
  brokerageModelsToDropdownItems,
  investmentStrategiesToDropdownItems,
} from "./utils";
import {
  BROKERAGE_MODEL_CLEAR,
  BROKERAGE_MODEL_GET_API_REQUEST,
  BROKERAGE_MODEL_SELECTED,
} from "../../../../../../reducers/brokerage-models-reducer";
import {
  INVESTMENT_STRATEGY_CLEAR,
  INVESTMENT_STRATEGY_GET_API_REQUEST,
  INVESTMENT_STRATEGY_SELECTED,
} from "../../../../../../reducers/investment-strategies-reducer";

export default interface InvestmentFieldsProps {
  brokerageModels: DropdownItem[];
  investmentStrategies: DropdownItem[];
  onRequestGetBrokerageModels?: any;
  onRequestGetInvestmentStrategies?: any;
  onRequestClearBrokerageModels?: any;
  onRequestClearInvestmentStrategies?: any;
  onBrokerageModelsSelected?: any;
  onInvestmentStrategiesSelected?: any;
}

export const mapStateToProps = (state: FormState): InvestmentFieldsProps => {
  return {
    brokerageModels: brokerageModelsToDropdownItems(
      state.brokerageModelsReducer.brokerageModels
    ),
    investmentStrategies: investmentStrategiesToDropdownItems(
      state.investmentStrategiesReducer.investmentStrategies
    ),
  };
};

export const mapDispatchToProps = (
  dispatch: (arg0: { type: string; id1?: number; id2?: number }) => any
) => {
  return {
    onRequestGetBrokerageModels: (bcId: number, smId: number) =>
      dispatch({ type: BROKERAGE_MODEL_GET_API_REQUEST, id1: bcId, id2: smId }),
    onRequestGetInvestmentStrategies: (smId: number) =>
      dispatch({ type: INVESTMENT_STRATEGY_GET_API_REQUEST, id1: smId }),
    onRequestClearBrokerageModels: () =>
      dispatch({ type: BROKERAGE_MODEL_CLEAR }),
    onRequestClearInvestmentStrategies: () =>
      dispatch({ type: INVESTMENT_STRATEGY_CLEAR }),
    onBrokerageModelsSelected: () =>
      dispatch({ type: BROKERAGE_MODEL_SELECTED }),
    onInvestmentStrategiesSelected: () =>
      dispatch({ type: INVESTMENT_STRATEGY_SELECTED }),
  };
};
