import React from "react";
import Content from "../../../../../components/Content";
import Header from "../../../../../components/Header";
import { StyledInputWrapper, StyledTextInput } from "../index.style";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Remarks = (): React.ReactElement => {
  const { setRemarks, remarks } = React.useContext(
    AppContext
  ) as AppContextTypes;

  const onRemarksChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setRemarks) {
      setRemarks(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <Content>
        <Header title="Remarks" />
      </Content>
      <StyledInputWrapper>
        <StyledTextInput
          label="Remarks"
          multiline
          name="remarks"
          variant="outlined"
          value={remarks}
          inputProps={{ maxLength: 400 }}
          maxRows={4}
          onChange={onRemarksChanged}
          helperText={`${remarks?.length}/${400} characters`}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default Remarks;
