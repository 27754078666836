import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import { InputAdornment } from "@mui/material";
import { formatNumber } from "../../../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const MAS = (): React.ReactElement => {
  const {
    feeClassMatrices,
    investmentAmount,
    bcCurrency,
    masFee,
    trcAvgTxSize,
    noTxLow,
    noTxMedium,
    noTxHigh,
    setTrcMasLow,
    setTrcMasMedium,
    setTrcMasHigh,
  } = React.useContext(AppContext) as AppContextTypes;

  const basisPoints =
    ((feeClassMatrices?.find(
      (fcm) =>
        fcm.feeType.text.toLowerCase() === "MAS Fee".toLowerCase() &&
        fcm.feeClass.text.toLowerCase() === "Bps".toLowerCase()
    )?.amount as number) ?? 0) / 10000;

  const masLow =
    (masFee as number) *
    (trcAvgTxSize as number) *
    basisPoints *
    (noTxLow as number);
  const masMedium =
    (masFee as number) *
    (trcAvgTxSize as number) *
    basisPoints *
    (noTxMedium as number);
  const masHigh =
    (masFee as number) *
    (trcAvgTxSize as number) *
    basisPoints *
    (noTxHigh as number);

  React.useEffect(() => {
    if (setTrcMasLow) {
      setTrcMasLow(masLow);
    }
    if (setTrcMasMedium) {
      setTrcMasMedium(masMedium);
    }
    if (setTrcMasHigh) {
      setTrcMasHigh(masHigh);
    }
  }, [
    masLow,
    masMedium,
    masHigh,
    setTrcMasLow,
    setTrcMasMedium,
    setTrcMasHigh,
  ]);

  if (!investmentAmount) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="mas-basis-points"
          name="mas-basis-points"
          label="TRC_MAS_BasisPoint"
          variant="filled"
          value={formatNumber(basisPoints, { decimals: 5 })}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">bps</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="trc-mas-low"
          name="trc-mas-low"
          label="TRC_MAS Low"
          variant="filled"
          value={masLow ? formatNumber(Number(masLow), { decimals: 2 }) : 0.0}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="trc-mas-medium"
          name="trc-mas-medium"
          label="TRC_MAS Medium"
          variant="filled"
          value={
            masMedium ? formatNumber(Number(masMedium), { decimals: 2 }) : 0
          }
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="trc-mas-high"
          name="trc-mas-high"
          label="TRC_MAS High"
          variant="filled"
          value={masHigh ? formatNumber(Number(masHigh), { decimals: 2 }) : 0}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default MAS;
