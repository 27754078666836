import { takeLatest, call, put } from "redux-saga/effects";
import {
  FEE_CLASS_MATRIX_GET_API_REQUEST,
  FEE_CLASS_MATRIX_GET_API_SUCCESS,
  FEE_CLASS_MATRIX_GET_API_FAILURE,
} from "../reducers/fee-class-matrices-reducer";
import api, { GetResponse } from "../api";
import FeeClassMatrix from "../models/FeeClassMatrix";

export interface RetrieveFeeClassMatricesParams {
  type: string;
  servicePackageId: number;
  bookingCenterId: number;
  serviceModelId: number;
  brokerageModelId: number;
  investmentStrategyId: number;
}

function getFeeClassMatrices({
  servicePackageId,
  bookingCenterId,
  serviceModelId,
  brokerageModelId,
  investmentStrategyId
}: RetrieveFeeClassMatricesParams): Promise<GetResponse<FeeClassMatrix>> {
  let data: any = {
    servicePackageId,
    bookingCenterId,
    serviceModelId,
    investmentStrategyId
  };

  if (brokerageModelId) {
    data = {
      ...data,
      brokerageModelId
    }
  } else {
    data = {
      ...data,
      "brokerageModel.text": "zzzNotRelevant"
    }
  }

  return api.get<FeeClassMatrix>({
    endpoint: "/fee-class-matrices",
    data,
  });
}

function* retrieveFeeClassMatrices(action: RetrieveFeeClassMatricesParams) {
  try {
    const response: GetResponse<FeeClassMatrix> = yield call(
      getFeeClassMatrices,
      action
    );
    yield put({
      type: FEE_CLASS_MATRIX_GET_API_SUCCESS,
      feeClassMatrices: response.entities,
    });
  } catch (error) {
    yield put({ type: FEE_CLASS_MATRIX_GET_API_FAILURE, error });
  }
}

export default function* feeClassMatricesSaga() {
  yield takeLatest(FEE_CLASS_MATRIX_GET_API_REQUEST, retrieveFeeClassMatrices);
}
