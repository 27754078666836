import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { getAccessToken } from "./utils/jwtUtils";
import {
  forceCalculateButtonToDisabled,
  removeForceCalculateButtonToDisabled,
} from "./utils/validate";
import env from "./config/env";

const getCallStack: {}[] = [];

export interface GetResponse<T> {
  entities: T[];
  totalEntities?: number;
}

export interface PostResponse<T> {
  data: T;
}

export interface PatchResponse<T> {
  data: T;
}

export interface InternalGetResponse<T> {
  _embedded: {
    entities: T[];
  };
  _links: {
    first?: {
      href: string;
    };
    prev?: {
      href: string;
    };
    self?: {
      href: string;
    };
    next?: {
      href: string;
    };
    last?: {
      href: string;
    };
  };
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  };
}

interface InternalGetRequest {
  endpoint: string;
  auto_paginate?: boolean;
  data?: {
    page?: number;
    size?: number;
  };
  cancelToken?: CancelTokenSource;
  headers?: any;
}

export interface GetRequest {
  endpoint: string;
  auto_paginate?: boolean;
  data?: any;
  cancelToken?: CancelTokenSource;

  headers?: any;
}

export interface PostRequest {
  endpoint: string;
  data?: any;
  headers?: any;
}

export interface PatchRequest {
  endpoint: string;
  data?: any;
}

const getInternal = async <T>(
  request: InternalGetRequest
): Promise<InternalGetResponse<T>> => {
  let { endpoint, data, cancelToken, headers } = request;

  const baseUrl = env.REACT_APP_API_URL ?? "";
  const accessToken = getAccessToken();

  return new Promise<InternalGetResponse<T>>((resolve, reject) => {
    const url = endpoint.startsWith(baseUrl) ? endpoint : baseUrl + endpoint;

    axios
      .get(url, {
        cancelToken: cancelToken?.token,
        params: data ?? {},
        headers: {
          ...(headers ?? []),
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response: AxiosResponse) => {
        const result: InternalGetResponse<T> = response.data;
        resolve(result);
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};

const get = async <T>(request: GetRequest): Promise<GetResponse<T>> => {
  getCallStack.push({});
  forceCalculateButtonToDisabled("api");

  let { data, auto_paginate, cancelToken, headers } = request;

  if (auto_paginate === undefined || auto_paginate === null) {
    auto_paginate = true;
  }

  data ??= {};

  if (!request.endpoint.includes("size=")) data.size = 100;
  if (!request.endpoint.includes("page=")) data.page = 0;

  return new Promise<GetResponse<T>>((resolve, reject) => {
    const popStack = () => {
      getCallStack.pop();

      if (getCallStack.length === 0) {
        removeForceCalculateButtonToDisabled("api");
      }
    };
    const internalResolver = (data: any) => {
      popStack();
      resolve(data);
    };

    const internalReject = (data: any) => {
      popStack();
      reject(data);
    };

    getInternal<T>({
      endpoint: request.endpoint,
      data,
      cancelToken,
      headers,
    })
      .then((response: InternalGetResponse<T>) => {
        let result: GetResponse<T> = {
          entities: response._embedded?.entities ?? [],
          totalEntities:
            response.page?.totalElements ??
            response._embedded.entities?.length ??
            0,
        };

        if (response._links?.next && auto_paginate) {
          get<T>({
            endpoint: response._links.next.href,
          })
            .then((new_result: GetResponse<T>) => {
              result.entities = [...result.entities, ...new_result.entities];

              internalResolver(result);
            })
            .catch((reason: any) => {
              internalReject(reason);
            });
        } else {
          internalResolver(result);
        }
      })
      .catch((reason: any) => {
        internalReject(reason);
      });
  });
};

const post = async <T>(request: PostRequest): Promise<PostResponse<T>> => {
  let { endpoint, data, headers } = request;
  data ??= {};

  const baseUrl = env.REACT_APP_API_URL ?? "";
  const accessToken = getAccessToken();

  return new Promise<PostResponse<T>>((resolve, reject) => {
    const url = endpoint.startsWith(baseUrl) ? endpoint : baseUrl + endpoint;

    axios
      .post(url, data, {
        headers: {
          ...(headers ?? []),
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response: AxiosResponse) => {
        const result: PostResponse<T> = response;
        resolve(result);
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};

const patch = async <T>(request: PatchRequest): Promise<PatchResponse<T>> => {
  let { endpoint, data } = request;
  data ??= {};

  const baseUrl = env.REACT_APP_API_URL ?? "";
  const accessToken = getAccessToken();

  return new Promise<PatchResponse<T>>((resolve, reject) => {
    const url = endpoint.startsWith(baseUrl) ? endpoint : baseUrl + endpoint;

    axios
      .patch(url, data, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response: AxiosResponse) => {
        const result: PatchResponse<T> = response;
        resolve(result);
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};

const api = {
  get,
  post,
  patch,
};

export default api;
