import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../index.style";
import Content from "../../../../../../components/Content";
import Header from "../../../../../../components/Header";
import NumberField from "../../../../../../components/NumberField/NumberField";
import { Alert, AlertTitle } from "@mui/material";
import AppContext, { AppContextTypes } from "app/index.ctx";

export const ProductRelatedCostFields = (): React.ReactElement => {
  const {
    refModelPortfolioStrategyForPrc,
    prcModelPortfolioStrategy,
    overridePercentageOfPRC,
    setOverridePercentageOfPRC,
  } = React.useContext(AppContext) as AppContextTypes;

  const onOverridePRCPercentageChanged = (value: string) => {
    if (setOverridePercentageOfPRC) {
      setOverridePercentageOfPRC(value === "" ? undefined : parseFloat(value));
    }
  };

  return (
    <React.Fragment>
      <Content>
        <Header title="Product related costs fields" />
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Alert severity="warning">
            <AlertTitle>Attention</AlertTitle>
            When providing a discount or flat free on brokerage fees, minimum
            fees are NOT considered
          </Alert>
        </div>
      </Content>
      <StyledInputWrapper>
        <NumberField
          label="Override PRC of Model Portfolio / Strategy"
          name="override-prc-percentage"
          variant="outlined"
          value={overridePercentageOfPRC}
          onChange={onOverridePRCPercentageChanged}
          endAdornment={"%"}
          min={0}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <StyledTextInput
          id="ref-model-portfolio-strategy-prc"
          name="ref-model-portfolio-strategy-prc"
          label="Ref. Model Portfolio / Strategy for PRC"
          variant="filled"
          value={
            refModelPortfolioStrategyForPrc === ", strategy "
              ? "NONE"
              : refModelPortfolioStrategyForPrc
          }
          InputProps={{ readOnly: true }}
        />
        <StyledTextInput
          id="prc-model-portfolio-strategy"
          name="prc-model-portfolio-strategy"
          label="PRC of Model Portfolio / Strategy"
          variant="filled"
          value={prcModelPortfolioStrategy?.toFixed(3) ?? "0.000"}
          InputProps={{ readOnly: true }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default ProductRelatedCostFields;
