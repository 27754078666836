import React from "react";
import Grid, { GridSize } from "@mui/material/Grid";
import { BackButton, StyledTypography } from "./index.style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";

export interface HeaderProps {
  title: string | React.ReactNode;
  subtitle?: string;
  size?: GridSize;
  showBackButton?: boolean;
  backLink?: string;
}

const Header = (props: HeaderProps): React.ReactElement => {
  const { title, subtitle, size, showBackButton, backLink } = props;

  const maybeRenderBackButton = (): React.ReactElement => {
    if (!showBackButton) return <React.Fragment />;

    return (
      <Link to={backLink ?? "/login"} style={{ textDecoration: "none" }}>
        <BackButton
          variant="outlined"
          color="primary"
          startIcon={<ArrowBackIcon />}
        >
          Back
        </BackButton>
      </Link>
    );
  };

  return (
    <React.Fragment>
      <Grid item xs={size ?? 12} role="grid">
        <StyledTypography
          variant="h6"
          gutterBottom
          color={"primary"}
          height={"inherit!important"}
        >
          {maybeRenderBackButton()}
          <span id="header-title">
            <b>{title}</b>
          </span>
        </StyledTypography>
        {subtitle ? (
          <StyledTypography variant="subtitle1" gutterBottom color={"primary"}>
            <span id="header-title">{subtitle}</span>
          </StyledTypography>
        ) : (
          <React.Fragment />
        )}
        <Divider />
      </Grid>
    </React.Fragment>
  );
};

export default Header;
