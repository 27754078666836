import Language from "../../../../../../models/Language";
import { DropdownItem } from "../../../../../../components/Dropdown";
import Currency from "../../../../../../models/Currency";

export const languagesToDropdownItems = (
  languages: Language[]
): DropdownItem[] => {
  return languages.map<DropdownItem>((language: Language): DropdownItem => {
    return {
      value: language.id,
      label: language.code,
    };
  });
};

export const currenciesToDropdownItems = (
  currencies: Currency[]
): DropdownItem[] => {
  return currencies.map<DropdownItem>((currency: Currency): DropdownItem => {
    return {
      value: currency.code,
      label: currency.code,
    };
  });
};
