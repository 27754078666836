import { styled } from "@mui/material/styles";
import Section from "../../components/Section";
import {Button} from "@mui/material";

export const StyledSection = styled(Section)`
    overflow: hidden;
`;

export const ButtonWrapper = styled('span')`
  & > *:not(:last-child) {
    margin-right: 15px;
  }
`;

export const StyledButton = styled(Button)`
    border-radius: 0;
    text-transform: none;
    font-weight: bold;
`;
