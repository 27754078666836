import React from "react";
import Content from "../../../../../../components/Content";
import { StyledInputWrapper, StyledDropdownInput } from "../../index.style";
import InvestmentFieldsProps, {
  mapDispatchToProps,
  mapStateToProps,
} from "./index.props";
import { SelectChangeEvent } from "@mui/material";
import Header from "../../../../../../components/Header";
import { connect } from "react-redux";
import store from "../../../../../../store";
import { resetSlaveDropdown } from "../../utils";
import { DropdownHandles } from "../../../../../../components/Dropdown";
import InvestmentStrategy from "../../../../../../models/InvestmentStrategy";
import BrokerageModel from "../../../../../../models/BrokerageModel";
import NumberField from "../../../../../../components/NumberField/NumberField";
import AppContext, { AppContextTypes } from "app/index.ctx";

export const InvestmentFields = (
  props: InvestmentFieldsProps
): React.ReactElement => {
  const {
    bookingCenter,
    brokerageModel,
    setBrokerageModel,
    outputCurrency,
    investmentStrategy,
    investmentAmount,
    setInvestmentStrategy,
    setInvestmentAmount,
    setInvestmentStrategyRequired,
  } = React.useContext(AppContext) as AppContextTypes;

  const prevBookingCenterId = React.useRef<number | undefined>(undefined),
    prevServiceModelId = React.useRef<number | undefined>(undefined);

  const { brokerageModels, investmentStrategies } = props;

  const {
    onRequestGetBrokerageModels,
    onRequestGetInvestmentStrategies,
    onRequestClearBrokerageModels,
    onRequestClearInvestmentStrategies,
  } = props;

  const brokerageModelsRef = React.createRef<DropdownHandles>(),
    investmentStrategiesRef = React.createRef<DropdownHandles>();

  const onServiceModelChanged = (value?: number, reset?: boolean) => {
    if (reset) {
      resetSlaveDropdown(brokerageModelsRef, onRequestClearBrokerageModels);
      resetSlaveDropdown(
        investmentStrategiesRef,
        onRequestClearInvestmentStrategies
      );
    }

    if (isNaN(value as number)) return;

    onRequestGetBrokerageModels(bookingCenter?.id, value);
    onRequestGetInvestmentStrategies(value);
  };

  React.useEffect(() => {
    const state = store.getState();

    if (prevBookingCenterId.current !== state.bookingCentersReducer.selected) {
      prevBookingCenterId.current = state.bookingCentersReducer
        .selected as number;
    }

    if (prevServiceModelId.current !== state.serviceModelsReducer.selected) {
      const reset = prevServiceModelId.current !== undefined;

      prevServiceModelId.current = state.serviceModelsReducer
        .selected as number;

      onServiceModelChanged(state.serviceModelsReducer.selected, reset);
    }
  });

  React.useEffect(() => {
    if (setInvestmentStrategyRequired) {
      setInvestmentStrategyRequired(investmentStrategies.length > 0);
    }
  }, [investmentStrategies]);

  const investmentStrategyChanged = (
    event: SelectChangeEvent<string | number>
  ): void => {
    const isId = parseInt(event.target.value as string);
    const _investmentStrategy: InvestmentStrategy | undefined = store
      .getState()
      .investmentStrategiesReducer.investmentStrategies.find(
        (is) => is.id === isId
      );

    if (setInvestmentStrategy) {
      setInvestmentStrategy(_investmentStrategy as InvestmentStrategy);
    }

    setInvestmentAmount!("");
  };

  const onInvestmentAmountChanged = (value: string) => {
    const _value: number = parseFloat(value);

    if (setInvestmentAmount) {
      setInvestmentAmount(_value);
    }
  };

  const onBrokerageModelChanged = (
    event: SelectChangeEvent<string | number>
  ): void => {
    const bmId = parseInt(event.target.value as string);

    const _brokerageModel: BrokerageModel | undefined = store
      .getState()
      .brokerageModelsReducer.brokerageModels.find((bm) => bm.id === bmId);

    if (setBrokerageModel) {
      setBrokerageModel(_brokerageModel as BrokerageModel);
    }
  };

  return (
    <React.Fragment>
      <Content>
        <Header title="Investment fields" />
      </Content>
      <StyledInputWrapper>
        <StyledDropdownInput
          label="Brokerage model"
          name="brokerage-model"
          items={brokerageModels}
          variant="outlined"
          ref={brokerageModelsRef}
          onChange={onBrokerageModelChanged}
          defaultValue={brokerageModel?.id}
        />

        <StyledDropdownInput
          label="Investment strategy"
          name="investment-strategy"
          items={investmentStrategies}
          variant="outlined"
          ref={investmentStrategiesRef}
          onChange={investmentStrategyChanged}
          defaultValue={investmentStrategy?.id}
          required={investmentStrategies.length > 0}
        />

        <NumberField
          label="Investment amount"
          name="investment-amount"
          variant="outlined"
          startAdornment={outputCurrency}
          onChange={onInvestmentAmountChanged}
          value={investmentAmount}
          min={0}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentFields);
