const calculateCanBeEnabled: { name: string }[] = [];

const findForceByName = (forceName: string) => {
  return calculateCanBeEnabled.findIndex((v) => v.name === forceName);
};

export const forceCalculateButtonToDisabled = (forceName: string) => {
  if (findForceByName(forceName) > -1) {
    return;
  }

  calculateCanBeEnabled.push({
    name: forceName,
  });
};

export const removeForceCalculateButtonToDisabled = (forceName: string) => {
  const index = findForceByName(forceName);

  if (index === -1) {
    return;
  }

  calculateCanBeEnabled.splice(index, 1);
};

export const isCalculateEnabled = () => calculateCanBeEnabled.length === 0;