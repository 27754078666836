import React from "react";
import api from "../../api";
import Card from "../../components/Card";
import { Container, Grid } from "@mui/material";
import { StyledSection, ButtonWrapper, StyledButton } from "./index.style";
import Table, { TableEntity, TableHandles } from "../../components/Table";
import SearchBar from "../../components/SearchBar/SearchBar";

export type AdminEntityProps = {
  title: string;
  endpoint: string;
  columns: {
    label: string;
    id: string;
    numeric: boolean;
  }[];
};

const AdminEntity = (props: AdminEntityProps): React.ReactElement => {
  const [entities, setEntities] = React.useState<TableEntity[]>([]);
  const [editEnabled, setEditEnabled] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<TableEntity | undefined>();
  const [page, setPage] = React.useState<number>(0);
  const [size, setSize] = React.useState<number>(10);
  const [sort, setSort] = React.useState<string>("id");
  const [direction, setDirection] = React.useState<"asc" | "desc">("asc");
  const [search, setSearch] = React.useState<string>("");
  const [totalEntities, setTotalEntities] = React.useState<number>(0);
  const { title, endpoint, columns } = props;

  const tableRef = React.useRef<TableHandles>(null);

  React.useEffect(() => {
    let _endpoint = endpoint + "?page=" + page + "&size=" + size;

    if (sort !== "") {
      _endpoint = _endpoint + "&sort=" + sort + "," + direction;
    }

    if (search !== "") {
      _endpoint = _endpoint + "&search=" + search;
    }

    api
      .get({
        endpoint: _endpoint,
        auto_paginate: false,
      })
      .then((response) => {
        setTotalEntities(response.totalEntities as number);

        setEntities([...(response.entities as TableEntity[])]);
      });
  }, [page, size, sort, direction, search, endpoint, totalEntities]);

  const onEditClicked = () => {
    window.location.href += "/edit/" + selected?.id;
  };

  const onSelected = (newSelected: TableEntity, enabled: boolean) => {
    setEditEnabled(enabled);
    setSelected(newSelected);
  };

  const reset = () => {
    setPage(0);
    setEntities([]);
  };

  const onSizeChanged = (pageSize: number) => {
    setSize(pageSize);
    reset();
  };

  const onSortChanged = (newSort: string, newDirection: "asc" | "desc") => {
    setSort(newSort);
    setDirection(newDirection);
    reset();
  };

  const onSearch = (searched: string) => {
    tableRef.current?.forceReset();
    setSearch(searched);
    reset();
  };

  return (
    <React.Fragment>
      <Card
        title={title}
        Component={StyledSection}
        style={{ width: "100%" }}
        sticky
        actions={
          <React.Fragment>
            <div style={{ marginRight: "15px" }}>
              <SearchBar setSearchQuery={onSearch} />
            </div>
            <ButtonWrapper>
              <StyledButton
                variant={"contained"}
                disabled={!editEnabled}
                onClick={onEditClicked}
              >
                Edit
              </StyledButton>
            </ButtonWrapper>
          </React.Fragment>
        }
      >
        <Grid item xs={12}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Table
                ref={tableRef}
                entities={entities}
                columns={columns}
                onSelected={onSelected}
                onPageChange={setPage}
                onSizeChange={onSizeChanged}
                totalEntities={totalEntities}
                onSortChanged={onSortChanged}
                orderDefault={direction}
                orderByDefault={sort}
              />
            </Grid>
          </Container>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default AdminEntity;
