// action types
import RMLocation from "../models/RMLocation";

export const RM_LOCATION_GET_API_REQUEST = "RM_LOCATION_GET_API_REQUEST";
export const RM_LOCATION_GET_API_SUCCESS = "RM_LOCATION_GET_API_SUCCESS";
export const RM_LOCATION_GET_API_FAILURE = "RM_LOCATION_GET_API_FAILURE";
export const RM_LOCATION_SELECTED = "RM_LOCATION_SELECTED";
export const RM_LOCATION_CLEAR = "RM_LOCATION_CLEAR";

export interface RMLocationState {
  rmLocations: RMLocation[];
  selected?: number;
}

// reducer with initial state
const initialState: RMLocationState = {
  rmLocations: [],
  selected: undefined,
};

interface Action {
  type: string;
  rmLocations: RMLocation[];
  id1?: number;
}

export default function rmLocationsReducer(
  state: RMLocationState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case RM_LOCATION_GET_API_SUCCESS:
      newState.rmLocations = action.rmLocations;
      break;
    case RM_LOCATION_GET_API_FAILURE:
      break;
    case RM_LOCATION_SELECTED:
      newState.selected = action.id1;
      break;
    case RM_LOCATION_CLEAR:
      newState = initialState;
      break;
  }

  return newState;
}
