// action types
import Currency from "../models/Currency";

export const CURRENCY_GET_API_REQUEST = "CURRENCY_GET_API_REQUEST";
export const CURRENCY_GET_API_SUCCESS = "CURRENCY_GET_API_SUCCESS";
export const CURRENCY_GET_API_FAILURE = "CURRENCY_GET_API_FAILURE";

export interface CurrencyState {
  currencies: Currency[];
}

// reducer with initial state
const initialState: CurrencyState = {
  currencies: [],
};

interface Action {
  type: string;
  currencies: Currency[];
}

export default function currenciesReducer(
  state: CurrencyState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case CURRENCY_GET_API_SUCCESS:
      newState.currencies = action.currencies;
      break;
    case CURRENCY_GET_API_FAILURE:
      break;
  }

  return newState;
}
