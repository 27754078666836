import { combineReducers } from "redux";
import languagesReducer from "./languages-reducer";
import bookingCentersReducer from "./booking-centers-reducer";
import rmLocationsReducer from "./rm-locations-reducer";
import serviceModelsReducer from "./service-models-reducer";
import servicePackagesReducer from "./service-packages-reducer";
import brokerageModelsReducer from "./brokerage-models-reducer";
import investmentStrategiesReducer from "./investment-strategies-reducer";
import valueBandsReducer from "./value-bands-reducer";
import feeClassMatricesReducer from "./fee-class-matrices-reducer";
import feeTypeMatricesReducer from "./fee-type-matrices-reducer";
import transactionCostConstantsReducer from "./transaction-cost-constants-reducer";
import outputReducer from "./output-reducer";
import currenciesReducer from "./currencies-reducer";

const rootReducer = combineReducers({
  languagesReducer,
  currenciesReducer,
  bookingCentersReducer,
  rmLocationsReducer,
  serviceModelsReducer,
  servicePackagesReducer,
  brokerageModelsReducer,
  investmentStrategiesReducer,
  valueBandsReducer,
  feeClassMatricesReducer,
  feeTypeMatricesReducer,
  transactionCostConstantsReducer,
  outputReducer
});

export default rootReducer;
