/* istanbul ignore file */
import React from "react";
import { createRoot } from "react-dom/client";
import { load } from "config/env";
import App from "app";
import { AuthConfig } from "config/auth";
import reportWebVitals from "./reportWebVitals";
import "./style.css";

const root = createRoot(document.getElementById("root") as HTMLElement);

async function prepare() {
  await load();

  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_MOCK_API === "1"
  ) {
    const { worker } = await import("./mocks/browser");
    worker.start();
  }
}

prepare().then(() => {
  root.render(
    <React.StrictMode>
      <AuthConfig>
        <App />
      </AuthConfig>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
