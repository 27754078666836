// action types
import FeeClassMatrix from "../models/FeeClassMatrix";

export const FEE_CLASS_MATRIX_GET_API_REQUEST =
  "FEE_CLASS_MATRIX_GET_API_REQUEST";
export const FEE_CLASS_MATRIX_GET_API_SUCCESS =
  "FEE_CLASS_MATRIX_GET_API_SUCCESS";
export const FEE_CLASS_MATRIX_GET_API_FAILURE =
  "FEE_CLASS_MATRIX_GET_API_FAILURE";
export const FEE_CLASS_MATRIX_SELECTED = "FEE_CLASS_MATRIX_SELECTED";

export interface FeeClassMatrixState {
  feeClassMatrices: FeeClassMatrix[];
  selected?: number;
}

// reducer with initial state
const initialState: FeeClassMatrixState = {
  feeClassMatrices: [],
  selected: undefined,
};

interface Action {
  type: string;
  feeClassMatrices: FeeClassMatrix[];
  selected?: number;
}

export default function feeClassMatrixReducer(
  state: FeeClassMatrixState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case FEE_CLASS_MATRIX_GET_API_SUCCESS:
      newState.feeClassMatrices = action.feeClassMatrices;
      break;
    case FEE_CLASS_MATRIX_GET_API_FAILURE:
      break;
    case FEE_CLASS_MATRIX_SELECTED:
      newState.selected = action.selected;
      break;
  }

  return newState;
}
