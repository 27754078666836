import { takeLatest, call, put } from "redux-saga/effects";
import {
  SERVICE_PACKAGE_GET_API_REQUEST,
  SERVICE_PACKAGE_GET_API_SUCCESS,
  SERVICE_PACKAGE_GET_API_FAILURE,
} from "../reducers/service-packages-reducer";
import api, { GetResponse } from "../api";
import ServicePackage from "../models/ServicePackage";

function getServicePackages(
  bookingCenterId: string | number,
  serviceModelId: string | number,
  rmLocation: string
): Promise<GetResponse<ServicePackage>> {
  return api.get<ServicePackage>({
    endpoint:
      "/booking-centers/" +
      bookingCenterId +
      "/service-models/" +
      serviceModelId +
      "/rm-locations/" +
      rmLocation +
      "/service-packages",
  });
}

function* retrieveServicePackages(action: {
  type: string;
  id1: string | number;
  id2: string | number;
  rcCode: string;
}) {
  try {
    const response: GetResponse<ServicePackage> = yield call(
      getServicePackages,
      action.id1,
      action.id2,
      action.rcCode
    );
    yield put({
      type: SERVICE_PACKAGE_GET_API_SUCCESS,
      servicePackages: response.entities,
    });
  } catch (error) {
    yield put({ type: SERVICE_PACKAGE_GET_API_FAILURE, error });
  }
}

export default function* servicePackagesSaga() {
  yield takeLatest(SERVICE_PACKAGE_GET_API_REQUEST, retrieveServicePackages);
}
