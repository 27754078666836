import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import { formatNumber } from "../../../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Fees = (): React.ReactElement => {
  const {
    investmentAmount,
    feeTypeMatrices,
    feeClassMatrices,
    setTransactionRelatedCosts,
    setMASFee,
    setTrcMinFee,
  } = React.useContext(AppContext) as AppContextTypes;

  let transactionRelatedCosts = feeTypeMatrices?.filter(
    (ftm) =>
      ftm.feeType.text.toLowerCase() ===
      "Transaction Related Costs".toLowerCase()
  )?.length as number;

  transactionRelatedCosts = isNaN(transactionRelatedCosts)
    ? 0
    : transactionRelatedCosts;

  let masFee = feeTypeMatrices?.filter(
    (ftm) => ftm.feeType.text.toLowerCase() === "MAS Fee".toLowerCase()
  )?.length as number;

  masFee = isNaN(masFee) ? 0 : masFee;

  let trcMinFee = feeClassMatrices?.find(
    (fcm) =>
      fcm.feeType.text.toLowerCase() ===
        "Transaction Related Costs".toLowerCase() &&
      fcm.feeClass.text.toLowerCase() === "min. Fee".toLowerCase()
  )?.amount as number;

  trcMinFee = isNaN(trcMinFee) ? 0 : trcMinFee;

  React.useEffect(() => {
    if (setMASFee) {
      setMASFee(masFee);
    }
    if (setTrcMinFee) {
      setTrcMinFee(trcMinFee);
    }
  }, [masFee, trcMinFee]);

  React.useEffect(() => {
    if (setTransactionRelatedCosts) {
      setTransactionRelatedCosts(transactionRelatedCosts);
    }
  }, [transactionRelatedCosts]);

  if (!investmentAmount) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="transaction-related-costs"
          name="transaction-related-costs"
          label="Transaction related costs"
          variant="filled"
          value={formatNumber(transactionRelatedCosts, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="mas-fee"
          name="mas-fee"
          label="MAS fee"
          variant="filled"
          value={formatNumber(masFee, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="trc-min-fee"
          name="trc-min-fee"
          label="TRC_min fee"
          variant="filled"
          value={formatNumber(trcMinFee, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default Fees;
