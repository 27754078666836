import { styled } from "@mui/material/styles";
import Content from "../../../../components/Content";
import { TextField, FormControlLabel } from "@mui/material";
import Section from "../../../../components/Section";

export const StyledSection = styled(Section)`
  overflow-y: auto;
  height: 1200px;
`;

export const StyledInputWrapper = styled(Content)`
  display: block;
`;

export const StyledTextInput = styled(TextField)`
  margin-bottom: 5px;
  margin-left: -40px;
  width: 109%;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 5px;
`;
