import { Grid } from "@mui/material";
import { FormFieldLabel } from "../../../../hook-form/FormFieldLabel";
import { FormSelect } from "../../../../hook-form/FormSelect";
import FeeClassMatrix from "../../../../models/FeeClassMatrix";

export interface FirstRowFieldsProps {
  control: any;
  feeClassMatrix: FeeClassMatrix;
}

export const FirstRowFields = (props: FirstRowFieldsProps) => {
  const { feeClassMatrix, control } = props;

  return (
    <>
      <Grid item xs={4}>
        <FormSelect
          name="bookingCenter"
          label={<FormFieldLabel isRequired>Booking Center</FormFieldLabel>}
          control={control}
          fullWidth
          options={[
            {
              label: feeClassMatrix?.bookingCenter.code ?? "",
              value: feeClassMatrix?.bookingCenter.text ?? "",
            },
          ]}
          disabled
          rules={{ required: "Booking Center is Required." }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormSelect
          name="serviceModel"
          label={<FormFieldLabel isRequired>Service Model</FormFieldLabel>}
          control={control}
          fullWidth
          options={[
            {
              label: feeClassMatrix?.serviceModel.code ?? "",
              value: feeClassMatrix?.serviceModel.text ?? "",
            },
          ]}
          disabled
          rules={{ required: "Service Model is required." }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormSelect
          name="feeType"
          label={<FormFieldLabel isRequired>Fee Type</FormFieldLabel>}
          control={control}
          fullWidth
          options={[
            {
              label: feeClassMatrix?.feeType.code ?? "",
              value: feeClassMatrix?.feeType.text ?? "",
            },
          ]}
          disabled
          rules={{ required: "Fee Type is required." }}
        />
      </Grid>
    </>
  );
};
