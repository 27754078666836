/* istanbul ignore file */
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "./authentication-templates";
import AppHeader from "../components/AppHeader";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Breadcrumbs from "../components/Breadcrumbs";
import { AdminProtectedRoute, ProtectedRoute } from "./routes-protection";
import HomePage from "../pages/HomePage";
import InputData from "../pages/InputData";
import Output from "../pages/Output";
import { EditFeeClassMatrix } from "../pages/EditFeeClassMatrix";
import { EditValueBand } from "../pages/EditValueBand";
import { EditTCC } from "../pages/EditTCC";
import AdminEntityTable from "../pages/AdminEntityTable";
import React from "react";

export const AppRoutes = () => {
  return (
    <React.Fragment>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <AppHeader authenticated={true} />
        <Breadcrumbs />
        <Routes>
          <Route
            path="/"
            element={
              <AdminProtectedRoute>
                <HomePage />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="/simulation"
            element={
              <ProtectedRoute>
                <InputData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/output"
            element={
              <ProtectedRoute>
                <Output />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/fee-class-matrices/edit/:id"
            element={
              <AdminProtectedRoute>
                <EditFeeClassMatrix />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="/admin/value-bands/edit/:id"
            element={
              <AdminProtectedRoute>
                <EditValueBand />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="/admin/transaction-cost-constants/edit/:id"
            element={
              <AdminProtectedRoute>
                <EditTCC />
              </AdminProtectedRoute>
            }
          />

          <Route
            path="/admin/value-bands"
            element={
              <AdminProtectedRoute>
                <AdminEntityTable
                  title={"Value Bands"}
                  endpoint="/value-bands"
                  columns={[
                    { label: "Code", id: "code", numeric: false },
                    { label: "Text", id: "text", numeric: false },
                    {
                      label: "Minimum value",
                      id: "valMin",
                      numeric: true,
                    },
                    {
                      label: "Maximum value",
                      id: "valMax",
                      numeric: true,
                    },
                    {
                      label: "BS Remark",
                      id: "bsRemark",
                      numeric: false,
                    },
                    {
                      label: "IT Remark",
                      id: "itRemark",
                      numeric: false,
                    },
                  ]}
                />
              </AdminProtectedRoute>
            }
          />

          <Route
            path="/admin/transaction-cost-constants"
            element={
              <AdminProtectedRoute>
                <AdminEntityTable
                  title={"Transaction Cost Constants"}
                  endpoint="/transaction-cost-constants"
                  columns={[
                    { label: "Code", id: "code", numeric: false },
                    { label: "Text", id: "text", numeric: false },
                    {
                      label: "Value",
                      id: "val",
                      numeric: true,
                    },
                    {
                      label: "BS Remark",
                      id: "bsRemark",
                      numeric: false,
                    },
                    {
                      label: "IT Remark",
                      id: "itRemark",
                      numeric: false,
                    },
                  ]}
                />
              </AdminProtectedRoute>
            }
          />

          <Route
            path="/admin/fee-class-matrices"
            element={
              <AdminProtectedRoute>
                <AdminEntityTable
                  title={"Fee Class Matrices"}
                  endpoint="/fee-class-matrices"
                  columns={[
                    {
                      label: "BC",
                      id: "bookingCenter.text",
                      numeric: false,
                    },
                    {
                      label: "SM",
                      id: "serviceModel.text",
                      numeric: false,
                    },
                    {
                      label: "FT",
                      id: "feeType.text",
                      numeric: false,
                    },
                    {
                      label: "SP",
                      id: "servicePackage.text",
                      numeric: false,
                    },
                    {
                      label: "BM",
                      id: "brokerageModel.text",
                      numeric: false,
                    },
                    {
                      label: "IS",
                      id: "investmentStrategy.text",
                      numeric: false,
                    },
                    {
                      label: "FC",
                      id: "feeClass.text",
                      numeric: false,
                    },
                    {
                      label: "Amount",
                      id: "amount",
                      numeric: true,
                    },
                    {
                      label: "Sec. Doc.",
                      id: "sectionDoc",
                      numeric: false,
                    },
                    {
                      label: "BS Remark",
                      id: "bsRemark",
                      numeric: false,
                    },
                    {
                      label: "IT Remark",
                      id: "itRemark",
                      numeric: false,
                    },
                  ]}
                />
              </AdminProtectedRoute>
            }
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthenticatedTemplate>
    </React.Fragment>
  );
};
