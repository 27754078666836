import React, { useEffect, useState } from "react";
import { StyledInputWrapper } from "../../index.style";
import Content from "../../../../../../components/Content";
import Header from "../../../../../../components/Header";
import { defaultNumberTo, formatNumber } from "../../../../../../utils/numbers";
import NumberField from "components/NumberField/NumberField";
import { useValidateForm } from "../../../../../../hooks/useValidateForm";
import AppContext, { AppContextTypes } from "app/index.ctx";

const BankServiceFeeFields = (): React.ReactElement => {
  const [bsFeeERROR, setBsFeeError] = useState(false);
  const {
    bsFeeFlatAmount,
    setBsFeeFlatAmount,
    outputCurrency,
    bankServiceFee,
    bcCurrency,
    bsfAmount,
    exchangeRate,
    bsfDiscount,
  } = React.useContext(AppContext) as AppContextTypes;

  const {
    forceCalculateButtonToDisabled,
    removeForceCalculateButtonToDisabled,
  } = useValidateForm();

  useEffect(() => {
    if (bsfDiscount && bsFeeFlatAmount && bsfDiscount > 0) {
      setBsFeeError(true);
      forceCalculateButtonToDisabled("bsFeeError");
    } else {
      setBsFeeError(false);
      removeForceCalculateButtonToDisabled("bsFeeError");
    }
  }, [bsfDiscount, bsFeeFlatAmount]);

  const onAmountChanged = (value: string) => {
    if (setBsFeeFlatAmount) {
      setBsFeeFlatAmount(
        isNaN(parseFloat(value)) ? undefined : parseFloat(value)
      );
    }
  };

  if (bankServiceFee === 0) return <React.Fragment />;

  return (
    <React.Fragment>
      <Content>
        <Header title="Bank service fee fields" />
      </Content>
      <StyledInputWrapper>
        <NumberField
          name="flat-fee-amount"
          value={bsFeeFlatAmount}
          onChange={onAmountChanged}
          label="Flat fee amount"
          startAdornment={outputCurrency}
          error={bsFeeERROR}
          helperText={
            (bsFeeERROR &&
              "The flat fee entered is higher than the standard fee.This would be an individual surcharge and is not allowed.Please correct the flat fee") ||
            undefined
          }
          min={0}
        />
      </StyledInputWrapper>

      <StyledInputWrapper>
        <NumberField
          name="bank-service-fee-bc"
          label={"Bank service fee in " + bcCurrency}
          variant="filled"
          value={formatNumber(Number(isNaN(bsfAmount ?? 0) ? 0 : bsfAmount), {
            decimals: 2,
          })}
          disabled
          startAdornment={bcCurrency}
          min={0}
        />

        {outputCurrency !== bcCurrency && outputCurrency !== "" ? (
          <NumberField
            name="bank-service-fee-out"
            label={"Bank service fee in " + outputCurrency}
            variant="filled"
            value={formatNumber(
              Number(
                Math.ceil(
                  Number(
                    defaultNumberTo(bsfAmount, 0) /
                      defaultNumberTo(exchangeRate, 1)
                  )
                )
              ),
              { decimals: 2 }
            )}
            startAdornment={outputCurrency}
            disabled
            min={0}
          />
        ) : (
          <React.Fragment />
        )}
        <NumberField
          name="bank-service-fee-discount"
          label={"Bank service fee discount"}
          variant="filled"
          value={formatNumber(Number(bsfDiscount), { decimals: 2 })}
          startAdornment={outputCurrency}
          disabled
          min={0}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default BankServiceFeeFields;
