import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import {
  defaultNumberTo,
  formatNumber,
} from "../../../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const NumberOfTx = (): React.ReactElement => {
  const {
    investmentAmount,
    transactionCostConstants,
    trcAvgTxSize,
    setNoTxLow,
    setNoTxMedium,
    setNoTxHigh,
    exchangeRate,
  } = React.useContext(AppContext) as AppContextTypes;

  const opk15 = transactionCostConstants?.find(
    (tcc) => tcc.code.toLowerCase() === "OPk_15".toLowerCase()
  )?.val as number;
  const opk16 = transactionCostConstants?.find(
    (tcc) => tcc.code.toLowerCase() === "OPk_16".toLowerCase()
  )?.val as number;
  const opk17 = transactionCostConstants?.find(
    (tcc) => tcc.code.toLowerCase() === "OPk_17".toLowerCase()
  )?.val as number;

  const investmentAmountConverted = investmentAmount
    ? Number((investmentAmount as number) * defaultNumberTo(exchangeRate, 1))
    : 0;

  const noTxLow = Math.round(
    ((2 * investmentAmountConverted) / (trcAvgTxSize as number)) * opk15
  );

  const noTxMedium = Math.round(
    ((2 * investmentAmountConverted) / (trcAvgTxSize as number)) * opk16
  );
  const noTxHigh = Math.round(
    ((2 * investmentAmountConverted) / (trcAvgTxSize as number)) * opk17
  );

  React.useEffect(() => {
    if (setNoTxLow) {
      setNoTxLow(noTxLow);
    }
    if (setNoTxMedium) {
      setNoTxMedium(noTxMedium);
    }
    if (setNoTxHigh) {
      setNoTxHigh(noTxHigh);
    }
  }, [noTxLow, noTxMedium, noTxHigh]);

  if (!investmentAmount) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="trc-no-tx-low"
          name="trc-no-tx-low"
          label="TRC_No. of Tx Low"
          variant="filled"
          value={formatNumber(noTxLow, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="trc-no-tx-medium"
          name="trc-no-tx-medium"
          label="TRC_No. of Tx Medium"
          variant="filled"
          value={formatNumber(noTxMedium, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="trc-no-tx-high"
          name="trc-no-tx-high"
          label="TRC_No. of Tx High"
          variant="filled"
          value={formatNumber(noTxHigh, { decimals: 2 })}
          InputProps={{ readOnly: true }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default NumberOfTx;
