import React from "react";
import { StyledTextInput } from "./index.style";
import { InputAdornment } from "@mui/material";
import { NumericFormat } from "react-number-format";

type NumberFieldProps = {
  name: string;
  label: string;
  value?: string | number;
  disabled?: boolean;
  onChange?: (value: string) => void;
  startAdornment?: string;
  endAdornment?: string;
  variant?: string;
  required?: boolean;
  inputRef?: (input: any) => void;
  min?: number;
  error?: boolean;
  helperText?: string;
};

const NumberField = (props: NumberFieldProps) => {
  const {
    value,
    min,
    name,
    disabled,
    endAdornment,
    startAdornment,
    variant,
    onChange,
    inputRef,
    ...otherProps
  } = props;

  return (
    <NumericFormat
      id={name}
      name={name}
      value={value ?? ""}
      onValueChange={(values) => {
        onChange && onChange(values.value);
      }}
      customInput={StyledTextInput as any}
      thousandSeparator={"'"}
      isAllowed={(values) => {
        const { floatValue } = values;
        return (floatValue ?? 0) >= (min ?? Number.MIN_SAFE_INTEGER);
      }}
      disabled={disabled}
      {...{
        ...otherProps,
        variant: variant ?? "outlined",
        inputRef: (input: any) => inputRef && inputRef(input),
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">{endAdornment}</InputAdornment>
          ),
        },
      }}
    />
  );
};

export default NumberField;
