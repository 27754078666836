// action types
import ServiceModel from "../models/ServiceModel";

export const SERVICE_MODEL_GET_API_REQUEST = "SERVICE_MODEL_GET_API_REQUEST";
export const SERVICE_MODEL_GET_API_SUCCESS = "SERVICE_MODEL_GET_API_SUCCESS";
export const SERVICE_MODEL_GET_API_FAILURE = "SERVICE_MODEL_GET_API_FAILURE";
export const SERVICE_MODEL_SELECTED = "SERVICE_MODEL_SELECTED";
export const SERVICE_MODEL_CHANGED = "SERVICE_MODEL_CHANGED";
export const SERVICE_MODEL_CLEAR = "SERVICE_MODEL_CLEAR";

export interface ServiceModelState {
  serviceModels: ServiceModel[];
  selected?: number;
}

// reducer with initial state
const initialState: ServiceModelState = {
  serviceModels: [],
  selected: undefined,
};

interface Action {
  type: string;
  serviceModels: ServiceModel[];
  selected?: number;
}

export default function serviceModelsReducer(
  state: ServiceModelState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case SERVICE_MODEL_GET_API_SUCCESS:
      newState.serviceModels = action.serviceModels;
      break;
    case SERVICE_MODEL_GET_API_FAILURE:
      break;
    case SERVICE_MODEL_CHANGED:
      newState.selected = action.selected;
      break;
    case SERVICE_MODEL_CLEAR:
      newState = initialState;
      break;
  }

  return newState;
}
