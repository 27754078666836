import { takeLatest, call, put } from "redux-saga/effects";
import {
    CURRENCY_GET_API_REQUEST,
    CURRENCY_GET_API_SUCCESS,
    CURRENCY_GET_API_FAILURE
} from "../reducers/currencies-reducer";
import api, { GetResponse } from "../api";
import Currency from "../models/Currency";

function getCurrencies(): Promise<GetResponse<Currency>> {
    return api.get<Currency>({
        endpoint: '/currencies'
    });
}

function* retrieveCurrencies() {
    try {
        const response: GetResponse<Currency> = yield call(getCurrencies);
        yield put({ type: CURRENCY_GET_API_SUCCESS, currencies: response.entities });
    } catch (error) {
        yield put({ type: CURRENCY_GET_API_FAILURE, error });
    }
}

export default function* currenciesSaga() {
    yield takeLatest(CURRENCY_GET_API_REQUEST, retrieveCurrencies);
}
