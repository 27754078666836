import React from "react";
import Header from "../../../../components/Header";
import { Grid } from "@mui/material";
import { formatNumber } from "../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const TransactionRelatedCosts = (): React.ReactElement => {
  const { output } = React.useContext(AppContext) as AppContextTypes;

  if (output === undefined) return <React.Fragment />;
  if (output.transactionRelatedCost === undefined) return <React.Fragment />;
  if (output.transactionRelatedCost === null) return <React.Fragment />;

  return (
    <React.Fragment>
      <Grid container marginTop={3}>
        <Header title={output.transactionRelatedCost} />
      </Grid>
      <Grid item xs={12}>
        {output.indicativeTransactionRelatedCostLowLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.indicativeTransactionRelatedCostLowLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(
                parseFloat(output.indicativeTransactionRelatedCostLowValue),
                { decimals: 3 }
              )}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.transactionRelatedCostSpecCondLowLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.transactionRelatedCostSpecCondLowLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(
                parseFloat(output.transactionRelatedCostSpecCondLowValue),
                { decimals: 2 }
              )}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.transactionRelatedCostTotalLowLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.transactionRelatedCostTotalLowLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(
                  parseFloat(output.transactionRelatedCostTotalLowValue),
                  { decimals: 0 }
                )}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.indicativeTransactionRelatedCostMediumLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.indicativeTransactionRelatedCostMediumLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(
                parseFloat(output.indicativeTransactionRelatedCostMediumValue),
                { decimals: 3 }
              )}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.transactionRelatedCostSpecCondMediumLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.transactionRelatedCostSpecCondMediumLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(
                parseFloat(output.transactionRelatedCostSpecCondMediumValue),
                { decimals: 3 }
              )}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.transactionRelatedCostTotalMediumLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.transactionRelatedCostTotalMediumLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(
                  parseFloat(output.transactionRelatedCostTotalMediumValue),
                  { decimals: 0 }
                )}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.indicativeTransactionRelatedCostHighLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.indicativeTransactionRelatedCostHighLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(
                parseFloat(output.indicativeTransactionRelatedCostHighValue),
                { decimals: 3 }
              )}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.transactionRelatedCostSpecCondHighLabel ? (
          <div style={{ width: "85%" }}>
            <span>{output.transactionRelatedCostSpecCondHighLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(
                parseFloat(output.transactionRelatedCostSpecCondHighValue),
                { decimals: 2 }
              )}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.transactionRelatedCostTotalHighLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.transactionRelatedCostTotalHighLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(
                  parseFloat(output.transactionRelatedCostTotalHighValue),
                  { decimals: 0 }
                )}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.trcMasDiscountAdv ? (
          <div style={{ width: "100%" }}>
            <span>{output.trcMasDiscountAdv}</span>
          </div>
        ) : (
          <React.Fragment />
        )}

        {output.transactionRelatedCostTotalLabel ? (
          <div style={{ width: "100%" }}>
            <b>
              <span>{output.transactionRelatedCostTotalLabel}</span>
              <span style={{ float: "right" }}>
                {formatNumber(
                  parseFloat(output.transactionRelatedCostTotalValue),
                  { decimals: 3 }
                )}
              </span>
            </b>
          </div>
        ) : (
          <React.Fragment />
        )}
      </Grid>
    </React.Fragment>
  );
};

export default TransactionRelatedCosts;
