import {styled} from "@mui/material/styles";
import {TextField} from "@mui/material";

export const StyledTextInput = styled(TextField)`
  width: 100% !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
`;