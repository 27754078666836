import { takeLatest, call, put } from "redux-saga/effects";
import {
  RM_LOCATION_GET_API_REQUEST,
  RM_LOCATION_GET_API_SUCCESS,
  RM_LOCATION_GET_API_FAILURE,
} from "../reducers/rm-locations-reducer";
import api, { GetResponse } from "../api";
import RMLocation from "../models/RMLocation";

function getRMLocations(id: string | number): Promise<GetResponse<RMLocation>> {
  return api.get<RMLocation>({
    endpoint: "/booking-centers/" + id + "/rm-locations",
  });
}

function* retrieveRMLocations(action: { type: string; id1: string | number }) {
  try {
    const response: GetResponse<RMLocation> = yield call(
      getRMLocations,
      action.id1
    );
    yield put({
      type: RM_LOCATION_GET_API_SUCCESS,
      rmLocations: response.entities,
    });
  } catch (error) {
    yield put({ type: RM_LOCATION_GET_API_FAILURE, error });
  }
}

export default function* rmLocationsSaga() {
  yield takeLatest(RM_LOCATION_GET_API_REQUEST, retrieveRMLocations);
}
