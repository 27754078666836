// action types
import FeeTypeMatrix from "../models/FeeTypeMatrix";

export const FEE_TYPE_MATRIX_GET_API_REQUEST =
  "FEE_TYPE_MATRIX_GET_API_REQUEST";
export const FEE_TYPE_MATRIX_GET_API_SUCCESS =
  "FEE_TYPE_MATRIX_GET_API_SUCCESS";
export const FEE_TYPE_MATRIX_GET_API_FAILURE =
  "FEE_TYPE_MATRIX_GET_API_FAILURE";
export const FEE_TYPE_MATRIX_SELECTED = "FEE_TYPE_MATRIX_SELECTED";
export const FEE_TYPE_MATRIX_RESET = "FEE_TYPE_MATRIX_RESET";

export interface FeeTypeMatrixState {
  feeTypeMatrices: FeeTypeMatrix[];
  selected?: number;
}

// reducer with initial state
const initialState: FeeTypeMatrixState = {
  feeTypeMatrices: [],
  selected: undefined,
};

interface Action {
  type: string;
  feeTypeMatrices: FeeTypeMatrix[];
  selected?: number;
}

export default function feeTypeMatricesReducer(
  state: FeeTypeMatrixState = initialState,
  action: Action
) {
  let newState = { ...state };

  switch (action.type) {
    case FEE_TYPE_MATRIX_GET_API_SUCCESS:
      newState.feeTypeMatrices = action.feeTypeMatrices;
      break;
    case FEE_TYPE_MATRIX_GET_API_FAILURE:
      break;
    case FEE_TYPE_MATRIX_SELECTED:
      newState.selected = action.selected;
      break;
    case FEE_TYPE_MATRIX_RESET:
      newState.feeTypeMatrices = [];
      newState.selected = undefined;
      break;
  }

  return newState;
}
