import React from "react";
import { StyledDropdownInput, StyledInputWrapper } from "../../index.style";
import Content from "../../../../../../components/Content";
import Header from "../../../../../../components/Header";
import { SelectChangeEvent } from "@mui/material";
import {
  specialConditionTypes,
  yesNoChoice,
} from "../../../../../../app/costants";
import NumberField from "../../../../../../components/NumberField/NumberField";
import AppContext, { AppContextTypes } from "app/index.ctx";

const CustodyServiceFeeFields = (): React.ReactElement => {
  const {
    csfMinFeeWaived,
    setCSFMinFeeWaived,
    csfSpecialCondition,
    setCSFSpecialCondition,
    csfSpecialConditionAmount,
    setCSFSpecialConditionAmount,
    custodyServiceFee,
  } = React.useContext(AppContext) as AppContextTypes;

  const disabled = csfSpecialCondition === specialConditionTypes[0].value;

  const onSpecialConditionTypeSelected = (
    event: SelectChangeEvent<string | number>
  ) => {
    if (setCSFSpecialCondition) {
      setCSFSpecialCondition(event.target.value as string);
    }
    setCSFSpecialConditionAmount?.(undefined);
  };

  const onAmountChanged = (value: string) => {
    if (setCSFSpecialConditionAmount) {
      setCSFSpecialConditionAmount(parseFloat(value));
    }
  };

  const onMinFeeWaivedChanged = (event: SelectChangeEvent<string | number>) => {
    if (setCSFMinFeeWaived) {
      setCSFMinFeeWaived(
        (event.target.value as string) === yesNoChoice[1].value
      );
    }
  };

  if (custodyServiceFee === 0) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <Content>
        <Header title="Custody service fee fields" />
      </Content>
      <StyledInputWrapper>
        <StyledDropdownInput
          label="Special condition type"
          name="special-condition-type"
          items={specialConditionTypes}
          variant="outlined"
          onChange={onSpecialConditionTypeSelected}
          defaultValue={csfSpecialCondition}
        />

        <NumberField
          label="Special condition amount"
          name="special-condition-amount"
          variant="outlined"
          value={disabled ? "" : csfSpecialConditionAmount}
          onChange={onAmountChanged}
          endAdornment={disabled ? "" : csfSpecialCondition}
          disabled={disabled}
          min={0}
        />

        <StyledDropdownInput
          label="Minimum fee waived?"
          name="minimum-fee-waived"
          items={yesNoChoice}
          variant="outlined"
          defaultValue={
            !(csfMinFeeWaived as boolean)
              ? yesNoChoice[0].value
              : yesNoChoice[1].value
          }
          onChange={onMinFeeWaivedChanged}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default CustodyServiceFeeFields;
