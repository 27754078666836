import { takeLatest, call, put } from "redux-saga/effects";
import {
  OUTPUT_POST_API_REQUEST,
  OUTPUT_POST_API_SUCCESS,
  OUTPUT_POST_API_FAILURE,
} from "../reducers/output-reducer";

import api, { PostResponse } from "../api";
import Output from "../models/Output";
import OutputRequest from "../models/OutputRequest";

function postOutput(output: OutputRequest): Promise<PostResponse<Output>> {
  return api.post<Output>({
    endpoint: "/output",
    data: output
  });
}

function* calculateOutput(action: { type: string; output: OutputRequest }) {
  try {
    const response: PostResponse<Output> = yield call(
      postOutput,
      action.output
    );
    yield put({ type: OUTPUT_POST_API_SUCCESS, output: response.data });
  } catch (error) {
    yield put({ type: OUTPUT_POST_API_FAILURE, error });
  }
}

export default function* outputSaga() {
  yield takeLatest(OUTPUT_POST_API_REQUEST, calculateOutput);
}
