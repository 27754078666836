import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import { Box } from "@mui/system";

export const StyledEditPage = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100vw;
`;

export interface EditPageLayoutProps {
  children: ReactNode;
}

export const EditPageLayout = (props: EditPageLayoutProps) => {
  const { children } = props;

  return <StyledEditPage>{children}</StyledEditPage>;
};
