/* istanbul ignore file */
import { takeLatest, call, put } from "redux-saga/effects";
import {
  INVESTMENT_STRATEGY_GET_API_REQUEST,
  INVESTMENT_STRATEGY_GET_API_SUCCESS,
  INVESTMENT_STRATEGY_GET_API_FAILURE,
  INVESTMENT_STRATEGY_FULL_LIST_GET_API_REQUEST,
  INVESTMENT_STRATEGY_FULL_LIST_GET_API_SUCCESS,
  INVESTMENT_STRATEGY_FULL_LIST_GET_API_FAILURE,
} from "../reducers/investment-strategies-reducer";
import api, { GetResponse } from "../api";
import InvestmentStrategy from "../models/InvestmentStrategy";

function getInvestmentStrategies(
  serviceModelId: string | number
): Promise<GetResponse<InvestmentStrategy>> {
  return api.get<InvestmentStrategy>({
    endpoint: "/service-models/" + serviceModelId + "/investment-strategies",
  });
}

function getInvestmentStrategiesFullList(): Promise<
  GetResponse<InvestmentStrategy>
> {
  return api.get<InvestmentStrategy>({
    endpoint: "/investment-strategies",
  });
}

function* retrieveInvestmentStrategies(action: {
  type: string;
  id1: string | number;
  id2: string | number;
}) {
  try {
    const response: GetResponse<InvestmentStrategy> = yield call(
      getInvestmentStrategies,
      action.id1
    );
    yield put({
      type: INVESTMENT_STRATEGY_GET_API_SUCCESS,
      investmentStrategies: response?.entities ?? [],
    });
  } catch (error) {
    yield put({ type: INVESTMENT_STRATEGY_GET_API_FAILURE, error });
  }
}

function* retrieveInvestmentStrategiesFullList() {
  try {
    const response: GetResponse<InvestmentStrategy> = yield call(
      getInvestmentStrategiesFullList
    );
    yield put({
      type: INVESTMENT_STRATEGY_FULL_LIST_GET_API_SUCCESS,
      fullList: response?.entities ?? [],
    });
  } catch (error) {
    yield put({ type: INVESTMENT_STRATEGY_FULL_LIST_GET_API_FAILURE, error });
  }
}

export default function* investmentStrategiesSaga() {
  yield takeLatest(
    INVESTMENT_STRATEGY_GET_API_REQUEST,
    retrieveInvestmentStrategies
  );
  yield takeLatest(
    INVESTMENT_STRATEGY_FULL_LIST_GET_API_REQUEST,
    retrieveInvestmentStrategiesFullList
  );
}
