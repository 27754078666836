import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { MenuDiv, MenuWrapper, StyledPersonOutline } from "./index.style";
import logo from "../../logo.svg";
import icon from "../../assets/icon.svg";
import { useAuth } from "react-oidc-context";
import api from "../../api";
import { getAccessToken, parseJwt } from "../../utils/jwtUtils";

const AppHeader = (props: { authenticated: boolean }) => {
  const auth = useAuth();
  const { authenticated } = props;

  const isSmall = window.matchMedia("(max-width: 448px)").matches;
  const token = parseJwt(getAccessToken() ?? "");

  const menu = [
    {
      icon: <StyledPersonOutline sx={{ my: 1, mx: 1.5 }} />,
      id: "user",
      submenu: [
        {
          id: "logout",
          title: "Logout",
          click: () => {
            api
              .post({
                endpoint: "/pdf/cleanup",
                data: token?.upn ?? "",
                headers: {
                  "Content-Type": "text/plain",
                },
              })
              .then(() => {
                auth.signoutRedirect().catch((e) => {
                  console.log(e);
                });
              });
          },
        },
      ],
    },
  ];

  const addMenuOpenedClass = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.querySelector(".submenu")?.classList.add("opened");
  };

  const removeMenuOpenedClass = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.querySelector(".submenu")?.classList.remove("opened");
  };

  const backToHome = () => {
    window.location.href = authenticated ? "/" : "/login";
  };

  return (
    <AppBar
      position="absolute"
      elevation={0}
      style={{ paddingRight: "24px", paddingLeft: "24px" }}
    >
      <Toolbar sx={{ flexWrap: "wrap" }}>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, cursor: "pointer" }}
          onClick={backToHome}
        >
          <span style={{ borderRight: "2px solid white", paddingRight: "5px" }}>
            <img src={logo} className="App-logo" alt="logo" />
          </span>
          <span style={{ paddingLeft: "5px" }}>
            <img src={icon} className="App-icon" alt="icon" />
          </span>
          <span style={{ paddingLeft: "5px" }}>Portfolio Cost Simulation</span>
        </Typography>
        {authenticated && (
          <nav style={{ position: "relative" }}>
            {!isSmall && (
              <div>
                <span
                  style={{
                    position: "absolute",
                    right: "50px",
                    top: "12px",
                    fontSize: "12px",
                  }}
                >
                  {token?.upn ?? ""}
                </span>
              </div>
            )}

            {menu.map((item) => (
              <MenuWrapper
                onMouseEnter={addMenuOpenedClass}
                onMouseLeave={removeMenuOpenedClass}
                key={"menu-item-" + item.id}
              >
                {item.icon}
                {item.submenu ? (
                  <MenuDiv className={"submenu"}>
                    {item.submenu.map((subItem) => (
                      <div
                        onClick={subItem.click}
                        key={"submenu-item-" + subItem.id}
                        role={"button"}
                      >
                        {subItem.title}
                      </div>
                    ))}
                  </MenuDiv>
                ) : (
                  <React.Fragment />
                )}
              </MenuWrapper>
            ))}
            {isSmall && (
              <div>
                <span
                  style={{
                    position: "absolute",
                    left: "50px",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {token?.upn ?? ""}
                </span>
              </div>
            )}
          </nav>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
