import React from "react";
import Content from "../../../../../../components/Content";
import Header from "../../../../../../components/Header";
import { StyledDropdownInput, StyledInputWrapper } from "../../index.style";
import ServiceFieldsProperties, {
  mapDispatchToProps,
  mapStateToProps,
} from "./index.props";
import { DropdownHandles } from "../../../../../../components/Dropdown";
import { connect } from "react-redux";
import { SelectChangeEvent } from "@mui/material";
import { resetSlaveDropdown } from "../../utils";
import store from "../../../../../../store";
import BookingCenter from "../../../../../../models/BookingCenter";
import ServiceModel from "../../../../../../models/ServiceModel";
import ServicePackage from "../../../../../../models/ServicePackage";
import RMLocation from "../../../../../../models/RMLocation";
import { yesNoChoice } from "../../../../../../app/costants";
import AppContext, { AppContextTypes } from "app/index.ctx";

export function returnEmptyArrayOnUndefined(value: any[] | undefined): any[] {
  return value ?? [];
}

export const ServiceFields = (
  props: ServiceFieldsProperties
): React.ReactElement => {
  const { bookingCenters, rmLocations, serviceModels, servicePackages } = props;

  const {
    onRequestGetBookingCenters,
    onRequestGetRMLocations,
    onRequestGetServiceModels,
    onRequestGetServicePackages,
    onBookingCenterSelected,
    onRMLocationSelected,
    onServiceModelSelected,
    onRequestClearServicePackage,
    onRequestClearRMLocations,
    onRequestClearServiceModels,
  } = props;

  let rmLocationInputRef: any;

  const rmLocationsRef = React.createRef<DropdownHandles>(),
    serviceModelsRef = React.createRef<DropdownHandles>(),
    servicePackagesRef = React.createRef<DropdownHandles>(),
    bespokeRef = React.createRef<DropdownHandles>();

  const {
    setBcCurrency,
    rmLocation,
    setRMLocation,
    serviceModel,
    setServiceModel,
    bookingCenter,
    setBookingCenter,
    servicePackage,
    setServicePackage,
    bespoke,
    setBespoke,
    outputCurrency,
    setExchangeRate,
  } = React.useContext(AppContext) as AppContextTypes;

  React.useEffect(() => {
    onRequestGetBookingCenters();
  }, []);

  // This piece of code does the autofocus after
  // BC has been selected to RM Location as per OCC-244
  React.useEffect(() => {
    if (!rmLocations || rmLocations.length === 0) {
      return;
    }

    if (rmLocation) {
      return;
    }

    rmLocationInputRef?.focus();
    rmLocationsRef.current?.setOpen(true);
  }, [rmLocation, rmLocations]);

  const setExchangeRateTo1IfBcCurrencyAndOutputCurrencyEqual = (
    currency: string
  ) => {
    if (currency !== outputCurrency) {
      if (setExchangeRate && outputCurrency) {
        setExchangeRate(undefined);
      }
      return;
    }

    if (setExchangeRate) {
      setExchangeRate(1.0);
    }
  };

  const onBookingCenterChange = (
    evt: SelectChangeEvent<string | number>
  ): void => {
    const bcId = parseInt(evt.target.value as string);

    const selectedBookingCenter: BookingCenter | undefined = store
      .getState()
      .bookingCentersReducer.bookingCenters.find((bc) => bc.id === bcId);

    if (setBcCurrency) {
      const currency = selectedBookingCenter?.currency.code as string;
      setBcCurrency(currency);
      setExchangeRateTo1IfBcCurrencyAndOutputCurrencyEqual(currency);
    }
    if (setBookingCenter) {
      setBookingCenter(selectedBookingCenter as BookingCenter);
    }

    resetSlaveDropdown(rmLocationsRef, onRequestClearRMLocations);

    if (isNaN(bcId)) return;

    onRequestGetRMLocations(bcId);
    onBookingCenterSelected(bcId);
  };

  const onRMLocationChange = (
    evt: SelectChangeEvent<string | number>
  ): void => {
    const rmlId = parseInt(evt.target.value as string);

    const selectedRMLocation: RMLocation | undefined = store
      .getState()
      .rmLocationsReducer.rmLocations.find((rml) => rml.id === rmlId);

    if (setRMLocation) {
      setRMLocation(selectedRMLocation as RMLocation);
    }

    resetSlaveDropdown(serviceModelsRef, onRequestClearServiceModels);

    if (isNaN(rmlId)) return;

    onRequestGetServiceModels(bookingCenter?.id, rmlId);
    onRMLocationSelected(rmlId);
  };

  const onServiceModelChange = (
    evt: SelectChangeEvent<string | number>
  ): void => {
    const smId = parseInt(evt.target.value as string);
    const selectedServiceModel: ServiceModel | undefined = store
      .getState()
      .serviceModelsReducer.serviceModels.find((sm) => sm.id === smId);

    if (setServiceModel) {
      setServiceModel(selectedServiceModel as ServiceModel);
    }

    if (!selectedServiceModel?.isDM)
      bespokeRef.current?.setValue(yesNoChoice[0].value);

    resetSlaveDropdown(servicePackagesRef, onRequestClearServicePackage);

    if (isNaN(smId)) return;
    //passare rmLocation
    onRequestGetServicePackages(bookingCenter?.id, smId, rmLocation?.code);
    onServiceModelSelected(smId);
  };

  const onServicePackageChange = (
    evt: SelectChangeEvent<string | number>
  ): void => {
    const spId = parseInt(evt.target.value as string);

    const selectedServicePackage: ServicePackage | undefined = store
      .getState()
      .servicePackagesReducer.servicePackages.find((sp) => sp.id === spId);

    if (setServicePackage) {
      setServicePackage(selectedServicePackage as ServicePackage);
    }
  };

  const onBespokeChanged = (evt: SelectChangeEvent<string | number>): void => {
    const bespokeValue = (evt.target.value as string) === yesNoChoice[1].value;
    if (setBespoke) {
      setBespoke(bespokeValue);
    }
  };

  return (
    <React.Fragment>
      <Content>
        <Header title="Service fields" />
      </Content>
      <StyledInputWrapper>
        <StyledDropdownInput
          label="Booking center"
          name="booking-center"
          items={returnEmptyArrayOnUndefined(bookingCenters)}
          variant="outlined"
          onChange={onBookingCenterChange}
          defaultValue={bookingCenter?.id}
          required
        />

        <StyledDropdownInput
          label="RM location"
          name="rm-location"
          items={returnEmptyArrayOnUndefined(rmLocations)}
          variant="outlined"
          onChange={onRMLocationChange}
          ref={rmLocationsRef}
          defaultValue={rmLocation?.id}
          required
          inputRef={(inputRef) => (rmLocationInputRef = inputRef)}
        />

        <StyledDropdownInput
          label="Service model"
          name="service-model"
          items={returnEmptyArrayOnUndefined(serviceModels)}
          variant="outlined"
          onChange={onServiceModelChange}
          ref={serviceModelsRef}
          defaultValue={serviceModel?.id}
          required
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <StyledDropdownInput
          label="Service package"
          name="service-package"
          items={returnEmptyArrayOnUndefined(servicePackages)}
          variant="outlined"
          ref={servicePackagesRef}
          onChange={onServicePackageChange}
          defaultValue={servicePackage?.id}
        />

        <StyledDropdownInput
          label="Bespoke?"
          name="bespoke"
          items={returnEmptyArrayOnUndefined(yesNoChoice)}
          variant="outlined"
          defaultValue={
            !(bespoke as boolean) ? yesNoChoice[0].value : yesNoChoice[1].value
          }
          onChange={onBespokeChanged}
          disabled={!serviceModel?.isDM}
          ref={bespokeRef}
          helperText="for discretionary mandates only"
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceFields);
