import { takeLatest, call, put } from "redux-saga/effects";
import {
    SERVICE_MODEL_GET_API_REQUEST,
    SERVICE_MODEL_GET_API_SUCCESS,
    SERVICE_MODEL_GET_API_FAILURE,
    SERVICE_MODEL_SELECTED,
    SERVICE_MODEL_CHANGED
} from "../reducers/service-models-reducer";
import api, { GetResponse } from "../api";
import ServiceModel from "../models/ServiceModel";

function getServiceModels(bookingCenterId: string | number, rmLocationId: string | number): Promise<GetResponse<ServiceModel>> {
    return api.get<ServiceModel>({
        endpoint: '/booking-centers/' + bookingCenterId + '/rm-locations/' + rmLocationId + '/service-models'
    });
}

function* retrieveServiceModels(action: { type: string, id1: string | number, id2: string | number }) {
    try {
        const response: GetResponse<ServiceModel> = yield call(getServiceModels, action.id1, action.id2);
        yield put({ type: SERVICE_MODEL_GET_API_SUCCESS, serviceModels: response.entities });
    } catch (error) {
        yield put({ type: SERVICE_MODEL_GET_API_FAILURE, error });
    }
}

function* setSelected(action: { type: string, id1: string | number, }) {
    yield put( { type: SERVICE_MODEL_CHANGED, selected: action.id1 } )
}

export default function* serviceModelsSaga() {
    yield takeLatest(SERVICE_MODEL_GET_API_REQUEST, retrieveServiceModels);
    yield takeLatest(SERVICE_MODEL_SELECTED, setSelected);
}
