import { styled } from "@mui/material/styles";
import Content from "../../../../components/Content";
import { TextField } from "@mui/material";
import Dropdown from "../../../../components/Dropdown";
import Section from "../../../../components/Section";

export const StyledSection = styled(Section)`
  overflow-y: auto;
  height: 1200px;
  padding: 10px;
`;

export const StyledInputWrapper = styled(Content)`
  display: flex;
  ${(props) => props.theme.breakpoints.down("md")} {
    display: block;
  }
`;

export const StyledTextInput = styled(TextField)`
  width: 100% !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
`;

export const StyledDropdownInput = styled(Dropdown)`
  width: 100% !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
`;
