import { Grid } from "@mui/material";
import { FormFieldLabel } from "../../../../hook-form/FormFieldLabel";
import { FormInput } from "../../../../hook-form/FormInput";
import { FormSelect } from "../../../../hook-form/FormSelect";
import FeeClassMatrix from "../../../../models/FeeClassMatrix";
import { FormCurrencyInput } from "hook-form/FormCurrencyInput";

export interface ThirdRowFieldsProps {
  control: any;
  feeClassMatrix: FeeClassMatrix;
}

export const ThirdRowFields = (props: ThirdRowFieldsProps) => {
  const { feeClassMatrix, control } = props;

  return (
    <>
      <Grid item xs={4}>
        <FormSelect
          name="feeClass"
          label={<FormFieldLabel isRequired>Fee Class</FormFieldLabel>}
          control={control}
          options={[
            {
              label: feeClassMatrix?.feeClass.code ?? "",
              value: feeClassMatrix?.feeClass.text ?? "",
            },
          ]}
          disabled
          rules={{ required: "Fee Class is required." }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormCurrencyInput
          control={control}
          name="amount"
          label={<FormFieldLabel isRequired>Amount</FormFieldLabel>}
          fullWidth
          autoComplete="off"
          rules={{ required: "Amount is required." }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormInput
          control={control}
          name="docSection"
          label={<FormFieldLabel isRequired>Doc Section</FormFieldLabel>}
          fullWidth
          disabled
          rules={{ required: "Doc Section is required." }}
        />
      </Grid>
    </>
  );
};
