import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export interface SpinnerProps {
  height?: number;
  width?: number;
}

export const Spinner = (props: SpinnerProps) => {
  const { height, width } = props;

  return (
    <Box
      sx={{
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress data-testid="spinner" />
    </Box>
  );
};
