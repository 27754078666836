import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import type { TextFieldProps } from "@mui/material";
import type { UseControllerProps } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types/fields";

export type FormInputProps<T extends FieldValues = FieldValues> = Omit<
  UseControllerProps<T>,
  "defaultValue"
> &
  Omit<TextFieldProps, "onBlur" | "onChange" | "value" | "error">;

export const FormInput = <T extends FieldValues = FieldValues>(
  props: FormInputProps<T>
) => {
  const { name, control, defaultValue, rules, shouldUnregister, ...restProps } =
    props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <TextField
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={!!error}
          helperText={error?.message ?? null}
          {...restProps}
        />
      )}
    />
  );
};
