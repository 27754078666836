import React from "react";
import { Grid } from "@mui/material";
import Header from "../../../../components/Header";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Remarks = (): React.ReactElement => {
  const { output } = React.useContext(AppContext) as AppContextTypes;

  if (output === undefined) return <React.Fragment />;
  if (output.remarksValue === undefined) return <React.Fragment />;
  if (output.remarksValue === null) return <React.Fragment />;
  if (output.remarksValue === "") return <React.Fragment />;

  return (
    <React.Fragment>
      <Grid container marginTop={3}>
        <Header title={output.remarksLabel} />
      </Grid>
      <Grid item xs={12}>
        <p style={{ wordBreak: "break-all" }}>{output.remarksValue}</p>
      </Grid>
    </React.Fragment>
  );
};

export default Remarks;
