import React from "react";
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { StyledFormControl } from "./index.style";

export type DropdownItem = {
  label: string;
  value: string | number;
};

export interface DropdownProperties {
  label: string;
  items: DropdownItem[];
  variant?: "standard" | "outlined" | "filled";
  className?: string;
  name?: string;
  defaultValue?: string | number;
  onChange?: (evt: SelectChangeEvent<string | number>) => void;
  disabled?: boolean;
  helperText?: string;
  required?: boolean;
  inputRef?: (input: any) => void
}

export interface DropdownHandles {
  clear: () => void;
  setValue: (value: string | number) => void;
  setOpen: (o: boolean) => void;
}

const Dropdown = React.forwardRef(
  (
    props: DropdownProperties,
    ref: React.ForwardedRef<DropdownHandles>
  ): React.ReactElement => {
    const { label, items, variant, className, name, helperText, required, inputRef } = props;
    const { onChange, defaultValue, disabled } = props;
    const [value, setValue] = React.useState<string | number>(defaultValue || "");
    const [open, setOpenInternal] = React.useState(false);

    const handleChange = (event: SelectChangeEvent<string | number>) => {
      setValue(event.target.value as string);

      if (onChange) onChange(event);
    };

    React.useEffect(() => {
      if (value !== "" || items.length !== 1) {
        return;
      }

      handleChange({ target: { value: items[0].value } } as SelectChangeEvent<string | number>);
    }, [value, items])

    React.useImperativeHandle(
      ref,
      (): DropdownHandles => ({
        clear: () => {
          if (value === "") return;

          handleChange({ target: { value: "" } } as SelectChangeEvent<
            string | number
          >);
        },
        setValue: (new_value: string | number) => {
          handleChange({ target: { value: new_value } } as SelectChangeEvent<
            string | number
          >);
        },
        setOpen: (o: boolean) => {
          setOpenInternal(o);
        }
      })
    );

    return (
      <React.Fragment>
        <StyledFormControl
          variant={variant}
          className={className}
          disabled={items.length <= 0 || disabled}
        >
          <InputLabel id={name + "-label"} role="label" required={required}>
            {label}
          </InputLabel>
          <Select<string | number>
            labelId={name + "-label"}
            id={name}
            name={name}
            value={value}
            defaultValue={defaultValue}
            onChange={handleChange}
            label={label}
            required={required}
            inputRef={input => inputRef && inputRef(input)}
            open={open}
            onOpen={() => setOpenInternal(true)}
            onClose={()=> setOpenInternal(false)}
          >
            {items.length <= 0 ? (
              <MenuItem value="" />
            ) : (
              items.map((item: DropdownItem) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))
            )}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </StyledFormControl>
      </React.Fragment>
    );
  }
);

export default Dropdown;
