import * as React from "react";
import Box from "@mui/material/Box";
import TableBase from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Radio from "@mui/material/Radio";
import { visuallyHidden } from "@mui/utils";
import { formatNumber } from "../../utils/numbers";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  columns: {
    label: string;
    id: string;
    numeric: boolean;
  }[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, columns } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.numeric ? "right" : "left"}
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : "asc"}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {"sorted " + order + "ending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export type TableEntity = { id: string } & { [key: string]: any };

export type TableProps = {
  entities: TableEntity[];
  columns: {
    label: string;
    id: string;
    numeric: boolean;
  }[];
  onSelected?: (entity: TableEntity, selected: boolean) => void;
  onPageChange?: (page: number) => void;
  onSizeChange?: (size: number) => void;
  onSortChanged?: (sort: string, direction: Order) => void;
  totalEntities?: number;
  orderDefault?: Order;
  orderByDefault?: string;
};

export interface TableHandles {
  forceReset: () => void;
}

const Table = React.forwardRef(
  (props: TableProps, ref: React.ForwardedRef<any>): React.ReactElement => {
    const {
      entities,
      columns,
      onSelected,
      onPageChange,
      onSizeChange,
      totalEntities,
      onSortChanged,
      orderDefault,
      orderByDefault,
    } = props;

    const [order, setOrder] = React.useState<Order>(orderDefault ?? "asc");
    const [orderBy, setOrderBy] = React.useState(
      orderByDefault ?? columns[0].id
    );
    const [selected, setSelected] = React.useState<string>("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    React.useImperativeHandle(
      ref,
      (): TableHandles => ({
        forceReset: () => {
          setPage(0);
        },
      })
    );

    const handleRequestSort = (
      _event: React.MouseEvent<unknown>,
      property: any
    ) => {
      const isAsc = orderBy === property && order === "asc";
      const direction = !isAsc ? "asc" : "desc";
      setOrder(direction);
      setOrderBy(property);
      setPage(0);

      if (onSortChanged) {
        onSortChanged(property, direction);
      }
    };

    const handleClick = (
      _event: React.MouseEvent<unknown>,
      entity: TableEntity
    ) => {
      const removeSelection = selected === entity.id;

      if (removeSelection) {
        setSelected("");
      } else {
        setSelected(entity.id);
      }

      if (onSelected) {
        onSelected(entity, !removeSelection);
      }
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
      setPage(newPage);
      if (onPageChange) {
        onPageChange(newPage);
      }
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const size = parseInt(event.target.value, 10);

      setRowsPerPage(size);
      if (onSizeChange) {
        onSizeChange(size);
      }
      setPage(0);
      if (onPageChange) {
        onPageChange(0);
      }
    };

    const renderCell = (
      entity: TableEntity,
      column: { label: string; id: string; numeric: boolean }
    ) => {
      let value: string;

      if (column.id.includes(".")) {
        const ids = column.id.split(".");
        value = entity[ids[0]][ids[1]];
      } else {
        value = entity[column.id];
      }

      return (
        <TableCell key={column.id} align={column.numeric ? "right" : "left"}>
          {column.numeric
            ? formatNumber(parseFloat(value), { decimals: 2 })
            : value}
        </TableCell>
      );
    };

    const isSelected = (name: string) => selected === name;

    return (
      <Box sx={{ width: "100%" }}>
        <TableContainer>
          <TableBase
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {/*stableSort(entities, getComparator(order, orderBy))*/}
              {entities.map((entity, index) => {
                const isItemSelected = isSelected(entity.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, entity)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={entity.id}
                    selected={isItemSelected}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Radio
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {columns.map((column) => renderCell(entity, column))}
                  </TableRow>
                );
              })}
            </TableBody>
          </TableBase>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalEntities ?? entities.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    );
  }
);

export default Table;
