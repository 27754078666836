import BrokerageModel from "../../../../../../models/BrokerageModel";
import { DropdownItem } from "../../../../../../components/Dropdown";
import InvestmentStrategy from "../../../../../../models/InvestmentStrategy";

export const brokerageModelsToDropdownItems = (brokerageModels: BrokerageModel[]): DropdownItem[] => {
    return brokerageModels.map<DropdownItem>((brokerageModel: BrokerageModel): DropdownItem => {
        return {
            value: brokerageModel.id,
            label: brokerageModel.text
        };
    });
}

export const investmentStrategiesToDropdownItems = (investmentStrategies: InvestmentStrategy[]): DropdownItem[] => {
    return investmentStrategies.map<DropdownItem>((investmentStrategy: InvestmentStrategy): DropdownItem => {
        return {
            value: investmentStrategy.id,
            label: investmentStrategy.text
        };
    });
}