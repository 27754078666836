import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../index.style";
import ValueBand from "../../../../../../models/ValueBand";
import { InputAdornment } from "@mui/material";
import { defaultNumberTo, formatNumber } from "../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

export function emptyStringWhenUndefined(value: string | undefined): string {
  return value ?? "";
}

const ValueBandsOutput = (): React.ReactElement => {
  const {
    investmentAmount,
    taValueBands,
    iaValueBands,
    exchangeRate,
    bcCurrency,
    setValueBand1,
    setValueBand2,
    setValueBand3,
    setValueBand4,
  } = React.useContext(AppContext) as AppContextTypes;

  let valueBand: ValueBand | undefined;
  let valueBandPortion1: number = 0,
    valueBandPortion2: number = 0,
    valueBandPortion3: number = 0,
    valueBandPortion4: number = 0;

  const investmentAmountConverted: number = investmentAmount
    ? (investmentAmount as number) * defaultNumberTo(exchangeRate, 1)
    : 0;

  if (!!investmentAmountConverted && !!taValueBands) {
    valueBand = taValueBands.find(
      (vb) =>
        vb.valMin <= investmentAmountConverted &&
        investmentAmountConverted <= vb.valMax
    );

    if (!valueBand) {
      valueBand = taValueBands.reduce((a, b) => (a.valMax > b.valMax ? a : b));
    }
  }

  if (!!investmentAmountConverted && !!iaValueBands) {
    const iaBand1Value: number = iaValueBands.find(
      (vb) => vb.code.toLowerCase() === "IA_BAND_1".toLowerCase()
    )?.valMax as number;
    const iaBand2Value: number = iaValueBands.find(
      (vb) => vb.code.toLowerCase() === "IA_BAND_2".toLowerCase()
    )?.valMax as number;
    const iaBand3Value: number = iaValueBands.find(
      (vb) => vb.code.toLowerCase() === "IA_BAND_3".toLowerCase()
    )?.valMax as number;

    valueBandPortion1 = Math.max(
      Math.min(iaBand1Value, investmentAmountConverted),
      0
    );
    valueBandPortion2 = Math.max(
      Math.min(
        iaBand2Value - iaBand1Value,
        investmentAmountConverted - iaBand1Value
      ),
      0
    );
    valueBandPortion3 = Math.max(
      Math.min(
        iaBand3Value - iaBand2Value,
        investmentAmountConverted - iaBand2Value
      ),
      0
    );
    valueBandPortion4 = Math.max(investmentAmountConverted - iaBand3Value, 0);
  }

  React.useEffect(() => {
    if (setValueBand1) {
      setValueBand1(valueBandPortion1);
    }
    if (setValueBand2) {
      setValueBand2(valueBandPortion2);
    }
    if (setValueBand3) {
      setValueBand3(valueBandPortion3);
    }
    if (setValueBand4) {
      setValueBand4(valueBandPortion4);
    }
  }, [
    valueBandPortion1,
    valueBandPortion2,
    valueBandPortion3,
    valueBandPortion4,
  ]);

  if (!valueBand) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="value-band"
          name="value-band"
          label="Value band"
          variant="filled"
          value={!!valueBand ? valueBand.code : ""}
          InputProps={{ readOnly: true }}
        />

        <StyledTextInput
          id="value-band-portion-1"
          name="value-band-portion-1"
          label="Value band portion #1"
          variant="filled"
          value={formatNumber(Number(valueBandPortion1), {
            decimals: 2,
          })}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                {emptyStringWhenUndefined(bcCurrency)}
              </InputAdornment>
            ),
          }}
        />

        <StyledTextInput
          id="value-band-portion-2"
          name="value-band-portion-2"
          label="Value band portion #2"
          variant="filled"
          value={formatNumber(Number(valueBandPortion2), { decimals: 2 })}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                {emptyStringWhenUndefined(bcCurrency)}
              </InputAdornment>
            ),
          }}
        />

        <StyledTextInput
          id="value-band-portion-3"
          name="value-band-portion-3"
          label="Value band portion #3"
          variant="filled"
          value={formatNumber(Number(valueBandPortion3), { decimals: 2 })}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                {emptyStringWhenUndefined(bcCurrency)}
              </InputAdornment>
            ),
          }}
        />

        <StyledTextInput
          id="value-band-portion-4"
          name="value-band-portion-4"
          label="Value band portion #4"
          variant="filled"
          value={formatNumber(Number(valueBandPortion4), { decimals: 2 })}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                {emptyStringWhenUndefined(bcCurrency)}
              </InputAdornment>
            ),
          }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default ValueBandsOutput;
