import { takeLatest, call, put } from "redux-saga/effects";
import {
    VALUE_BAND_GET_API_REQUEST,
    VALUE_BAND_GET_API_SUCCESS,
    VALUE_BAND_GET_API_FAILURE,
} from "../reducers/value-bands-reducer";
import api, { GetResponse } from "../api";
import ValueBand from "../models/ValueBand";

function getValueBands(): Promise<GetResponse<ValueBand>> {
    return api.get<ValueBand>({
        endpoint: '/value-bands'
    });
}

function* retrieveValueBands() {
    try {
        const response: GetResponse<ValueBand> = yield call(getValueBands);
        yield put({ type: VALUE_BAND_GET_API_SUCCESS, valueBands: response.entities });
    } catch (error) {
        yield put({ type: VALUE_BAND_GET_API_FAILURE, error });
    }
}

export default function* valueBandsSaga() {
    yield takeLatest(VALUE_BAND_GET_API_REQUEST, retrieveValueBands);
}
