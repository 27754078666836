import React from "react";
import { StyledDropdownInput, StyledInputWrapper } from "../../index.style";
import Content from "../../../../../../components/Content";
import Header from "../../../../../../components/Header";
import { SelectChangeEvent } from "@mui/material";
import { specialConditionTypes } from "../../../../../../app/costants";
import NumberField from "../../../../../../components/NumberField/NumberField";
import AppContext, { AppContextTypes } from "app/index.ctx";

const TransactionRelatedCostsFields = (): React.ReactElement => {
  const {
    transactionRelatedCosts,
    trcSpecialCondition,
    setTrcSpecialCondition,
    trcSpecialConditionAmount,
    setTrcSpecialConditionAmount,
  } = React.useContext(AppContext) as AppContextTypes;
  const disabled = trcSpecialCondition === specialConditionTypes[0].value;

  const onSpecialConditionTypeSelected = (
    event: SelectChangeEvent<string | number>
  ) => {
    if (setTrcSpecialCondition) {
      setTrcSpecialCondition(event.target.value as string);
    }
  };

  const onAmountChanged = (value: string) => {
    if (setTrcSpecialConditionAmount) {
      setTrcSpecialConditionAmount(parseFloat(value));
    }
  };

  if (transactionRelatedCosts === 0) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <Content>
        <Header title="Transaction related costs fields" />
      </Content>
      <StyledInputWrapper>
        <StyledDropdownInput
          label="Special condition type"
          name="special-condition-type"
          items={specialConditionTypes}
          variant="outlined"
          onChange={onSpecialConditionTypeSelected}
          defaultValue={trcSpecialCondition}
        />

        <NumberField
          label="Special condition amount"
          name="special-condition-amount"
          variant="outlined"
          value={disabled ? "" : trcSpecialConditionAmount}
          onChange={onAmountChanged}
          endAdornment={disabled ? "" : trcSpecialCondition}
          disabled={disabled}
          min={0}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default TransactionRelatedCostsFields;
