import React from "react";
import { DropdownHandles } from "../../../../components/Dropdown";

export const resetSlaveDropdown = (
  ref: React.RefObject<DropdownHandles>,
  clearMethod: () => any
) => {
  ref.current?.clear();
  clearMethod();
};
