import { Grid } from "@mui/material";
import { FormFieldLabel } from "../../../../hook-form/FormFieldLabel";
import { FormInput } from "../../../../hook-form/FormInput";
import TransactionCostConstant from "../../../../models/TransactionCostConstant";

export interface SecondRowFieldsProps {
  control: any;
  transactionCostConstant: TransactionCostConstant;
}

export const SecondRowFields = (props: SecondRowFieldsProps) => {
  const { control } = props;

  return (
    <>
      <Grid item xs={6}>
        <FormInput
          control={control}
          name="bsRemark"
          label={<FormFieldLabel>BS Remark</FormFieldLabel>}
          fullWidth
          autoComplete="off"
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          control={control}
          name="itRemark"
          label={<FormFieldLabel>IT Remark</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
    </>
  );
};
