import { DropdownItem } from "../../../../../../components/Dropdown";
import { FormState } from "../../../../../../store";
import {
  bookingCentersToDropdownItems,
  rmLocationsToDropdownItems,
  serviceModelsToDropdownItems,
  servicePackagesToDropdownItems,
} from "./utils";
import {
  BOOKING_CENTER_GET_API_REQUEST,
  BOOKING_CENTER_SELECTED,
} from "../../../../../../reducers/booking-centers-reducer";
import {
  RM_LOCATION_CLEAR,
  RM_LOCATION_GET_API_REQUEST,
  RM_LOCATION_SELECTED,
} from "../../../../../../reducers/rm-locations-reducer";
import {
  SERVICE_MODEL_CLEAR,
  SERVICE_MODEL_GET_API_REQUEST,
  SERVICE_MODEL_SELECTED,
} from "../../../../../../reducers/service-models-reducer";
import {
  SERVICE_PACKAGE_GET_API_REQUEST,
  SERVICE_PACKAGE_CLEAR,
} from "../../../../../../reducers/service-packages-reducer";

export default interface ServiceFieldsProperties {
  bookingCenters: DropdownItem[];
  rmLocations: DropdownItem[];
  serviceModels: DropdownItem[];
  servicePackages: DropdownItem[];
  onRequestGetBookingCenters?: any;
  onRequestGetRMLocations?: any;
  onRequestGetServiceModels?: any;
  onRequestGetServicePackages?: any;
  onBookingCenterSelected?: any;
  onRMLocationSelected?: any;
  onServiceModelSelected?: any;
  onRequestClearRMLocations?: any;
  onRequestClearServiceModels?: any;
  onRequestClearServicePackage?: any;
}

export const mapStateToProps = (state: FormState): ServiceFieldsProperties => {
  return {
    bookingCenters: bookingCentersToDropdownItems(
      state.bookingCentersReducer.bookingCenters
    ),
    rmLocations: rmLocationsToDropdownItems(
      state.rmLocationsReducer.rmLocations
    ),
    serviceModels: serviceModelsToDropdownItems(
      state.serviceModelsReducer.serviceModels
    ),
    servicePackages: servicePackagesToDropdownItems(
      state.servicePackagesReducer.servicePackages
    ),
  };
};

export const mapDispatchToProps = (
  dispatch: (arg0: {
    type: string;
    id1?: number;
    id2?: number;
    selected?: number;
    rcCode?: string;
  }) => any
) => {
  return {
    onRequestGetBookingCenters: () =>
      dispatch({ type: BOOKING_CENTER_GET_API_REQUEST }),
    onRequestGetRMLocations: (id1: number) =>
      dispatch({ type: RM_LOCATION_GET_API_REQUEST, id1 }),
    onRequestGetServiceModels: (id1: number, id2: number) =>
      dispatch({ type: SERVICE_MODEL_GET_API_REQUEST, id1, id2 }),
    onRequestGetServicePackages: (id1: number, id2: number, rcCode: string) =>
      dispatch({ type: SERVICE_PACKAGE_GET_API_REQUEST, id1, id2, rcCode }),
    onBookingCenterSelected: (selected: number) =>
      dispatch({ type: BOOKING_CENTER_SELECTED, selected }),
    onRMLocationSelected: (id1: number) =>
      dispatch({ type: RM_LOCATION_SELECTED, id1 }),
    onServiceModelSelected: (id1: number) =>
      dispatch({ type: SERVICE_MODEL_SELECTED, id1 }),
    onRequestClearRMLocations: () => dispatch({ type: RM_LOCATION_CLEAR }),
    onRequestClearServiceModels: () => dispatch({ type: SERVICE_MODEL_CLEAR }),
    onRequestClearServicePackage: () =>
      dispatch({ type: SERVICE_PACKAGE_CLEAR }),
  };
};
