import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Breadcrumbs as BreadcrumbsBase, Typography } from "@mui/material";
import { StyledLink } from "./index.style";
import env from "../../config/env";

const breadsCrumbsList: { [key: string]: { [key: string]: string } } = {
  "/": { "": "Admin Panel" },
  "/simulation": { "": "Start Simulation" },
  "/output": { "/simulation": "PCS Simulation", "": "Output" },
  "/admin/fee-class-matrices": {
    "/admin": "Admin Page",
    "": "Fee Class Matrices",
  },
  "/admin/fee-class-matrices/edit/:id": {
    "/admin": "Admin Page",
    "/admin/fee-class-matrices": "Fee Class Matrices",
    "": "Edit",
  },
  "/admin/value-bands": { "/admin": "Admin Page", "": "Value Bands" },
  "/admin/value-bands/edit/:id": {
    "/admin": "Admin Page",
    "/admin/value-bands": "Value Bands",
    "": "Edit",
  },
  "/admin/transaction-cost-constants": {
    "/admin": "Admin Page",
    "": "Transaction Cost Constants",
  },
  "/admin/transaction-cost-constants/edit/:id": {
    "/admin": "Admin Page",
    "/admin/transaction-cost-constants": "Transaction Cost Constants",
    "": "Edit",
  },
};

const Breadcrumbs = (): React.ReactElement => {
  const [breadcrumbs, setBreadcrumbs] = React.useState<{
    [key: string]: string;
  }>({});

  const location = useLocation();

  const { REACT_APP_ENV, REACT_APP_PDF_VERSION } = env;

  React.useEffect(() => {
    let path = location.pathname;

    if (!breadsCrumbsList[path]) {
      let pieces = path.split("/");

      if (parseInt(pieces[pieces.length - 1]) >= 0) {
        pieces[pieces.length - 1] = ":id";
        path = pieces.join("/");
      }
    }

    if (!breadsCrumbsList[path]) {
      return;
    }

    setBreadcrumbs(breadsCrumbsList[path]);
  }, [location]);

  const renderBreadcrumbs = () => {
    let result = [];

    for (let key of Object.keys(breadcrumbs)) {
      let breadcrumb = breadcrumbs[key];

      if (key === "") {
        result.push(
          <Typography color="text.primary" key={"breadcrumb-#" + key}>
            {breadcrumb}
          </Typography>
        );
        continue;
      }

      result.push(
        <StyledLink color="primary" key={"breadcrumb-#" + key} to={key}>
          {breadcrumb}
        </StyledLink>
      );
    }

    return result;
  };

  if (breadcrumbs === undefined || breadcrumbs === null) {
    return <></>;
  }

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
    >
      <BreadcrumbsBase
        aria-label="breadcrumb"
        separator={">"}
        style={{ marginBottom: "10px" }}
      >
        {renderBreadcrumbs()}
      </BreadcrumbsBase>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginLeft: "auto",
        }}
      >
        {REACT_APP_ENV && (
          <>
            <b>Env:</b> {REACT_APP_ENV} &nbsp;
          </>
        )}
        <b> Vers:</b> {REACT_APP_PDF_VERSION}
      </div>
    </Box>
  );
};

export default Breadcrumbs;
