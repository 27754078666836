import React from "react";
import { isCalculateEnabled } from "../utils/validate";
import AppContext, { AppContextTypes } from "app/index.ctx";

export const useValidateForm = () => {
  const {
    clientName,
    outputLanguage,
    bookingCenter,
    outputCurrency,
    exchangeRate,
    rmLocation,
    serviceModel,
    investmentStrategy,
    investmentAmount,
    investmentStrategyRequired,
    calculateCanBeEnabled,
    setCalculateCanBeEnabled,
  } = React.useContext(AppContext) as AppContextTypes;

  const findForceByName = React.useCallback(
    (forceName: string) => {
      return (
        calculateCanBeEnabled?.findIndex((v) => v.name === forceName) ?? -1
      );
    },
    [calculateCanBeEnabled]
  );

  const forceCalculateButtonToDisabled = React.useCallback(
    (forceName: string) => {
      if (findForceByName(forceName) > -1) {
        return;
      }

      calculateCanBeEnabled?.push({
        name: forceName,
      });

      setCalculateCanBeEnabled?.([...(calculateCanBeEnabled ?? [])]);
    },
    [findForceByName, calculateCanBeEnabled, setCalculateCanBeEnabled]
  );

  const removeForceCalculateButtonToDisabled = React.useCallback(
    (forceName: string) => {
      const index = findForceByName(forceName);

      if (index === -1) {
        return;
      }

      calculateCanBeEnabled?.splice(index, 1);
      setCalculateCanBeEnabled?.([...(calculateCanBeEnabled ?? [])]);
    },
    [findForceByName, calculateCanBeEnabled, setCalculateCanBeEnabled]
  );

  const isFormValid = React.useMemo(
    () =>
      clientName !== "" &&
      outputLanguage !== undefined &&
      bookingCenter !== undefined &&
      outputCurrency !== undefined &&
      exchangeRate !== undefined &&
      rmLocation !== undefined &&
      serviceModel !== undefined &&
      (investmentStrategyRequired ? investmentStrategy !== undefined : true) &&
      investmentAmount !== undefined &&
      investmentAmount > 0,
    [
      clientName,
      outputLanguage,
      bookingCenter,
      outputCurrency,
      exchangeRate,
      rmLocation,
      serviceModel,
      investmentStrategyRequired,
      investmentStrategy,
      investmentAmount,
    ]
  );

  return {
    isValid:
      isFormValid &&
      isCalculateEnabled() &&
      calculateCanBeEnabled?.length === 0,
    forceCalculateButtonToDisabled,
    removeForceCalculateButtonToDisabled,
  };
};
