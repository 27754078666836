import { useState, useContext, useEffect } from "react";
import IndicativeOverview from "./components/IndicativeOverview";
import store from "../../store";
import {
  OUTPUT_CLEAR,
  OUTPUT_POST_API_REQUEST,
} from "../../reducers/output-reducer";
import OutputRequest from "../../models/OutputRequest";
import Card from "../../components/Card";
import { StyledDiv, StyledSection } from "./index.style";
import logo from "../../assets/logo.png";
import { Box, Container, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import OngoingServiceRelatedCosts from "./components/OngoingServiceRelatedCosts";
import TransactionRelatedCosts from "./components/TransactionRelatedCosts";
import InvestmentProductRelatedCosts from "./components/InvestmentProductRelatedCosts";
import TotalCosts from "./components/TotalCosts";
import { specialConditionTypes } from "../../app/costants";
import Remarks from "./components/Remarks";
import Button from "../../components/Button";
import { DownloadOutlined } from "@mui/icons-material";
import axios from "axios";
import { getAccessToken, parseJwt } from "../../utils/jwtUtils";
import { useValidateForm } from "../../hooks/useValidateForm";
import env from "../../config/env";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Output = () => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const {
    output,
    setOutput,
    outputLanguage,
    clientName,
    bookingCenter,
    percentageInsteadOfBps,
    serviceModel,
    investmentStrategy,
    rmLocation,
    servicePackage,
    brokerageModel,
    bespoke,
    investmentAmount,
    outputCurrency,
    transactionRelatedCosts,
    masFee,
    trcMasLow,
    trcMasMedium,
    trcMasHigh,
    noTxLow,
    noTxMedium,
    noTxHigh,
    trcAvgTxSize,
    exchangeRate,
    trcSpecialCondition,
    trcSpecialConditionAmount,
    advisoryServiceFee,
    asfSpecialConditionAmount,
    asfSpecialCondition,
    asfTotal,
    asfSoko,
    trcBpsIsDm,
    trcAmountLow,
    trcAmountMedium,
    trcAmountHigh,
    bsFeeFlatAmount,
    bankServiceFee,
    bsfAmount,
    bsfDiscount,
    custodyServiceFee,
    csfSpecialCondition,
    csfSpecialConditionAmount,
    csfTotal,
    csfSoko,
    productRelatedCosts,
    overridePercentageOfPRC,
    remarks,
    prcModelPortfolioStrategy,
  } = useContext(AppContext) as AppContextTypes;
  const { isValid: validRequest } = useValidateForm();
  let onBeforeUnload: any;

  useEffect(() => {
    if (!validRequest) {
      window.location.href = "/simulation";
      return;
    }

    onBeforeUnload = window.onbeforeunload;
    window.onbeforeunload = () => "";

    return () => {
      window.onbeforeunload = onBeforeUnload;
    };
  }, [validRequest]);

  function onOutputRequested(state: any) {
    if (state.outputReducer.output && setOutput) {
      setInitialized(true);
      setOutput(state.outputReducer.output);
    }
  }

  function returnStringOrNull(value: string | undefined): string {
    return (value === "" ? null : value) as string;
  }

  function defaultZero(value: number | undefined): number {
    return isNaN(value as number) ? 0 : (value as number);
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      onOutputRequested(state);
    });

    const token = parseJwt(getAccessToken() ?? "");

    const outputRequest: OutputRequest = {
      languageId: outputLanguage?.id as number,
      userId: token?.upn ?? "",
      clientName: clientName as string,
      bookingCenterId: bookingCenter?.id as number,
      percentageInsteadOfBps: percentageInsteadOfBps as boolean,
      currencyInputId: bookingCenter?.currency?.id as number,
      currencyOutput: returnStringOrNull(outputCurrency),
      serviceModelId: serviceModel?.id as number,
      investmentStrategyId: investmentStrategy?.id as number,
      resourceManagerLocationId: rmLocation?.id as number,
      servicePackageId: servicePackage?.id as number,
      brokerageModelId: brokerageModel?.id as number,
      beSpoke: bespoke as boolean,
      investmentAmount: investmentAmount as number,
      exchangeRate: exchangeRate,

      masFee: defaultZero(masFee),
      trcMasLow: defaultZero(trcMasLow),
      trcMasMedium: defaultZero(trcMasMedium),
      trcMasHigh: defaultZero(trcMasHigh),
      trcAmountSumLow: defaultZero(trcAmountLow),
      trcAmountSumMedium: defaultZero(trcAmountMedium),
      trcAmountSumHigh: defaultZero(trcAmountHigh),
      transactionsRelatedCosts: defaultZero(transactionRelatedCosts),
      trcSpecConditionType:
        trcSpecialCondition === specialConditionTypes[1].value
          ? "DISCOUNT_IN_PERCENTAGE"
          : "FLAT_FEE_IN_BPS",
      trcSpecConditionAmount: defaultZero(trcSpecialConditionAmount),
      trcNLow: defaultZero(noTxLow),
      trcNMedium: defaultZero(noTxMedium),
      trcNHigh: defaultZero(noTxHigh),
      trcAvgSize: defaultZero(trcAvgTxSize),
      trcBpsIsDm: defaultZero(trcBpsIsDm),
      advPmSpecConditionType:
        asfSpecialCondition === specialConditionTypes[1].value
          ? "DISCOUNT_IN_PERCENTAGE"
          : "FLAT_FEE_IN_BPS",
      advisoryServiceFee: defaultZero(advisoryServiceFee),
      advisoryServiceFeeSum:
        advisoryServiceFee === 0
          ? 0
          : asfTotal
          ? Math.ceil(defaultZero(asfTotal))
          : asfTotal,
      advisoryServiceFeeDiscount: asfSoko,
      advisoryServiceFeeSpecCondAmount: asfSpecialConditionAmount,

      bankServiceFee: defaultZero(bankServiceFee),
      bankingServiceFeeSum:
        bankServiceFee === 0
          ? 0
          : bsfAmount
          ? Math.ceil(defaultZero(bsfAmount))
          : bsfAmount,
      bankingServiceFeeDiscount: bsfDiscount,
      bankingServiceFeeSpecCondFlatFee: bsFeeFlatAmount,

      custodyServiceFee: defaultZero(custodyServiceFee),
      custodyServiceFeeSum:
        custodyServiceFee === 0
          ? 0
          : csfTotal
          ? Math.ceil(defaultZero(csfTotal))
          : csfTotal,
      custodyServiceFeeDiscount: csfSoko,
      custodyPmSpecConditionType:
        csfSpecialCondition === specialConditionTypes[1].value
          ? "DISCOUNT_IN_PERCENTAGE"
          : "FLAT_FEE_IN_BPS",
      custodyServiceFeeSpecCondAmount: csfSpecialConditionAmount,

      overridePercentageOfPRC: defaultZero(
        overridePercentageOfPRC ?? prcModelPortfolioStrategy
      ),
      productRelatedCosts: defaultZero(productRelatedCosts),
      remarks: remarks,
    };

    store.dispatch({
      type: OUTPUT_CLEAR,
    });

    store.dispatch({
      type: OUTPUT_POST_API_REQUEST,
      output: outputRequest,
    });

    return unsubscribe;
  }, []);

  function downloadPDF() {
    axios
      .get(
        env.REACT_APP_API_URL +
          "/pdf/download?reportName=" +
          (output?.reportName ?? ""),
        {
          headers: {
            "Content-Disposition": "attachment",
            Authorization: "Bearer " + getAccessToken(),
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);

        let filename = "pcs_result.pdf";

        if (response.headers) {
          filename = response.headers["content-disposition"] ?? filename;

          if (filename.includes('; filename="')) {
            filename = filename.split('; filename="')[1];
            filename = filename.substring(0, filename.length - 1);
          }
        }

        const a = document.createElement("a");
        a.setAttribute("style", "display:none;");
        document.body.appendChild(a);
        a.href = url;
        a.download = decodeURIComponent(filename);
        a.click();
        a.remove();
      });
  }

  if (!initialized) return <></>;

  return (
    <>
      <Card
        title={"Output"}
        Component={StyledSection}
        style={{ width: "100%" }}
        actions={
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => downloadPDF()}
            >
              Download PDF
              <DownloadOutlined />
            </Button>
          </>
        }
      >
        <Grid item xs={12}>
          <StyledDiv>
            <Container>
              <Grid container justifyContent="center" sx={{ mb: 10 }}>
                <Box
                  component="img"
                  sx={{
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="Julius Baer logo"
                  src={logo}
                />
              </Grid>
              <Grid container>
                <Header
                  title={output?.title ?? ""}
                  subtitle={output?.subtitle ?? ""}
                />
                <IndicativeOverview />
                <OngoingServiceRelatedCosts />
                <TransactionRelatedCosts />
                <InvestmentProductRelatedCosts />
                <TotalCosts />
                <Remarks />
                <Typography variant="body2" style={{ marginTop: "50px" }}>
                  {output?.disclaimer}
                </Typography>
                <Grid
                  container
                  marginTop={5}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography color="primary">
                    {output?.bankNameForBc}
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    transform: "rotate(-90deg)",
                    bottom: "280px",
                    left: "-400px",
                    position: "relative",
                  }}
                >
                  {output?.leftVerticalSign?.replace(/\\/g, "")}
                </Box>
              </Grid>
            </Container>
          </StyledDiv>
        </Grid>
      </Card>
    </>
  );
};

export default Output;
