import { Grid } from "@mui/material";
import { FormFieldLabel } from "../../../../hook-form/FormFieldLabel";
import { FormSelect } from "../../../../hook-form/FormSelect";
import ValueBand from "../../../../models/ValueBand";

export interface FirstRowFieldsProps {
  control: any;
  valueBand: ValueBand;
}

export const FirstRowFields = (props: FirstRowFieldsProps) => {
  const { valueBand, control } = props;

  return (
    <>
      <Grid item xs={6}>
        <FormSelect
          name="code"
          label={<FormFieldLabel isRequired>Code</FormFieldLabel>}
          control={control}
          fullWidth
          options={[
            {
              label: valueBand.code ?? "",
              value: valueBand.code ?? "",
            },
          ]}
          disabled
          rules={{ required: "Code is required." }}
        />
      </Grid>

      <Grid item xs={6}>
        <FormSelect
          name="text"
          label={<FormFieldLabel isRequired>Text</FormFieldLabel>}
          control={control}
          fullWidth
          options={[
            {
              label: valueBand.text ?? "",
              value: valueBand.text ?? "",
            },
          ]}
          disabled
          rules={{ required: "Text is required." }}
        />
      </Grid>
    </>
  );
};
