import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import { InputAdornment } from "@mui/material";
import AppContext, { AppContextTypes } from "app/index.ctx";

const RateBands = (): React.ReactElement => {
  const {
    investmentAmount,
    feeClassMatrices,
    setTrcRateBand1Low,
    setTrcRateBand2Low,
    setTrcRateBand3Low,
    setTrcRateBand4Low,
    setTrcRateBand5Low,
  } = React.useContext(AppContext) as AppContextTypes;

  const band1 =
    (feeClassMatrices?.find(
      (fcm) =>
        fcm.feeType.text.toLowerCase() ===
          "Transaction Related Costs".toLowerCase() &&
        fcm.feeClass.text.toLowerCase() === "Band_1".toLowerCase()
    )?.amount as number) ?? 0;

  const band2 =
    (feeClassMatrices?.find(
      (fcm) =>
        fcm.feeType.text.toLowerCase() ===
          "Transaction Related Costs".toLowerCase() &&
        fcm.feeClass.text.toLowerCase() === "Band_2".toLowerCase()
    )?.amount as number) ?? 0;

  const band3 =
    (feeClassMatrices?.find(
      (fcm) =>
        fcm.feeType.text.toLowerCase() ===
          "Transaction Related Costs".toLowerCase() &&
        fcm.feeClass.text.toLowerCase() === "Band_3".toLowerCase()
    )?.amount as number) ?? 0;

  const band4 =
    (feeClassMatrices?.find(
      (fcm) =>
        fcm.feeType.text.toLowerCase() ===
          "Transaction Related Costs".toLowerCase() &&
        fcm.feeClass.text.toLowerCase() === "Band_4".toLowerCase()
    )?.amount as number) ?? 0;

  const band5 =
    (feeClassMatrices?.find(
      (fcm) =>
        fcm.feeType.text.toLowerCase() ===
          "Transaction Related Costs".toLowerCase() &&
        fcm.feeClass.text.toLowerCase() === "Band_5".toLowerCase()
    )?.amount as number) ?? 0;

  const trcHiddenStyle = !investmentAmount ? { display: "none" } : {};

  React.useEffect(() => {
    if (setTrcRateBand1Low) {
      setTrcRateBand1Low(band1);
    }
    if (setTrcRateBand2Low) {
      setTrcRateBand2Low(band2);
    }
    if (setTrcRateBand3Low) {
      setTrcRateBand3Low(band3);
    }
    if (setTrcRateBand4Low) {
      setTrcRateBand4Low(band4);
    }
    if (setTrcRateBand5Low) {
      setTrcRateBand5Low(band5);
    }
  }, [
    band1,
    band2,
    band3,
    band4,
    band5,
    setTrcRateBand1Low,
    setTrcRateBand2Low,
    setTrcRateBand3Low,
    setTrcRateBand4Low,
    setTrcRateBand5Low,
  ]);

  if (!investmentAmount) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="trc-rate-band1"
          name="trc-rate-band1"
          label="TRC_Rate.Band 1.low"
          variant="filled"
          value={band1}
          style={trcHiddenStyle}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="trc-rate-band2"
          name="trc-rate-band2"
          label="TRC_Rate.Band 2.low"
          variant="filled"
          value={band2}
          style={trcHiddenStyle}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="trc-rate-band3"
          name="trc-rate-band3"
          label="TRC_Rate.Band 3.low"
          variant="filled"
          value={band3}
          style={trcHiddenStyle}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="trc-rate-band4"
          name="trc-rate-band4"
          label="TRC_Rate.Band 4.low"
          variant="filled"
          value={band4}
          style={trcHiddenStyle}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />

        <StyledTextInput
          id="trc-rate-band5"
          name="trc-rate-band5"
          label="TRC_Rate.Band 5.low"
          variant="filled"
          value={band5}
          style={trcHiddenStyle}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{
            style: {
              textAlign: "right",
              marginRight: "5px",
            },
          }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default RateBands;
