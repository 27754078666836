import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const BackButton = styled(Button)`
    margin-right: 15px;
    border-radius: 0;
`;

export const StyledTypography = styled(Typography)`
    height: 40px;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: inherit;
    }
`;