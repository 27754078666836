import { DropdownItem } from "../components/Dropdown";

export const outputUnitItems: DropdownItem[] = [
  {
    value: "bps",
    label: "BPS as unit",
  },
  {
    value: "%",
    label: "Percentage",
  },
];

export const specialConditionTypes: DropdownItem[] = [
  {
    value: "n",
    label: "None",
  },
  {
    value: "%",
    label: "Discount in %",
  },
  {
    value: "bps",
    label: "Flat fee in bps",
  },
];

export const yesNoChoice: DropdownItem[] = [
  {
    value: "n",
    label: "No",
  },
  {
    value: "y",
    label: "Yes",
  },
];
