import React from "react";
import Header from "../../../../components/Header";
import { Grid } from "@mui/material";
import { formatNumber } from "../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const InvestmentProductRelatedCosts = (): React.ReactElement => {
  const { output } = React.useContext(AppContext) as AppContextTypes;

  if (output === undefined) return <React.Fragment />;
  if (output.investmentProductRelatedCostTotalValue === null)
    return <React.Fragment />;
  if (output.investmentProductRelatedCostTotalValue === undefined)
    return <React.Fragment />;
  if (output.investmentProductRelatedCost === null) return <React.Fragment />;
  if (output.investmentProductRelatedCost === undefined)
    return <React.Fragment />;

  return (
    <React.Fragment>
      <Grid container marginTop={3}>
        <Header title={output.investmentProductRelatedCost} />
      </Grid>
      <Grid item xs={12}>
        <div style={{ width: "100%" }}>
          <b>
            <span>{output.investmentProductRelatedCostTotalLabel}</span>
            <span style={{ float: "right" }}>
              {formatNumber(
                parseFloat(output.investmentProductRelatedCostTotalValue),
                {
                  decimals: 3,
                }
              )}
            </span>
          </b>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default InvestmentProductRelatedCosts;
