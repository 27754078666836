/* istanbul ignore file */
import { takeLatest, call, put } from "redux-saga/effects";
import {
  BROKERAGE_MODEL_GET_API_REQUEST,
  BROKERAGE_MODEL_GET_API_SUCCESS,
  BROKERAGE_MODEL_GET_API_FAILURE,
} from "../reducers/brokerage-models-reducer";
import api, { GetResponse } from "../api";
import BrokerageModel from "../models/BrokerageModel";

function getBrokerageModels(
  bookingCenterId: string | number,
  serviceModelId: string | number
): Promise<GetResponse<BrokerageModel>> {
  return api.get<BrokerageModel>({
    endpoint:
      "/booking-centers/" +
      bookingCenterId +
      "/service-models/" +
      serviceModelId +
      "/brokerage-models",
  });
}

function* retrieveBrokerageModels(action: {
  type: string;
  id1: string | number;
  id2: string | number;
}) {
  try {
    const response: GetResponse<BrokerageModel> = yield call(
      getBrokerageModels,
      action.id1,
      action.id2
    );
    yield put({
      type: BROKERAGE_MODEL_GET_API_SUCCESS,
      brokerageModels: response?.entities ?? [],
    });
  } catch (error) {
    yield put({ type: BROKERAGE_MODEL_GET_API_FAILURE, error });
  }
}

export default function* brokerageModelsSaga() {
  yield takeLatest(BROKERAGE_MODEL_GET_API_REQUEST, retrieveBrokerageModels);
}
