import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import React from "react";

type SearchBarProps = {
  setSearchQuery: (value: string) => void;
};

const SearchBar = ({ setSearchQuery }: SearchBarProps) => {
    let timeoutHandler: NodeJS.Timeout | null = null;

  const onInput = (e: any) => {
      function prepareTimeout() {
          timeoutHandler = setTimeout(() => {
              setSearchQuery(e.target.value);
              timeoutHandler = null;
          }, 500);
      }

      if (timeoutHandler !== null) {
          window.clearTimeout(timeoutHandler);
      }

      prepareTimeout();
  };

  return (
    <TextField
      id="search-bar"
      className="text"
      onInput={onInput}
      label="Search"
      variant="outlined"
      placeholder="Search..."
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
