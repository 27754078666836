import React from "react";
import {
  StyledFormControlLabel,
  StyledInputWrapper,
  StyledTextInput,
} from "../../index.style";
import { Checkbox, InputAdornment } from "@mui/material";
import { defaultNumberTo, formatNumber } from "../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const BasicOutput = (): React.ReactElement => {
  const { bcCurrency, serviceModel, investmentAmount, exchangeRate } =
    React.useContext(AppContext) as AppContextTypes;

  const investmentAmountConverted = investmentAmount
    ? formatNumber(
        Number((investmentAmount as number) * defaultNumberTo(exchangeRate, 1)),
        {
          decimals: 2,
        }
      )
    : "";

  const bcCurrencyHiddenStyle =
    !bcCurrency || bcCurrency === "" ? { display: "none" } : {};
  const investmentHiddenStyle =
    !bcCurrency || bcCurrency === "" ? { display: "none" } : {};
  const serviceModelIsDMHiddenStyle = !serviceModel ? { display: "none" } : {};

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="business-center-currency"
          name="business-center-currency"
          label="Business center currency"
          variant="filled"
          value={bcCurrency || ""}
          style={bcCurrencyHiddenStyle}
          InputProps={{ readOnly: true }}
        />
        <StyledTextInput
          id="investment-amount-output"
          name="investment-amount-output"
          label={"Investment amount in " + bcCurrency}
          variant="filled"
          value={investmentAmountConverted}
          style={investmentHiddenStyle}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                {bcCurrency ?? ""}
              </InputAdornment>
            ),
          }}
        />
        <StyledFormControlLabel
          disabled
          control={<Checkbox checked={!!serviceModel && serviceModel?.isDM} />}
          label="Service model is DM"
          style={serviceModelIsDMHiddenStyle}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default BasicOutput;
