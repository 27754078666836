import { Grid } from "@mui/material";
import { FormFieldLabel } from "../../../../hook-form/FormFieldLabel";
import { FormInput } from "../../../../hook-form/FormInput";

import ValueBand from "../../../../models/ValueBand";

export interface ThirdRowFieldsProps {
  control: any;
  valueBand: ValueBand;
}

export const ThirdRowFields = (props: ThirdRowFieldsProps) => {
  const { control } = props;

  return (
    <>
      <Grid item xs={6}>
        <FormInput
          control={control}
          name="bsRemark"
          label={<FormFieldLabel>Bs Remark</FormFieldLabel>}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          control={control}
          name="itRemark"
          label={<FormFieldLabel>IT Remark</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
    </>
  );
};
