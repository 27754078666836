import { takeLatest, call, put } from "redux-saga/effects";
import {
    TRANSACTION_COST_CONSTANT_GET_API_REQUEST,
    TRANSACTION_COST_CONSTANT_GET_API_SUCCESS,
    TRANSACTION_COST_CONSTANT_GET_API_FAILURE,
} from "../reducers/transaction-cost-constants-reducer";
import api, { GetResponse } from "../api";
import TransactionCostConstant from "../models/TransactionCostConstant";

export interface RetrieveTransactionCostConstantsParams {
    type: string,
    servicePackageId: number,
    bookingCenterId: number,
    serviceModelId: number,
    brokerageModelId: number,
    investmentStrategyId: number
}

function getTransactionCostConstants({ servicePackageId, bookingCenterId, serviceModelId, brokerageModelId, investmentStrategyId } : RetrieveTransactionCostConstantsParams): Promise<GetResponse<TransactionCostConstant>> {
    return api.get<TransactionCostConstant>({
        endpoint: '/transaction-cost-constants',
        data: {
            servicePackageId,
            bookingCenterId,
            serviceModelId,
            brokerageModelId,
            investmentStrategyId
        }
    });
}

function* retrieveTransactionCostConstants(action: RetrieveTransactionCostConstantsParams) {
    try {
        const response: GetResponse<TransactionCostConstant> = yield call(getTransactionCostConstants, action);
        yield put({ type: TRANSACTION_COST_CONSTANT_GET_API_SUCCESS, transactionCostConstants: response.entities });
    } catch (error) {
        yield put({ type: TRANSACTION_COST_CONSTANT_GET_API_FAILURE, error });
    }
}

export default function* transactionCostConstantsSaga() {
    yield takeLatest(TRANSACTION_COST_CONSTANT_GET_API_REQUEST, retrieveTransactionCostConstants);
}
